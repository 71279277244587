import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Divider, Typography } from '@mui/material';

import { useShareStory } from './hooks/useShareStory';
import { httpClient } from '../../services/httpClient/httpClient';
import { getUserIncentiveCampaigns } from '../../store/slices/rewards';
import { useAppDispatch, useTypedSelector } from '../../store/store';
import { SharePageStrings } from '../../common/localization/en';
import { ReceivedIcon } from '../../common/assets/ReceivedIcon';
import { UploadProgressBar } from '../../common/components/UploadProgressBar/UploadProgressBar';
import { NavigationPanel } from '../../common/components/NavigationPanel/NavigationPanel';
import { useSharePageStyles } from './SharePage.styles';

const SharePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id: storyId } = useParams<{ id: string }>();

  const styles = useSharePageStyles();

  const { account } = useTypedSelector((state) => state.account);
  const me = useTypedSelector((state) => state.me);

  useEffect(() => {
    const userId = httpClient.getUserId();
    if (userId) {
      dispatch(getUserIncentiveCampaigns({ userId }));
    }
  }, [dispatch]);

  const { shareStory, isLoading } = useShareStory({
    storyId,
    refetchUntilModerated: true,
  });

  const { currentCampaign } = useTypedSelector((state) => state.rewards);

  const isStoryLoading = isLoading || !shareStory;
  if (isStoryLoading) return <></>;

  return (
    <>
      <Box position="fixed" bottom="0" width="100vw">
        <NavigationPanel
          activeIndex={currentCampaign?.welcomeVideoUrl && currentCampaign?.showWelcomeVideo ? 3 : 2}
          isSharePage={true}
        />
      </Box>
      <Box className={styles.container}>
        <UploadProgressBar forceShowUploadComplete />

        <Box className={styles.titleContainer}>
          <ReceivedIcon color="#058901" />
          <Typography className={styles.title}>{SharePageStrings.YourVideoReceived}</Typography>
        </Box>

        <Box className={styles.contentContainer}>
          <Typography className={styles.mainMessageTitle}>
            {SharePageStrings.YouAreAllSet.replace(
              '{name}',
              me.firstName ? me.firstName : shareStory.userName.split(' ')[0],
            )}
          </Typography>
          <Typography className={styles.mainMessageText}>
            {SharePageStrings.ThankYou.replace('{name}', account.name)}
          </Typography>

          <Divider className={styles.divider} />

          <Typography className={styles.bottomText}>{SharePageStrings.WouldYouShareVideo}</Typography>

          <Button
            variant="outlined"
            href={`${shareStory.videoPageUrl}&share=true`}
            className={styles.shareButton}
            fullWidth
          >
            {SharePageStrings.ShareVideo}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SharePage;
