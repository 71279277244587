import React, { useMemo, useState, useEffect } from 'react';
import { useFormik } from 'formik';

import { useTypedSelector, useAppDispatch } from '../../../../store/store';
import { updateUserRequest } from '../../../../store/slices/me';
import { InitialFormValues, initialValues, validationSchema, useStyles } from './DefaultVideoTextForm.helper';
import { getError } from '../../../../common/components/AuthForm/AuthForm.helper';
import { FSForm, FSFormFields, WrapperButton } from '../../../../common/components/AuthForm/AuthForm.style';
import { FormTextField } from '../../../../common/components/FormComponents/FormTextField/FormTextField';
import { getOrganizationById } from '../../../../store/slices/venue';
import { OrganizationAPIModel } from '../../../../api/models/venue';
import { ProfilePageStrings } from '../../../../common/localization/en';
// import { WebappdefaultVideoDetailsEditableValues } from '../../../../api/models/venue';

export const DefaultVideoTextForm = () => {
  const { defaultVideoDetails, id, displayName, returnUrl } = useTypedSelector((state) => state.me);
  const { properties } = useTypedSelector((state) => state.account.accountAttributes);
  const { orgId } = useTypedSelector((state) => state.account.account);
  const [organization, setOrganization] = useState<OrganizationAPIModel>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getOrganization = async () => {
      try {
        const response = await getOrganizationById({ id: orgId });
        if (response.id) {
          setOrganization(response);
        }
      } catch (err) {
        throw err;
      }
    };

    getOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const metaTitle =
    defaultVideoDetails?.metaTitle || properties['webapp.default-story-details']?.metaTitle || initialValues.metaTitle;
  const videoText =
    defaultVideoDetails?.videoText || properties['webapp.default-story-details']?.videoText || initialValues.videoText;
  const ctaBtnText =
    defaultVideoDetails?.ctaBtnText ||
    properties['webapp.default-story-details']?.ctaBtnText ||
    initialValues.ctaBtnText;
  const ctaBtnLinkUrl =
    defaultVideoDetails?.ctaBtnLinkUrl ||
    returnUrl ||
    properties['webapp.default-story-details']?.ctaBtnLinkUrl ||
    initialValues.ctaBtnLinkUrl;

  const editableProperty = properties?.['webapp.default-story-details']?.webAppSettings;

  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);

  const s = useStyles();

  const initialFormikValues = {
    metaTitle,
    videoText,
    ctaBtnText,
    ctaBtnLinkUrl,
  };

  const formik = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit: (values) => {
      onSubmitHandler(values);
    },
  });

  const { handleSubmit, values, handleBlur, handleChange, setFieldTouched, errors, isValid, dirty, touched } = formik;

  const getErrorHandler = (name: string) => {
    return getError(name, touched, errors);
  };

  const isSubmitButtonDisabled = useMemo(() => {
    return isValid && dirty && !disabledSubmitButton;
  }, [dirty, disabledSubmitButton, isValid]);

  const onSubmitHandler = async (values: InitialFormValues) => {
    const { metaTitle, /* videoText, */ ctaBtnText, ctaBtnLinkUrl } = values;
    setDisabledSubmitButton(true);
    dispatch(
      updateUserRequest({
        userId: id,
        defaultVideoDetails: {
          metaTitle,
          videoText: '',
          ctaBtnText,
          ctaBtnLinkUrl,
        },
      }),
    )
      .then(() => {
        setDisabledSubmitButton(false);
      })
      .catch(() => {
        setDisabledSubmitButton(false);
      });
  };

  return (
    <FSForm onSubmit={handleSubmit}>
      <FSFormFields>
        {editableProperty?.metaTitle && (
          <FormTextField
            onChange={handleChange}
            onBlur={handleBlur}
            setFieldTouched={setFieldTouched}
            value={values.metaTitle
              .replace('{Display Name}', displayName || 'me')
              .replace('{Org Name}', organization?.name || '')}
            label={ProfilePageStrings.ProfileTabTextFormField1}
            name="metaTitle"
            errorText={getErrorHandler('metaTitle')}
            multiline
            maxRows={3}
          />
        )}
        <>
          {!!editableProperty?.ctaBtnText && (
            <FormTextField
              onChange={handleChange}
              onBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              value={values.ctaBtnText}
              label={ProfilePageStrings.ProfileTabTextFormField4}
              name="ctaBtnText"
              errorText={getErrorHandler('ctaBtnText')}
              multiline
              maxRows={3}
            />
          )}
          {!!editableProperty?.ctaBtnLinkUrl && (
            <FormTextField
              onChange={handleChange}
              onBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              value={values.ctaBtnLinkUrl}
              label={ProfilePageStrings.ProfileTabTextFormField5}
              name="ctaBtnLinkUrl"
              errorText={getErrorHandler('ctaBtnLinkUrl')}
              multiline
              maxRows={2}
            />
          )}
        </>
      </FSFormFields>

      <WrapperButton>
        <button className={s.button} type="submit" disabled={!isSubmitButtonDisabled}>
          {ProfilePageStrings.ProfileTabTextFormCtaBtn}
        </button>
      </WrapperButton>
    </FSForm>
  );
};
