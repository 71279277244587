import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  backButton: {
    color: theme.palette.common.white,
    marginLeft: '39px',
  },
  timeText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    marginBottom: '10px',
  },
  instruction: {
    marginTop: '4px',
    marginBottom: '0px',
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '24px',
  },
  previewWrapper: {
    position: 'relative',
    height: 'var(--app-height)',
    minHeight: '550px',
    background: '#000001',
  },
  previewControlsWrapper: {
    position: 'absolute',
    bottom: '0',
    height: '100px',
    width: 'calc(100% + 2px)',
    left: '-1px',
    background: 'rgba(0, 0, 0, 0.70)',
    zIndex: 20,
  },
  buttonsWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  getReadyText: {
    textAlign: 'center',
    lineHeight: '150%',
    padding: '3px 0',
    fontSize: 'clamp(14px, 4vw, 17px )',
  },
  recordText: {
    fontWeight: 600,
    lineHeight: '150%',
    textAlign: 'center',
    padding: '3px 0',
  },
  recordCounter: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '150px',
    textShadow: '0px 0px 14px rgba(0, 0, 0, 0.35)',
    fontFamily: 'Poppins',
  },

  videoPreview: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    aspectRatio: 9 / 16,
    top: 0,
    cursor: 'pointer',
  },
  playIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, calc(-50% - 50px))',
    cursor: 'pointer',
    zIndex: 30,
  },
  videoPreviewWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  logoOverlay: {
    cursor: 'pointer',
    position: 'absolute',
  },
  submitVideoText: {
    fontWeight: 600,
    lineHeight: '150%',
    textAlign: 'center',
    padding: '3px 0',
  },
  submitControlsWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
  },
  retakeButton: {
    color: '#fff',
    border: '1px solid white',
    borderRadius: '60px',
    padding: '14px 24px',
  },
  submitButton: {
    borderRadius: '60px',
    padding: '14px 24px',
    backgroundColor: '#2D11AD',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2D11AD',
    },
  },
}));
