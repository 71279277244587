import styled from 'styled-components';
import { Theme } from '@mui/material';
import { theme } from '../../theme';

export const Button = styled.button<{ theme: Theme }>`
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme?.palette?.primary?.main};
  font-size: 14px;
  font-weight: bold;
  border-radius: var(--buttonBorderRadius);
`;

export const SectionDivider = styled.div`
  border-top: 1px solid var(--dividerColor);
  margin: 20px var(--inversePagePadding) 18px var(--inversePagePadding);
  opacity: 0.3;
`;

export const CommonContentWrapper = styled.div`
  width: 100%;
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    //  margin: 0 auto;
    // width: 670px;
  }
  @media (min-width: ${theme.breakpoints.values.lg}px) {
    // margin: 0 auto;
    // width: 670px;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin: 0 auto;
    margin-top: -10px;
    width: calc(100vw - 48px);
    height: 600px;
  }
`;

export const CommonCarouselWrapper = styled.div`
  @media (min-width: ${theme.breakpoints.values.xs}px) {
    height: calc(100vh - 400px);
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    height: calc(100vh - 300px);
  }
`;
