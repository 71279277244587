import { useEffect, useState } from 'react';
import { LayoutMode } from '../../common/constants/constants';
import { getLayoutFormat } from '../utilities';

export const useWindowResize = () => {
  const [layoutMode, setLayoutMode] = useState<LayoutMode | undefined>();

  useEffect(() => {
    const resizeHandler = () => {
      const newLayoutMode = getLayoutFormat(window.innerWidth);
      if (newLayoutMode !== layoutMode) {
        setLayoutMode(newLayoutMode);
      }
    };

    window.addEventListener('resize', resizeHandler);

    resizeHandler();

    return () => window.removeEventListener('resize', resizeHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return layoutMode;
};
