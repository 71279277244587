import { FC } from 'react';

interface Props {
  color?: string;
}
export const SuccessUploadIcon: FC<Props> = ({ color }) => {
  return (
    <svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.7085 3.08337H11.2918C7.03683 3.08337 3.5835 6.52129 3.5835 10.7609V19.98V21.5217C3.5835 25.7613 7.03683 29.1992 11.2918 29.1992H13.6043C14.0206 29.1992 14.5756 29.4767 14.8377 29.8159L17.1502 32.8838C18.1677 34.2405 19.8327 34.2405 20.8502 32.8838L23.1627 29.8159C23.4556 29.4305 23.9181 29.1992 24.396 29.1992H26.7085C30.9635 29.1992 34.4168 25.7613 34.4168 21.5217V10.7609C34.4168 6.52129 30.9635 3.08337 26.7085 3.08337ZM20.5418 21.198H11.2918C10.6597 21.198 10.1356 20.6738 10.1356 20.0417C10.1356 19.4096 10.6597 18.8855 11.2918 18.8855H20.5418C21.1739 18.8855 21.6981 19.4096 21.6981 20.0417C21.6981 20.6738 21.1739 21.198 20.5418 21.198ZM26.7085 13.4896H11.2918C10.6597 13.4896 10.1356 12.9655 10.1356 12.3334C10.1356 11.7013 10.6597 11.1771 11.2918 11.1771H26.7085C27.3406 11.1771 27.8647 11.7013 27.8647 12.3334C27.8647 12.9655 27.3406 13.4896 26.7085 13.4896Z"
        fill={color}
      />
    </svg>
  );
};
