import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<unknown, { color?: string }>(() => ({
  container: {
    width: '100%',
  },
  progressWrapper: {
    width: '100%',
    height: '16vw',
    maxHeight: '86px',
    borderRadius: '9px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '14px',
    paddingRight: '8px',
  },
  uploadText: {
    fontSize: 'clamp(18px,  5vw, 27px)',
    fontWeight: 500,
    lineHeight: 'clamp(20px,  5vw, 32px)',
    color: '#2D11AD',
  },
  keepOpenText: {
    fontSize: 'clamp(14px,  3.9vw, 21px)',
    fontWeight: 400,
    lineHeight: 'clamp(16px,  3.9vw, 24px)',
    [`@media (min-width: 430px)`]: {
      marginTop: '6px',
    },
  },
  uploadCompleteText: ({ color }) => ({
    fontSize: 'clamp(18px,  5vw, 27px)',
    fontWeight: 500,
    lineHeight: '20px',
    color,
  }),
  thanksText: {
    fontSize: 'clamp(14px,  3.9vw, 21px)',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#475467',
    [`@media (min-width: 430px)`]: {
      marginTop: '6px',
    },
  },
  stepsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    width: '100%',
  },
  stepText: {
    fontSize: 'clamp(14px,  3.9vw, 21px)',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  step: {
    display: 'flex',
    alignItems: 'center',
  },
  uploadErrorWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  uploadFailText: {
    fontSize: 'clamp(18px,  5vw, 27px)',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#B42318',
  },
  weakConnectText: {
    fontSize: 'clamp(14px,  3.9vw, 21px)',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#475467',
    [`@media (min-width: 430px)`]: {
      marginTop: '6px',
    },
  },
}));
