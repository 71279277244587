import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
  margin-bottom: 20px;
`;

export const PictureWrapper = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  object-position: center;
  object-fit: cover;
  flex-shrink: 0;
  overflow: hidden;
`;

export const MediaIconWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const PicturedLabel = styled.label``;

export const PicturedInput = styled.input`
  display: none;
`;

export const EditDefaultAvatar = styled.img``;
