import { useAppDispatch, useTypedSelector } from '../../../store/store';
import {
  changeStep,
  CreateStoryRequest,
  createUploadUrl,
  resetUploadingIterations,
  setUploadError,
  UploadStoryDetails,
  uploadVideo,
  uploadVideoAsFile,
} from '../../../store/slices/upload';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { EventNames } from '../../../common/constants/constants';
import { asyncgetVideoPosterWithCloudinary } from '../../../pages/VideoToolPage/utils';
import { getUpdatedVideoUrl, getVideoAspectRatio, getVideoDuration, isVideoARCloseToTargetAR } from '../../utilities';
import { useOnUploadProgress } from './useUploadProgress';
import { useAttributesFilterUrl, useLogoFilterEnabled } from '../useAttributesFilterUrl';
import { usePostStory } from '../usePostStory';
import { useTrackEvent } from '../../../common/hooks/useTrackEvent';
import { trackEvent as globalTrackEvent } from '../../../../utils/analytics/analytics';
import { UploadProcessStep } from '../../../store/storeModels';

export interface VideoPos {
  x: number;
  y: number;
  width: number;
}

export interface HandleSubmitFileProps {
  file: File | null;
  details: UploadStoryDetails;
  isPublic: boolean;
  videoPos: VideoPos;
  shouldPostStory?: boolean;
  webappRecorded: boolean;
}

export const useHandleSubmitUploadedFile = (
  setStoryId: Dispatch<SetStateAction<string>>,
  isAthleticSolutionsClient: boolean,
  returnUrl: string,
) => {
  const dispatch = useAppDispatch();

  const attributesFilterUrl = useAttributesFilterUrl();
  const logoEnabled = useLogoFilterEnabled();
  const { id } = useTypedSelector((state) => state.me);
  const { mainCategory } = useTypedSelector((state) => state.stories);
  const { account } = useTypedSelector((state) => state.account);
  const logoSrc = account.logo;
  const { id: categoryId } = mainCategory || {};

  const onUploadProgress = useOnUploadProgress();
  const postStory = usePostStory(setStoryId);
  const { trackEvent } = useTrackEvent();

  const handleUploadedFile = async ({
    file,
    details,
    isPublic,
    videoPos,
    shouldPostStory,
    webappRecorded,
  }: HandleSubmitFileProps): Promise<CreateStoryRequest | undefined> => {
    if (!file) return;

    dispatch(setUploadError(false));
    const duration = await getVideoDuration(file);
    const videoAr = await getVideoAspectRatio(file);
    const shouldFill = !isVideoARCloseToTargetAR(videoAr);

    try {
      const res = await createUploadUrl(file.type);
      if (!res) return;
      dispatch(changeStep(UploadProcessStep.VideoUploading));
      await uploadVideoAsFile({ options: res, data: file, onUploadProgress });
      trackEvent(EventNames.Upload_Completed);

      const url = await getUpdatedVideoUrl(
        res.downloadUrl,
        logoSrc && logoEnabled ? attributesFilterUrl : undefined,
        videoPos.width,
        trackEvent,
        shouldFill,
      );

      const newThumbnailUrl = (await asyncgetVideoPosterWithCloudinary(url || '', trackEvent, '.png')) || '';

      const urlWithoutLogo = await getUpdatedVideoUrl(res.downloadUrl, undefined, undefined, trackEvent);
      const altThumbnailUrl = await asyncgetVideoPosterWithCloudinary(urlWithoutLogo || '', trackEvent);

      const story: CreateStoryRequest = {
        categoryId: categoryId || '',
        url: url,
        thumbnailUrl: newThumbnailUrl,
        altThumbnailUrl,
        isPublic: isPublic,
        duration,
        details: {
          ...details,
          ctaBtnLinkUrl: isAthleticSolutionsClient ? returnUrl : details.ctaBtnLinkUrl || null,
        },
        webappRecorded,
      };

      if (!shouldPostStory) return story;

      await postStory(story);
      dispatch(resetUploadingIterations());
      return story;
    } catch (error) {
      globalTrackEvent({
        action: EventNames.Upload_Failed,
        accountId: account.id,
        errorMessage: JSON.stringify(error),
        userId: id,
      });
      dispatch(setUploadError(true));
      return undefined;
    }
  };

  const handleSubmitVideo = async (
    blob: string | undefined,
    details: UploadStoryDetails,
    isPublic: boolean,
    supportedMimeType: string,
    smsNotificationRef?: MutableRefObject<boolean | undefined>,
    webappRecorded?: boolean,
  ) => {
    createUploadUrl(supportedMimeType).then(async (res) => {
      if (blob && res) {
        dispatch(changeStep(UploadProcessStep.VideoUploading));
        try {
          await uploadVideo({ options: res, data: blob, onUploadProgress });
          const duration = await getVideoDuration(blob);
          trackEvent(EventNames.Upload_Completed);
          const url = await getUpdatedVideoUrl(
            res.downloadUrl,
            logoSrc && logoEnabled ? attributesFilterUrl : undefined,
            undefined,
            trackEvent,
          );

          const newThumbnailUrl = await asyncgetVideoPosterWithCloudinary(url || '', trackEvent, '.png');

          const urlWithoutLogo = await getUpdatedVideoUrl(res.downloadUrl, undefined, undefined, trackEvent);
          const altThumbnailUrl = await asyncgetVideoPosterWithCloudinary(urlWithoutLogo || '', trackEvent);

          postStory({
            categoryId: categoryId || '',
            url,
            thumbnailUrl: newThumbnailUrl,
            altThumbnailUrl,
            isPublic: isPublic,
            duration,
            sendSMSNotification: smsNotificationRef?.current || false,
            details: {
              ...details,
              ctaBtnLinkUrl: isAthleticSolutionsClient ? returnUrl : details.ctaBtnLinkUrl || null,
            },
            webappRecorded,
          });
        } catch (err) {
          globalTrackEvent({
            action: EventNames.Upload_Failed,
            accountId: account.id,
            errorMessage: JSON.stringify(err),
            userId: id,
          });
          dispatch(setUploadError(true));
          console.log(err);
        }
      }
    });
  };

  return { handleUploadedFile, handleSubmitVideo, postStory };
};
