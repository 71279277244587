import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
    width: '100%',
    marginTop: '10px',
  },
  TOS: {
    background: '#222124',
    borderRadius: '5px',
    padding: '1px 5px 9px 0',
  },
  termsWrapper: {
    margin: '0 auto',
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '25px !important',
  },
  iconButton: {
    borderRadius: '0',
    background: 'transparent !important',
    '&:hover': {
      background: 'transparent !important',
    },
  },
  TermsText: {
    textAlign: 'center',
    fontSize: '11px',
    color: theme.palette.common.white,
    [`@media (max-width: 380px)`]: {
      width: '220px',
      textAlign: 'center',
    },
  },
  termsLink: {
    color: theme.palette.common.white,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  TOSWrapper: {
    margin: '0 auto',
    width: '282px',
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    width: '81.9vw',
    height: '12.2vw',
    maxWidth: '442px',
    maxHeight: '52px',
    fontSize: 'clamp(16px, 4.4vw, 24px )',
    fontWeight: 600,
    borderRadius: '8px',
    '&:active': {
      transform: 'scale(0.99)',
    },
  },
  recordButton: {
    background: '#fff',
    color: '#2D11AD',
    border: '1px solid #D0D5DD',

    '&:active': {
      transform: 'scale(0.99)',
    },
    '&:hover': {
      background: '#fff',
    },
    '&:disabled': {
      color: '#DFDFDF',
      border: '1px solid #D0D5DD',
    },
  },
  uploadButton: {
    background: '#2D11AD',
    color: '#fff',
    marginBottom: '12px',

    '&:active': {
      transform: 'scale(0.99)',
    },
    '&:hover': {
      background: '#2D11AD',
    },
    '&:disabled': {
      background: '#DFDFDF',
    },
  },
  uploadButtonText: {
    color: 'white',
    fontWeight: 600,
    boxShadow: '0px 4px 7px -2px rgba(44, 44, 44, 0.15)',
  },
}));
