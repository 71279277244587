import { ClientsTypes } from '../../api/models/common';

export interface OrganizationAPIModel {
  createdAt: string;
  id: string;
  logo: string;
  name: string;
  refId: null | string;
  updatedAt: string;
}
export interface VenueAPIModel {
  id: string;
  name: string;
  orgId: string;
  logo: string;
  subdomain: string;
  scaleoOfferId: number | null;
  clientType: ClientsTypes;
  websiteUrl: string;
  businessAddress: string;
}

export type SharingOrder = {
  [key in keyof SharingSettings]?: number;
};

export interface VenueAttributesAPIModel {
  [key: string]: string;
}

export interface WebAppTipsModel {
  'tip-media-type': 'video' | 'image';
  'tip-media-url': string;
  'tip-text': string;
}
export interface WebappLoadingCardModel {
  'tip-media-type': 'video' | 'image';
  'tip-media-url': string;
  'tip-text': string;
}

export enum WebappDefaultStoryDetailsEditableValues {
  FULL = 'full',
  LIMITED = 'limited',
}
export interface WebappDefaultStoryDetails {
  ctaBtnLinkUrl: string;
  ctaBtnText: string;
  editable: WebappDefaultStoryDetailsEditableValues;
  metaDescription: string;
  metaTitle: string;
  videoText: string;
  videoMessage: string;
  webAppSettings: {
    metaTitle: boolean;
    videoText: boolean;
    ctaBtnText: boolean;
    ctaBtnLinkUrl: boolean;
    videoMessage: boolean;
  };
}

export interface WebappCard {
  mobile: {
    original: string | null;
    cropped: string | null;
  };
  desktop: {
    original: string | null;
    cropped: string | null;
  };
  tablet: {
    original: string | null;
    cropped: string | null;
  };
  title?: string;
  subtitle?: string;
  alignment?: string;
}
export interface SharingSettings {
  Message: boolean;
  Email: boolean;
  Twitter: boolean;
  Instagram: boolean;
  Facebook: boolean;
  Link: boolean;
  LinkedIn: boolean;
}

export enum LogoPresentation {
  Default = 'default',
  BrandColor = 'brand-color',
  SplashImage = 'splash-image',
}

export enum LogoPresentationLayout {
  Straight = 'straight',
  Curved = 'curved',
  Fade = 'fade',
}

export interface SignUpConfig {
  emailRequired: boolean;
  nameRequired: boolean;
  phoneRequired: boolean;
  logoPresentation?: LogoPresentation;
  logoPresentationLayout?: LogoPresentationLayout;
  showCompanyName?: boolean;
}

enum CampaignActivitiesTypes {
  CreateVideo = 'CREATE_VIDEO',
  Conversion = 'CONVERSION',
  Shares = 'SHARE',
  CallToAction = 'CALL_TO_ACTION',
  BonusPoints = 'BONUS_POINTS',
}

interface Activity {
  activityType: CampaignActivitiesTypes;
  points: number;
}

export enum VideoAppTheme {
  Dark = 'dark',
  Light = 'light',
}

export interface VenueProperties {
  'webapp.venue': {
    slug: string;
    'display-name': string;
    brandname: string;
    id: string;
  };
  'webapp.open-registration': boolean;
  'webapp.config': {
    'logo-img-url': string;
    'primary-color': string;
    'primary-light-color': string;
    'secondary-color': string;
    'text-color': string;
    'landing-card-url': string;
    'landing-card-text': string;
    'rewards-coming-soon-image': string;
    //layout with logo
    'filter-url': string;
    //something
    'filter-logo': string;
    'filter-logo-enabled': boolean;
    'filter-logo-position': string;
    'filter-overlay-enabled': boolean;
    //layout with overlay
    'filter-overlay-url': string;
    theme: VideoAppTheme;
    isThemeLayoutEnabled: boolean;
  };
  'webapp.sharing': {
    'backlink-cta-text': 'Donate' | 'Product Page';
    'meta-og-image': string;
    'meta-og-title': string;
    'meta-og-description': string;
    'meta-twitter-img': string;
    'meta-twitter-title': string;
    'meta-twitter-description': string;
    'meta-twitter-site-id': string;
    sharingSettings: SharingSettings;
    sharingSMSNotInContacts: boolean;
    sharingSMSSequence: boolean | undefined;
    sharingSMSSequenceAmount: number;
    sharingOrder: SharingOrder[];
  };
  'webapp.loading-cards': WebappLoadingCardModel | WebappLoadingCardModel[];
  'webapp.tips': WebAppTipsModel | WebAppTipsModel[];
  'webapp.welcome-video': string;
  'webapp.welcome-text': string[];
  'webapp.default-story-details': WebappDefaultStoryDetails;
  'webapp.cards-welcome': WebappCard;
  'webapp.cards-tip': WebappCard;
  'webapp.cards-edu': WebappCard;
  'webapp.signUp.config': SignUpConfig;
  'webapp.incentive.config': {
    title: string;
    action: string;
    imageUrl: string;
    points: number;
    activities: Activity[];
    fistTime?: string;
    name: string;
  };
  'webapp.launch-screen': {
    cover: 'white' | 'branding' | 'black';
    reveal: 'branding' | 'splash';
    type: 'logo' | 'name';
  };
}

export interface VenuePropsDTO {
  locale?: string;
  venueLogo?: string;
  metaOGUrl?: string;
  metaOGImg: string;
  metaOGVideo?: string;
  metaOGTitle: string;
  metaOGDescription: string;
  metaTwitterImg: string;
  metaTwitterTitle: string;
  metaTwitterDescription: string;
  metaTwitterSiteId: string;
  title?: string;
}

export interface HomePageProps extends VenuePropsDTO {
  accountId: string;
}
