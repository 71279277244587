import { makeStyles } from '@mui/styles';

export const useVideoPlayerStyles = makeStyles(() => ({
  captionIcon: {
    position: 'absolute',
    top: '34px',
    right: '26px',
    display: 'none',
    filter: 'opacity(0.85)',
    zIndex: 13,
    height: 'auto',
    width: 'fit-content',
  },
  captionIconDisplay: {
    display: 'block',
    '&:hover': {
      filter: 'opacity(1)',
    },
  },
}));
