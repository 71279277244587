import React, { FC, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { useStyles } from './CampaignsSwiper.styles';
import { UserIncentiveCampaignApiModel } from '../../../api/models/rewards';
import { RewardBlock } from '../RewardBlock/RewardBlock';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Button } from '@mui/material';
import { SliderNextArrow } from '../../assets/SliderNextArrow';
import { SliderPrevArrow } from '../../assets/SliderPrevArrow';
import { useTypedSelector } from '../../../store/store';
import type { Swiper as SwiperType } from 'swiper/types';
import { isMobile } from 'react-device-detect';
import { VideoAppTheme } from '../../../api/models/venue';

interface Props {
  userIncentiveCampaigns: UserIncentiveCampaignApiModel[];
  isThemeLayoutEnabled?: boolean;
  layoutTheme?: VideoAppTheme;
}

export const CampaignsSwiper: FC<Props> = ({ userIncentiveCampaigns, isThemeLayoutEnabled, layoutTheme }) => {
  const SWIPE_SPEED = 400;

  const { properties } = useTypedSelector((state) => state.account.accountAttributes);
  const navItemColor = properties?.['webapp.config']?.['primary-color'] || '#8B89A0';

  const classes = useStyles({ isThemeLayoutEnabled, layoutTheme });
  const sliderRef = useRef<SwiperRef | null>(null);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slideNext();
    }
  };

  const onSlideChange = (swiper: SwiperType) => {
    setActiveSlideIndex(swiper.activeIndex);
  };

  const slideTo = (index: number) => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slideTo(index);
    }
  };

  return (
    <Box className={classes.container}>
      <Box>
        <Swiper slidesPerView="auto" spaceBetween={10} ref={sliderRef} speed={SWIPE_SPEED} onSlideChange={onSlideChange}>
          {userIncentiveCampaigns.map((campaign) => (
            <SwiperSlide key={campaign.campaign.id} className={classes.swiperSlide}>
              <RewardBlock userIncentiveCampaign={campaign} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      {userIncentiveCampaigns.length > 1 && (
        <Box className={classes.navWrapper}>
          {!isMobile && !isThemeLayoutEnabled && (
            <Button onClick={handlePrev} startIcon={<SliderPrevArrow />} className={classes.btn} />
          )}
          <Box className={classes.navItemsWrapper}>
            {userIncentiveCampaigns.map((campaign, index) => (
              <Box
                key={campaign.campaign.id}
                className={classes.navItem}
                onClick={() => slideTo(index)}
                style={{
                  backgroundColor:
                    activeSlideIndex !== index
                      ? navItemColor
                      : isThemeLayoutEnabled && layoutTheme === VideoAppTheme.Light
                      ? '#475467'
                      : '#fff',
                }}
              ></Box>
            ))}
          </Box>
          {!isMobile && !isThemeLayoutEnabled && (
            <Button onClick={handleNext} endIcon={<SliderNextArrow />} className={classes.btn} />
          )}
        </Box>
      )}
    </Box>
  );
};
