import { Box, Button, Container, Input, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { trackEvent } from '../../../utils/analytics/analytics';
import { AuthInitialFormValues } from '../../common/commonTypes';
import { getError } from '../../common/components/AuthForm/AuthForm.helper';
import { DeactivatedUserModal } from '../../common/components/DeactivatedUserModal/DeactivatedUserModal';
import { ExistingUserModal } from '../../common/components/ExistingUserModal/ExistingUserModal';
import { PhoneFieldInternational } from '../../common/components/FormComponents/PhoneFieldInternational/PhoneFieldInternational';
import { UploadProgressBar } from '../../common/components/UploadProgressBar/UploadProgressBar';
import { VerificationModal } from '../../common/components/VerificationModal/VerificationModal';
import { authFormInitialValues, EventNames } from '../../common/constants/constants';
import { AuthFormStrings } from '../../common/localization/en';
import { useQuery } from '../../services/hooks/useQuery';
import {
  requestLoginVerificationCode,
  requestSignInVerificationCode,
  resetMeError,
  updateUserRequest,
} from '../../store/slices/me';
import { setPendingUploadedStory } from '../../store/slices/upload';
import { useAppDispatch, useTypedSelector } from '../../store/store';
import { VerificationCodeQueryParams } from '../../store/storeModels';
import { getValidationSchema } from '../MagicLinkPage/MagicLinkForm/MagicLinkForm.helper';
import { useVerificationStyles } from './VerificationScreen.styles';

interface VerificationScreenProps {}

const VerificationScreen: FC<VerificationScreenProps> = () => {
  const styles = useVerificationStyles();
  const queries = useQuery();
  const dispatch = useAppDispatch();
  const { account } = useTypedSelector((state) => state.account);
  const { currentCampaign, primaryCampaign } = useTypedSelector((state) => state.rewards);
  const {
    account: { id: accountId },
  } = useTypedSelector((state) => state.account);
  const {
    registerErrorType,
    phoneNumber: mePhoneNumber,
    id,
    firstName: storeFirstName,
    lastName: storeLastName,
  } = useTypedSelector((state) => state.me);
  const { email, firstName, phoneNumber, lastName } = {
    email: queries.get('svIdent') || '',
    firstName: queries.get('svUn')?.split(' ')[0] || storeFirstName || '',
    lastName: queries.get('svUn')?.split(' ')[1] || storeLastName || '',
    phoneNumber: queries.get('phone') || mePhoneNumber || '',
  };

  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
  const [codeId, setCodeId] = useState('');
  const [modal, setModal] = useState<'resend' | 'unregistered' | null>(null);
  const [isDeactivatedUserModalOpen, setIsDeactivatedUserModalOpen] = useState(false);
  const [isExistingUserModalOpen, setIsExistingUserModalOpen] = useState(false);

  const validateEmail = currentCampaign ? !!currentCampaign?.emailRequired : !!primaryCampaign?.emailRequired;

  const initValues: AuthInitialFormValues = {
    ...authFormInitialValues,
    email,
    firstName,
    lastName,
    phoneNumber: mePhoneNumber,
  };

  const formikProps = {
    initialValues: initValues,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema({
      phoneNumber: true,
      firstName: false,
      lastName: false,
      email: validateEmail,
    }),
    onSubmit: async (values: AuthInitialFormValues) => {
      await onSubmitHandler(values);
    },

    onReset: () => {
      setDisabledSubmitButton(false);
    },
  };

  const formik = useFormik({
    ...formikProps,
    validationSchema: getValidationSchema({
      phoneNumber: true,
      firstName: false,
      lastName: false,
      email: validateEmail,
    }),
  });
  const fullName =
    formik.values.firstName && formik.values.lastName
      ? `${formik.values.firstName} ${formik.values.lastName}`
      : formik.values.firstName || '';

  const onSubmitHandler = useCallback(
    async (values: AuthInitialFormValues) => {
      console.log('val', values);

      const magicLinkQueryParams: VerificationCodeQueryParams = {
        accountId,
        phoneNumber: values.phoneNumber || phoneNumber,
        captchaResponse: null,
        details: {
          email: null,
          firstName,
          lastName,
        },
      };

      setDisabledSubmitButton(true);

      dispatch(resetMeError());

      const isNewUserFlow = id !== '';
      const resultType = isNewUserFlow
        ? ((
            await dispatch(
              updateUserRequest({
                phoneNumber,
                email: email || null,
                userId: id,
              }),
            )
          ).type as string)
        : null;

      const isPhoneAlreadyExists = resultType && resultType.includes('rejected');

      if (!isPhoneAlreadyExists) {
        dispatch(setPendingUploadedStory(null));
      }

      const requiresToken = false;

      const code =
        !isNewUserFlow || isPhoneAlreadyExists
          ? await requestLoginVerificationCode(magicLinkQueryParams, requiresToken)
          : await requestSignInVerificationCode(magicLinkQueryParams);

      if (code.errorStatus === 429) {
        setDisabledSubmitButton(false);
        return;
      }

      if (code.errorStatus === 403) {
        setIsDeactivatedUserModalOpen(true);
        setDisabledSubmitButton(false);
        return;
      }

      if (!code.id) {
        setDisabledSubmitButton(false);
        setModal('unregistered');
      } else {
        setModal('resend');
        setCodeId(code.id);
        setDisabledSubmitButton(false);
      }
    },
    [accountId, phoneNumber, firstName, lastName, dispatch, id, email],
  );

  useEffect(() => {
    trackEvent({
      action: EventNames.Phone_Verification_Page,
      location: window.location.href,
      accountId: account.id,
      userId: id,
    });
  }, [account.id, id]);

  useEffect(() => {
    if (registerErrorType === 'userAlreadyExists') {
      setIsExistingUserModalOpen(true);
      dispatch(resetMeError());
    }
  }, [dispatch, registerErrorType]);

  const handlePhoneChange = (val: string) => {
    formik.setFieldValue('phoneNumber', '+' + val);
  };

  const handleTrackPhoneNumberBlur = () => {
    trackEvent({
      action: EventNames.Signup_Phone_Typed,
      location: window.location.href,
      accountId: account.id,
      phoneNumber: formik.values.phoneNumber,
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      email: formik.values.email,
      form: 'verification_screen',
    });
  };

  const trackCountryCodeChange = () => {
    trackEvent({
      action: EventNames.Phone_Country_Select,
      location: window.location.href,
      accountId: account.id,
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      email: formik.values.email,
      phoneNumber: formik.values.phoneNumber,
      form: 'verification_screen',
    });
  };

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldTouched(event.target.name);
    formik.handleChange(event);
  };

  const getErrorHandler = (name: string, disabled?: boolean) => {
    if (disabled) {
      return formik.values[name] ? formik.errors[name] : '';
    }
    return getError(name, formik.touched, formik.errors);
  };

  const sendVerificationCodeBtnDisabled =
    disabledSubmitButton || formik.isSubmitting || !!formik.errors.phoneNumber || !formik.dirty || !!formik.errors.email;

  return (
    <Box>
      <Container
        sx={{
          position: 'absolute',
          zIndex: 2,
          transform: `translateX(-50%)`,
          marginTop: '-20px',
        }}
        className={styles.Container}
      >
        <Box className={styles.progressBar}>
          <UploadProgressBar />
        </Box>
        <DeactivatedUserModal
          isOpen={isDeactivatedUserModalOpen}
          onClose={() => {
            setIsDeactivatedUserModalOpen(false);
          }}
        />
        <VerificationModal
          modalType={modal}
          phoneNumber={formik.values.phoneNumber}
          setModalType={setModal}
          codeId={codeId}
          onBackClick={() => {}}
          accountId={accountId}
        />
        <ExistingUserModal
          isOpen={isExistingUserModalOpen}
          onClose={() => setIsExistingUserModalOpen(false)}
          phoneNumber={formik.values.phoneNumber}
          goToSignup={() => {}}
          trackProperties={{ accountId, phoneNumber, name: fullName, email }}
        />
        <form
          className={styles.form}
          onSubmit={formik.handleSubmit}
          style={{
            bottom: window.innerHeight < 615 ? '140px' : '180px',
          }}
        >
          <PhoneFieldInternational
            onChange={handlePhoneChange}
            name="phoneNumber"
            value={formik.values.phoneNumber || ''}
            error={formik.errors.phoneNumber}
            placeholder={'Enter Phone Number'}
            label={'Let’s verify its you'}
            className={styles.textField}
            showErrorOnBlur={true}
            setTouched={() => {
              formik.setFieldTouched('phoneNumber', true);
              handleTrackPhoneNumberBlur();
            }}
            trackCountryCodeChange={trackCountryCodeChange}
            isOutlinedForLightTheme={false}
            inputFontSize="clamp(16px, 4.4vw, 24px)"
          />
          {validateEmail && (
            <Box style={{ marginTop: '10px' }}>
              <Input
                className={styles.input}
                value={formik.values.email}
                onChange={handleFieldChange}
                disableUnderline={true}
                error={!!getErrorHandler('email', true)}
                name="email"
                placeholder={'Enter an email'}
                onBlur={formik.handleBlur}
              />
              {getErrorHandler('email', true) && (
                <Typography className={styles.errorText}>{formik.errors.email}</Typography>
              )}
            </Box>
          )}
          <Typography className={styles.messageRateLabel}>{AuthFormStrings.WellSentYouCode}</Typography>
          <Button
            classes={{ disabled: styles.DisabledSubmitButton }}
            className={styles.AuthSubmitBtn}
            sx={{
              height: '48px',
              borderRadius: '12px',
            }}
            variant="contained"
            fullWidth
            disabled={sendVerificationCodeBtnDisabled}
            type="submit"
          >
            Send Verification Code
          </Button>
          <Typography className={styles.messageRateText} color={'#767676'}>
            {AuthFormStrings.MessageRate}
          </Typography>
        </form>
      </Container>
    </Box>
  );
};

export default VerificationScreen;
