import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme, { thumbnailUrl: string }>(() => ({
  videoTextWrapper: {
    position: 'absolute',
    right: '20px',
    height: '55px',
    left: '20px',
    marginTop: '16px',
    zIndex: '2',
    background: 'rgba(0, 0, 0, 0.7)',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
  },
  videoBlockWrapper: {
    position: 'relative',
    width: '332px',
    height: '240px',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    background: '#1e1e1e' /* '#fff' */,
    aspectRatio: '9.1/16',
  },
  videoBlockOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '0px',
    top: '0px',
    overflow: 'hidden',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
  },
  videoBlockOverlayInner: {
    filter: 'blur(7px)',
    transform: ' scale(1.1)',
    opacity: '0.6',
  },
  videoWrapper: (props) => ({
    width: '100%',
    height: '100%',
    position: 'relative',
    background: `url(${props.thumbnailUrl}), lightgray 50% / cover no-repeat, #000`,
  }),
  videoText: {
    minheight: '40px',
    maxHeight: '55px',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '12px',
    alignSelf: 'center',
    overflow: 'auto',
  },
  videoTextWrapperbubble: {
    position: 'absolute',
    width: '0',
    height: '0',
    left: '60px',
    marginTop: '71px',
    transform: 'rotate(-180deg)',
    zIndex: '2',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '15px solid rgba(0, 0, 0, 0.7)',
  },
  icons: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.5) ',
      opacity: '0.6',
    },
  },

  buttonPlayPause: {
    position: 'absolute',
    width: '54px',
    height: '54px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backdropFilter: 'blur(20px)',

    zIndex: 200,
    transition: 'all 0.2s ease-in-out',
    fontSize: 30,
  },
  videoOverlayImg: {
    width: '332px',
    height: '240px',
    objectFit: 'cover',
  },
  videoDuration: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    background: '#000000',
    width: '60px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '10px',
    letterSpacing: '0em',
    borderRadius: '6px',
  },
}));
