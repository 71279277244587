export const MuteOffIcon = () => (
  <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M55 27.5C55 31.1114 54.2887 34.6873 52.9067 38.0238C51.5247 41.3602 49.499 44.3918 46.9454 46.9454C44.3918 49.499 41.3602 51.5247 38.0238 52.9067C34.6873 54.2887 31.1114 55 27.5 55C23.8886 55 20.3127 54.2887 16.9762 52.9067C13.6398 51.5247 10.6082 49.499 8.05456 46.9454C5.50095 44.3918 3.47532 41.3602 2.09331 38.0238C0.711308 34.6873 -5.38133e-08 31.1114 0 27.5C1.08681e-07 20.2065 2.89731 13.2118 8.05456 8.05456C13.2118 2.89731 20.2065 0 27.5 0C34.7935 0 41.7882 2.89731 46.9454 8.05456C52.1027 13.2118 55 20.2065 55 27.5Z"
      fill="#1E1E1E"
      fillOpacity="0.6"
    />
    <path
      d="M38.1534 17.8749C40.1022 20.584 41.25 23.9079 41.25 27.4999C41.25 31.0919 40.1022 34.4158 38.1534 37.1249M32.6498 21.9999C33.7373 23.5589 34.375 25.4549 34.375 27.4999C34.375 29.5449 33.7373 31.4409 32.6498 32.9999M24.2472 18.3777L19.8944 22.7305C19.6566 22.9683 19.5377 23.0872 19.3989 23.1723C19.2759 23.2477 19.1417 23.3032 19.0014 23.3369C18.8432 23.3749 18.675 23.3749 18.3387 23.3749H15.95C15.1799 23.3749 14.7949 23.3749 14.5008 23.5248C14.242 23.6566 14.0317 23.8669 13.8999 24.1257C13.75 24.4198 13.75 24.8048 13.75 25.5749V29.4249C13.75 30.195 13.75 30.58 13.8999 30.8741C14.0317 31.1329 14.242 31.3432 14.5008 31.475C14.7949 31.6249 15.1799 31.6249 15.95 31.6249H18.3387C18.675 31.6249 18.8432 31.6249 19.0014 31.6629C19.1417 31.6966 19.2759 31.7521 19.3989 31.8275C19.5377 31.9126 19.6566 32.0315 19.8944 32.2693L24.2472 36.6221C24.8362 37.2111 25.1307 37.5056 25.3836 37.5255C25.603 37.5428 25.8174 37.454 25.9603 37.2866C26.125 37.0938 26.125 36.6773 26.125 35.8443V19.1555C26.125 18.3225 26.125 17.906 25.9603 17.7132C25.8174 17.5458 25.603 17.457 25.3836 17.4743C25.1307 17.4942 24.8362 17.7887 24.2472 18.3777Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
