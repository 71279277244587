import { useTheme } from '@mui/styles';
import React from 'react';

export const XBlueIcon = () => {
  const theme = useTheme();

  return (
    <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="56" height="56" rx="28" fill="currentColor" />
      <path
        d="M31.0399 26.4594L40.9683 15.1667H38.6159L29.9912 24.9699L23.1078 15.1667H15.1667L25.5781 29.9922L15.1667 41.8333H17.519L26.6213 31.4784L33.8922 41.8333H41.8333L31.0399 26.4594ZM27.817 30.1224L26.7604 28.6451L18.3674 16.902H21.9811L28.7566 26.3827L29.8086 27.8599L38.6148 40.1827H35.001L27.817 30.1224Z"
        fill={theme.palette.primary.contrastText}
      />
    </svg>
  );
};
