import { VideoAppTheme } from '../../api/models/venue';
import { useTypedSelector } from '../../store/store';

export const useThemeLayout = () => {
  const { accountAttributes } = useTypedSelector((state) => state.account);
  const theme = accountAttributes.properties?.['webapp.config']?.theme || VideoAppTheme.Dark;
  const isThemeLayoutEnabled = accountAttributes.properties?.['webapp.config']?.isThemeLayoutEnabled;

  const textColorMap: Record<VideoAppTheme, string> = {
    [VideoAppTheme.Dark]: '#fff',
    [VideoAppTheme.Light]: '#1A1A1A',
  };

  const backgroundColorMap: Record<VideoAppTheme, string> = {
    [VideoAppTheme.Dark]: '#1C1B1B',
    [VideoAppTheme.Light]: '#fff',
  };

  return {
    theme,
    isThemeLayoutEnabled,
    themeTextColor: textColorMap[theme],
    themeBackgroundColor: backgroundColorMap[theme],
    isLightTheme: isThemeLayoutEnabled && theme === VideoAppTheme.Light,
    isDarkTheme: isThemeLayoutEnabled && theme === VideoAppTheme.Dark,
    isLoading: accountAttributes.isLoading,
  };
};
