import { useAppDispatch } from '../../../store/store';
import { addUploadingIteration, setProgress } from '../../../store/slices/upload';

export const useOnUploadProgress = () => {
  const dispatch = useAppDispatch();

  const onUploadProgress = (event: ProgressEvent) => {
    let percentCompleted = Math.round((event.loaded * 100) / event.total);
    dispatch(setProgress(percentCompleted));
    dispatch(addUploadingIteration({ timeStamp: event.timeStamp, loaded: event.loaded, total: event.total }));
  };

  return onUploadProgress;
};
