import styled from 'styled-components';
import { TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const TextFieldStyled = styled(TextField)<{ theme: Theme }>`
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -16px;
  }
  .MuiFormHelperText-root.Mui-error {
    color: ${(props) => props?.theme?.palette?.error?.main} !important;
  }
  .MuiFormHelperText-root.Mui-error .MuiInput-underline:after {
    border-bottom: 2px solid ${(props) => props?.theme?.palette?.error?.main};
  }

  .MuiInput-input::placeholder {
    color: ${(props) => (props.error ? props?.theme.palette.error.main : '#94a5ab')};
    opacity: 1;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme?.palette?.primary?.main};
  }
`;

export const useTextFieldStyles = makeStyles((theme: Theme) => ({
  TextField: {
    '& label': {
      fontSize: '12px',
    },
    '& label.MuiInputLabel-shrink': {
      fontSize: '12px',
      transform: 'scale(1)',
    },
    '& label.Mui-error': {
      color: theme.palette.error.main,
    },
    '& label.Mui-error.MuiInputLabel-shrink': {
      transform: 'scale(1)',
      color: theme.palette.error.main,
    },
    '& .Mui-error.MuiInput-underline:after': {
      borderBottom: `2px solid ${theme.palette.error.main}`,
    },
    '& .Mui-error.MuiInput-underline:before': {
      borderBottom: `2px solid ${theme.palette.error.main}`,
    },
    '& .Mui-error': {
      '& ::placeholder': {
        color: theme.palette.error.main,
      },
    },
    '& ::placeholder': {
      color: 'rgb(199, 199, 217)',
    },
  },
}));
