import styled, { keyframes } from 'styled-components';
import makeStyles from '@mui/styles/makeStyles';

const progressAnimation = keyframes`
  from{
    border-color: #fff;
  }
  to {
    border-color: #FF0000;
  }
`;

const ANIMATION_INTERVAL = '0.8s';

export const ProgressButton = styled.button`
  position: relative;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
  align-self: center;
  padding: 0;
  cursor: pointer;

  width: 55px;
  height: 55px;

  &.overTimeLimit {
    animation: ${progressAnimation} ${ANIMATION_INTERVAL} linear infinite;
  }
`;

export const ActivityState = styled.span`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ff0000;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.2s ease-in-out;
  transform: translate(-50%, -50%) scale(0.9);

  &.active {
    transform: translate(-50%, -50%) scale(0.4);
    border-radius: 16px;
  }
`;

export const ProgressSvg = styled.svg`
  display: inline-block;
  max-width: 100%;
  transform: rotate(-90deg);
`;

const strokeOffset = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

export const AnimatedCircle = styled.circle`
  fill: none;
  transition: ${strokeOffset} 800ms linear;

  &.active {
    animation: ${strokeOffset} 30s linear forwards;
  }
`;

export const PlainCircle = styled.circle`
  fill: none;
`;

export const useStyles = makeStyles(() => ({
  square: {},
}));
