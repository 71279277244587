import { Box } from '@mui/material';
import { FC, useEffect, useRef } from 'react';

import { useTypedSelector } from '../../../store/store';
import {
  // MockVideoWrapper,
  // MockImgWrapper,
  PreviewImage,
  PreviewImageWrapper,
  Video,
  VideoWrapper,
} from './RecordPreview.style';
import { useAttributesFilterUrl } from '../../../services/hooks/useAttributesFilterUrl';

interface RecordPreviewProps {
  stream: MediaStream | null;
}

export const RecordPreview: FC<RecordPreviewProps> = ({ stream }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { mainCategory } = useTypedSelector((state) => state.stories);
  const { filterUrl } = mainCategory || {};

  const { accountAttributes } = useTypedSelector((state) => state.account);

  const attributesFilterUrl = useAttributesFilterUrl();

  const filterLogoEnabled = accountAttributes.properties?.['webapp.config']?.['filter-logo-enabled'];
  // const filterOverlayEnabled = accountAttributes.properties?.['webapp.config']?.['filter-overlay-enabled'];
  const logoUrl = accountAttributes.properties?.['webapp.config']?.['filter-url'];
  // const overlayUrl = accountAttributes.properties?.['webapp.config']?.['filter-overlay-url'];

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream)
    return (
      <VideoWrapper style={{ opacity: 1, pointerEvents: 'none' }}>
        <Box bgcolor="black" width="100%" height="100%" />
        <PreviewImageWrapper></PreviewImageWrapper>
      </VideoWrapper>
    );

  return (
    <VideoWrapper>
      <Video ref={videoRef} playsInline muted autoPlay />

      <PreviewImageWrapper>
        {filterLogoEnabled ? (
          <PreviewImage src={logoUrl || attributesFilterUrl || filterUrl || ''} className="no-click" />
        ) : null}
      </PreviewImageWrapper>
    </VideoWrapper>
  );
};
