import { FC } from "react"

export const VideoPlayIcon:FC= () => (
  <svg
    width={62}
    height={62}
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.0523 5.16602H23.9473V16.4293H38.0523V5.16602Z"
      fill="#454545"
    />
    <path
      d="M41.9277 5.16602V16.4293H56.4977C55.1802 9.32518 49.9361 5.19185 41.9277 5.16602Z"
      fill="#454545"
    />
    <path
      d="M5.1665 20.3047V41.8239C5.1665 51.2272 10.7723 56.833 20.1757 56.833H41.824C51.2273 56.833 56.8332 51.2272 56.8332 41.8239V20.3047H5.1665ZM37.3032 41.798L31.9298 44.898C30.7932 45.5438 29.6823 45.8797 28.649 45.8797C27.874 45.8797 27.1765 45.6989 26.5307 45.3372C25.0323 44.4847 24.2057 42.728 24.2057 40.4547V34.2547C24.2057 31.9814 25.0323 30.2247 26.5307 29.3722C28.029 28.4939 29.9407 28.6489 31.9298 29.8114L37.3032 32.9114C39.2923 34.048 40.3773 35.6497 40.3773 37.3805C40.3773 39.1114 39.2665 40.6355 37.3032 41.798Z"
      fill="#454545"
    />
    <path
      d="M20.0724 5.16602C12.0641 5.19185 6.81994 9.32518 5.50244 16.4293H20.0724V5.16602Z"
      fill="#454545"
    />
  </svg>
);

