import styled from 'styled-components';

export const Container = styled.div<{ $isLayoutThemeEnabled?: boolean }>`
  width: 100%;
  color: #fff;
  z-index: 1;
  padding: 0;
  overflow: auto;
  border-radius: 12px;
  margin: 0 auto;
  width: 336px;
  min-height: 530px;
  background: ${(props) =>
    props.$isLayoutThemeEnabled
      ? 'none'
      : 'linear-gradient(180deg, rgba(0, 0, 0, 0.46) 35.94%, rgba(0, 0, 0, 0.46) 100%)'};
  padding: 40px 8px;
  // outline: 1px solid green;
`;
