import { Box, Button, Container, Modal, Stack, Typography } from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import { FC, useEffect, useState } from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import PhoneInput from 'react-phone-input-2';
import { trackEvent } from '../../../../utils/analytics/analytics';
import { MeApiModel } from '../../../api/models/auth';
import { loginWithVerificationCode, requestMergeUser } from '../../../store/slices/me';
import { useAppDispatch, useTypedSelector } from '../../../store/store';
import { EventNames } from '../../constants/constants';
import { VerificationModalStrings } from '../../localization/en';
import { useStyles } from './VerificationModal.helper';
import { getUserIncentiveCampaigns } from '../../../store/slices/rewards';
import { useThemeLayout } from '../../hooks/useThemeLayout';
import { isMobile } from 'react-device-detect';

interface Props {
  modalType: 'resend' | 'unregistered' | null;
  phoneNumber: string;
  setModalType: (modalType: 'resend' | 'unregistered' | null) => void;
  codeId?: string;
  onBackClick?: () => void;
  onLogin?: (data: { isExistingUser: boolean }) => void;
  accountId?: string;
}

export const VerificationModal: FC<Props> = ({
  modalType,
  phoneNumber,
  setModalType,
  codeId,
  onBackClick,
  onLogin,
  accountId,
}) => {
  const styles = useStyles({ modal: modalType });
  const { error, id: currentUserId } = useTypedSelector((state) => state.me);

  const [isVerificationBtnEnabled, setIsVerificationBtnEnabled] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerificationCodeError, setIsVerificationCodeError] = useState(false);
  const { isDarkTheme } = useThemeLayout();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (codeId) {
      if (modalType === 'unregistered') {
        setIsVerificationBtnEnabled(true);
      }
    } else {
      setIsVerificationBtnEnabled(true);
    }
  }, [modalType, codeId]);

  useEffect(() => {
    if (codeId) {
      setIsVerificationCodeError(error || false);
    }
  }, [error, codeId]);

  const handleBackBtn = () => {
    setIsVerificationCodeError(false);
    setVerificationCode('');
    setModalType(null);
  };

  const handleVerify = () => {
    const token = localStorage.getItem('authToken');
    setVerificationCode('');
    if (codeId) {
      dispatch(loginWithVerificationCode({ codeId, verificationCode })).then((data) => {
        const id = (data as PayloadAction<MeApiModel>)?.payload?.user?.id;
        if (id) {
          trackEvent({
            action: EventNames.Phone_Verification_Success,
            location: window.location.href,
            accountId: accountId || '',
            phoneNumber: phoneNumber,
            verificationCode,
          });
          trackEvent({
            action: EventNames.User_Login,
            location: window.location.href,
            accountId: accountId || '',
            userId: id,
          });
          window.gtag('set', { userId: id });
        } else {
          trackEvent({
            action: EventNames.Phone_Verification_Failure,
            location: window.location.href,
            accountId: accountId || '',
            phoneNumber: phoneNumber,
            verificationCode,
          });
        }
        const isExistingUser = currentUserId !== id;
        if (isExistingUser) {
          dispatch(
            requestMergeUser({
              id: id,
              accessToken: token || '',
              userToMerge: currentUserId,
            }),
          );
        }
        dispatch(getUserIncentiveCampaigns({ userId: id }));
        onLogin?.({ isExistingUser });
      });
    } else {
      setIsVerificationBtnEnabled(true);
      setIsVerificationCodeError(true);
    }
  };

  const isUSPhoneNumber = phoneNumber?.substring(0, 2) === '+1';

  return (
    <Container>
      {!!modalType && (
        <Modal open={!!modalType}>
          <Box
            className={styles.container}
            style={isMobile ? { bottom: modalType === 'resend' ? '150px' : '118px' } : {}}
          >
            <Stack
              className={styles.container}
              style={{
                background: '#fff',
                height: modalType === 'resend' ? (isMobile ? '292px' : 'auto') : 'auto',
              }}
            >
              <Typography className={styles.title} color={'#000'}>
                {modalType === 'resend' ? VerificationModalStrings.Title : VerificationModalStrings.TitleUnregistered}
              </Typography>
              <div className={styles.phoneWrapper} style={{ flexDirection: modalType === 'resend' ? 'row' : 'column' }}>
                <Typography className={styles.subtitle} color={'#475467'}>
                  {modalType === 'resend'
                    ? VerificationModalStrings.Subtitle
                    : VerificationModalStrings.SubtitleUnregistered}
                </Typography>
                <Typography
                  component={'span'}
                  className={`${styles.phoneNumber} ${modalType === 'unregistered' ? styles.unregisteredPhone : ''}`}
                >
                  <PhoneInput
                    value={isUSPhoneNumber ? phoneNumber?.substring(2) : phoneNumber}
                    disabled
                    inputStyle={{
                      color: '#1A1A1A',
                    }}
                    disableCountryCode={isUSPhoneNumber}
                    defaultMask="(...) ...-...."
                    alwaysDefaultMask={isUSPhoneNumber}
                  />
                </Typography>
              </div>
              {modalType === 'resend' && (
                <>
                  <div
                    className={`${styles.verificationCode} ${
                      isVerificationCodeError ? styles.verificationCodeError : ''
                    }`}
                  >
                    <ReactInputVerificationCode
                      autoFocus={false}
                      value={verificationCode}
                      placeholder=""
                      length={4}
                      onChange={(newValue) => {
                        if (newValue !== '') {
                          setVerificationCode(newValue);
                          setIsVerificationBtnEnabled(false);
                          setIsVerificationCodeError(false);
                        }

                        setIsVerificationBtnEnabled(newValue.length === 4);
                      }}
                    />
                  </div>
                  {isVerificationCodeError && (
                    <Typography className={styles.verificationError}>{VerificationModalStrings.InvalidCode}</Typography>
                  )}
                </>
              )}
              {modalType === 'resend' && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={modalType === 'resend' ? handleVerify : handleBackBtn}
                  className={`${styles.btnVerify} ${
                    modalType === 'resend' && !isVerificationCodeError ? '' : styles.btnVerifyUnregistered
                  }`}
                  disabled={!isVerificationBtnEnabled}
                >
                  {VerificationModalStrings.VerifyAccount}
                </Button>
              )}
              {modalType === 'resend' && (
                <Typography
                  className={styles.bottomMessage}
                  sx={{
                    width: '59.7vw',
                    maxWidth: '316px',
                    m: '0 auto',
                    mt: '0px !important',
                    color: isDarkTheme ? '#fff' : '#8B89A0',
                  }}
                >
                  {VerificationModalStrings.OptTextSMS}
                </Typography>
              )}
              <Button
                size="small"
                variant="text"
                className={styles.btnResend}
                onClick={() => {
                  handleBackBtn();
                  onBackClick?.();
                }}
                style={{ marginBottom: 16, color: '#1A1A1A' }}
              >
                {VerificationModalStrings.Back}
              </Button>
            </Stack>
          </Box>
        </Modal>
      )}
    </Container>
  );
};
