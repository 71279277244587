import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.black,
  },
}));

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

export interface PixelCropValues {
  width: number;
  height: number;
  x: number;
  y: number;
}

export const getCroppedImg = async (imageSrc: string, crop: PixelCropValues): Promise<string> => {
  const image = (await createImage(imageSrc)) as HTMLImageElement;
  const canvas: any = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  /* setting canvas width & height allows us to 
  resize from the original image resolution */
  canvas.width = 250;
  canvas.height = 250;

  ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, canvas.width, canvas.height);

  return new Promise((resolve) => {
    canvas.toBlob((blob: File) => {
      resolve(URL.createObjectURL(blob));
    }, 'image/jpeg');
  });
};
