export const SliderPrevArrow = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.37155 7.50011H19.2502V10.5001H6.37155L11.3109 15.4395L9.18957 17.5608L0.628906 9.00011L9.18957 0.439453L11.3109 2.56077L6.37155 7.50011Z"
        fill="#8B89A0"
      />
    </svg>
  );
};
