import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';

export const useVerificationStyles = makeStyles<Theme>((theme: Theme) => ({
  Container: {
    maxWidth: '540px',
    width: '100%',
    left: '50%',
    paddingLeft: 0,
    paddingRight: 0,
    background: '#fff',
    height: 'var(--app-height)',
    minHeight: '550px',
  },
  progressBar: {
    marginTop: isMobile ? '26px' : '40px',
    width: '95.5vw',
    maxWidth: '515px',
    height: '16vw',
    maxHeight: '86px',
    margin: '0 auto',
  },
  form: {
    margin: '0 auto',
    width: '81.9vw',
    maxWidth: '442px',
    position: 'absolute',

    left: '50%',
    transform: 'translateX(-50%)',
  },
  AuthSubmitBtn: () => ({
    marginTop: '44px',
    backgroundColor: '#2D11AD',
    color: '#fff',
    width: '81.9vw',
    height: '12.2vw',
    maxWidth: '442px',
    maxHeight: '52px',
    fontSize: 'clamp(16px, 4.4vw, 24px)',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: '#2D11AD',
      boxShadow: 'unset',
    },
  }),

  textField: () => ({
    '& .MuiFormLabel-root': {
      fontSize: '24px !important',
      fontWeight: 700,
      letterSpacing: '0.15px',
      color: '#475467 !important',
    },
    '& input': {
      paddingLeft: '0',
      fontSize: '16px',
      color: '#2D11AD !important',
      '&::placeholder': {
        opacity: '1 !important',
        color: `#2D11AD !important`,
      },
    },
  }),
  nameText: {
    color: '#344054',
    fontWeight: '600',
    width: '81.9vw',
    maxWidth: '442px',
    fontSize: 'clamp(18px, 5vw, 27px)',
    marginBottom: '7px',
  },
  input: {
    width: '81.9vw',
    height: '12.2vw',
    maxWidth: '442px',
    maxHeight: '52px',
    border: '1px solid',
    borderRadius: '8px',
    borderColor: '#D0D5DD',
    marginBottom: '10px',

    paddingLeft: '12px',
    color: '#2D11AD',
    '& .MuiInput-input:-webkit-autofill, .MuiInput-input:-webkit-autofill:focus, .MuiInput-input:-webkit-autofill:hover':
      {
        WebkitTextFillColor: '#2D11AD',
        fontSize: 'clamp(16px, 4.4vw, 24px)',
      },
    '& input': {
      color: '#2D11AD !important',
      fontSize: 'clamp(16px, 4.4vw, 24px)',
      '&::placeholder': {
        color: '#A9B3C8',
      },
    },
    '&.Mui-error': {
      borderColor: '#ED2E38',
    },
  },
  errorText: {
    fontSize: 'clamp(11px, 3.05vw, 16.5px )',
    color: '#ED2E38',
    width: '81.9vw',
    maxWidth: '442px',
    marginTop: '-8px',
    marginBottom: '10px',
    letterSpacing: '0.11px',
  },

  dontCloseText: {
    textAlign: 'center',
    color: theme.palette.common.white,
    marginTop: '42px',
    fontSize: '15px',
    fontWeight: '500',
  },
  messageRateText: {
    fontSize: 'clamp(14px, 3.9vw, 21px)',
    fontWeight: 400,
    textAlign: 'center',
    paddingTop: '10px',
  },
  messageRateLabel: {
    fontSize: 'clamp(12px, 3.15vw, 16px)',
    fontWeight: 400,
    textAlign: 'center',
    paddingTop: '6px',
    color: 'var(--lightBlack)',
  },
}));
