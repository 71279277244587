import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useCreateVideoStyles = makeStyles<Theme, { clientHeight: number; isIos: boolean }>(() => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    objectFit: 'contain',
  },
  noVideoWrapper: {
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '90%',
    maxWidth: '336px',
    maxHeight: '620px',
  },
  glassContainer: {
    zIndex: 1,
    overflow: 'auto',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '50px 0 15px 0',
    position: 'relative',
    top: '40%',
    transform: 'translateY(-50%)',
  },
  glassBackground: {
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.46) 35.94%, rgba(0, 0, 0, 0.46) 100%)',
    backdropFilter: 'blur(1px)',
  },
  venueLogo: {
    position: 'absolute',
    width: 'auto',
    height: 46,
    zIndex: 10000,
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  welcomeVideoWrapper: {
    margin: '0 auto',
    position: 'relative',
    minHeight: '450px',
    maxHeight: '100vh !important',
    height: 'auto',
    aspectRatio: '9/16',
    overflow: 'hidden',
  },
  welcomeVideo: {
    width: '101%',
    cursor: 'pointer',
  },
  playIcon: {
    '&:hover': {
      opacity: '0.9',
    },
    position: 'absolute',
    width: '54px',
    height: '54px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100000,
  },
  muteIcon: {
    '&:hover': {
      opacity: '0.9',
    },
    position: 'absolute',
    width: '54px',
    height: '54px',
    bottom: '30px',
    right: '30px',
    zIndex: 100001,
  },
  controlsWrapper: {
    width: '100%',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'all 0.35s ease-in-out',
    zIndex: 100000,
  },
  logoNoVideoWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  companyName: {
    fontSize: '28px',
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '30px',
    color: '#fff',
  },
  recordVideoText: {
    fontSize: '40px',
    fontWeight: 600,
    lineHeight: '111%',
    letterSpacing: '0.4px',
    textAlign: 'center',
    maxWidth: '90%',
    margin: '0 auto',
  },
  recordVideoLengthText: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '14px',
    textAlign: 'center',
    marginTop: '35px',
  },
  controlsWrapperNoVideo: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  vanueName: {
    position: 'absolute',
    width: '90%',
    top: '109px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '40px 10px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.45) 35.94%, rgba(0, 0, 0, 0.45) 100%)',
    borderRadius: '12px',
    fontSize: '28px',
    fontWieght: 700,
    textAlign: 'center',
  },
}));
