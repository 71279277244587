import React, { FC } from 'react';

interface Props {
  color?: string;
}

export const ReceivedIcon: FC<Props> = ({ color }) => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 2.5C8.6125 2.5 3 8.1125 3 15C3 21.8875 8.6125 27.5 15.5 27.5C22.3875 27.5 28 21.8875 28 15C28 8.1125 22.3875 2.5 15.5 2.5ZM21.475 12.125L14.3875 19.2125C14.2125 19.3875 13.975 19.4875 13.725 19.4875C13.475 19.4875 13.2375 19.3875 13.0625 19.2125L9.525 15.675C9.1625 15.3125 9.1625 14.7125 9.525 14.35C9.8875 13.9875 10.4875 13.9875 10.85 14.35L13.725 17.225L20.15 10.8C20.5125 10.4375 21.1125 10.4375 21.475 10.8C21.8375 11.1625 21.8375 11.75 21.475 12.125Z"
        fill={color ? color : '#058901'}
      />
    </svg>
  );
};
