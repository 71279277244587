import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { loginWithMagicLink } from '../../store/slices/me';
import { useAppDispatch } from '../../store/store';
import { EventNames } from '../../common/constants/constants';
import { getAppLinkSource } from '../../services/utilities';
import { MeApiModel } from '../../api/models/auth';
import { trackEvent } from '../../../utils/analytics/analytics';
import { getActiveCampaign, getUserIncentiveCampaigns, setCurrentCampaign } from '../../store/slices/rewards';
import { CampaignApiModel } from '../../api/models/rewards';
import { Box } from '@mui/system';
import { AppRoutes } from '../../common/constants/routes';

export const LoginWithMagicLink: FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const magicLinkToken = location.pathname.split('/').pop();

  useEffect(() => {
    if (magicLinkToken) {
      dispatch(loginWithMagicLink(magicLinkToken)).then((response) => {
        const user = (response?.payload as MeApiModel)?.user;
        if (user) {
          dispatch(getUserIncentiveCampaigns({ userId: user.id }));
          dispatch(getActiveCampaign(user.id)).then((data) => {
            const campaign = data.payload as CampaignApiModel;
            if (campaign?.id) {
              localStorage.setItem('campaignId', campaign.id);
            } else {
              localStorage.removeItem('campaignId');
            }

            dispatch(setCurrentCampaign(campaign));

            trackEvent({
              action: EventNames.User_Login,
              accountId: user.accountId,
              userId: user.id,
              source: getAppLinkSource({ magicLink: true }),
              campaignId: campaign?.id,
            });
          });
        }
        history.push(AppRoutes.Home);
      });
    }
  }, [magicLinkToken]);

  return <Box></Box>;
};
