import makeStyles from '@mui/styles/makeStyles';
import * as Yup from 'yup';
import omit from 'lodash/omit';
import { SignUpPageStrings } from '../../../common/localization/en';
import { SignUpConfig } from '../../../api/models/venue';
import { Theme } from '@mui/material';
// import { BREAK_HEIGHT } from '../../../common/constants/constants';

export const useStyles = makeStyles<Theme, { clientHeight: number }>((theme) => ({
  BrushContainer: {
    overflow: 'hidden',
    position: 'relative',

    height: '200px',
    '& >div': {
      top: '0px',
    },
    '& p': {
      '&:nth-child(1)': {
        fontSize: '26px',
        fontWeight: 600,
        letterSpacing: '-0.02em',
      },
      '&:nth-child(2)': {
        fontSize: '18px',
        fontWeight: 400,
      },
      '&:nth-child(3)': {
        marginTop: '0px',
        fontWeight: 400,
        fontSize: '12px',
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)',
      },
    },
    [`@media (max-width: 380px)`]: {
      transform: 'scale(0.8)',
    },
  },
  Container: () => ({
    width: '100%',
    marginTop: 0,
    color: '#fff',
    zIndex: 1,
    padding: '12px',
    position: 'relative',
    borderRadius: '12px',
  }),

  GetLinkText: {
    color: theme.palette.common.white,
    fontSize: '14px',
  },
  dontCloseText: {
    margin: '0 auto',
    whiteSpace: 'pre-line',
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '21px',
  },
  verifyText: {
    textAlign: 'center',
    marginTop: '24px',
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '100%',
  },
  codeText: {
    textAlign: 'center',
    marginTop: '14px',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
  },
}));

const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

const iso88591Regex = /^[\x00-\xFF]*$/;

export const emailValidationSchema = Yup.string().email('Please enter a valid email format');

export const getValidationSchema = (
  config: { email?: boolean; firstName?: boolean; lastName?: boolean; phoneNumber?: boolean } = {
    email: true,
    firstName: true,
    lastName: true,
    phoneNumber: true,
  },
) => {
  const baseValidationObject = {
    firstName: Yup.string()
      .trim()
      .max(100, 'max length 100 symbols')
      .matches(iso88591Regex, 'First name contains invalid characters.')
      .required('First name is required'),
    lastName: Yup.string()
      .trim()
      .max(100, 'max length 100 symbols')
      .matches(iso88591Regex, 'Last name contains invalid characters.')
      .required('Last name is required'),
    // email: Yup.string().matches(emailMaskRegex, 'Email is not valid').required('Email is required'),
    email: emailValidationSchema.required('Email is required'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .min(7, 'Phone number is not valid')
      .matches(phoneRegExp, 'Phone number is not valid')
      .nullable(),
  };

  const disabledValues = Object.entries(config)
    .filter(([_, value]) => value === false)
    .map(([key]) => key);

  return Yup.object().shape(omit(baseValidationObject, disabledValues));
};

export const ValidationSchemaPhone = () => {
  return Yup.object().shape({
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .min(7, 'Phone number is not valid')
      .matches(phoneRegExp, 'Phone number is not valid'),
  });
};

export const getFormData = (submitButtonName: string, isSignUp: boolean, signUpConfig: SignUpConfig) => {
  return {
    isNameField: isSignUp,
    isEmailField: false,
    isPhoneField: !isSignUp,
    submitButtonName,
    passwordPlaceholder: SignUpPageStrings.Password,
    formName: SignUpPageStrings.SignUp,
    text: SignUpPageStrings.Login,
    authValidationSchema: isSignUp
      ? getValidationSchema({
          phoneNumber: !!signUpConfig?.phoneRequired,
          firstName: !!signUpConfig?.nameRequired,
          lastName: !!signUpConfig?.nameRequired,
          email: !!signUpConfig?.emailRequired,
        })
      : ValidationSchemaPhone,
    isTermsOfService: true,
    analyticsText: {
      action: SignUpPageStrings.RedirectedText,
      buttonName: SignUpPageStrings.SignUp,
    },
  };
};
