import { Box, Typography } from '@mui/material';
import React, { FC, useEffect, useRef } from 'react';
import { UserIncentiveCampaignApiModel } from '../../../api/models/rewards';
import { useStyles } from './RewardCardPreview.styles';
import { useTypedSelector } from '../../../store/store';
import { getRewardCardFontSize } from './RewardCardPreview.helpers';
import RewardCardPreviewBackground from '../../assets/RewardCardPreviewBackground';
import { HowToRewardCreatorsStrings } from '../../localization/en';
import { format } from 'date-fns';
import { getImageDimensionsToFit, getImageMetadata } from '../../../services/utilities';

interface Props {
  userIncentiveCampaign: UserIncentiveCampaignApiModel;
}

export const RewardCard: FC<Props> = ({ userIncentiveCampaign }) => {
  const styles = useStyles();

  const {
    account: { logo, name },
  } = useTypedSelector((state) => state.account);
  const defaultCardColor = '#D1D1D1';

  const { cardStyle, rewardTitle, rewardHeader, contact } = userIncentiveCampaign.campaign;
  const { firstName } = useTypedSelector((state) => state.me);

  const logoRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const logoElement = logoRef.current;
    if (!logoElement) return;

    getImageMetadata(logo).then((img) => {
      const dimensions = getImageDimensionsToFit(img, 250, 60);

      logoElement.style.width = `${dimensions.width}px`;
      logoElement.style.height = `${dimensions.height}px`;
    });
  }, [logo]);

  return (
    <Box className={styles.container}>
      <RewardCardPreviewBackground fill={cardStyle || defaultCardColor} />
      <Box className={styles.contentWrapper}>
        {logo ? (
          // eslint-disable-next-line @next/next/no-img-element
          <img src={logo} alt="company logo" ref={logoRef} className={styles.companyLogo} />
        ) : (
          <Box sx={{ marginTop: '80px' }} />
        )}
        <Typography className={styles.headerText} sx={{ fontSize: getRewardCardFontSize(rewardHeader) }}>
          {rewardHeader}
        </Typography>
        <Typography className={styles.customerName} sx={{ marginTop: '-8px' }}>
          {firstName + '!'}
        </Typography>
        <Typography className={styles.rewardTitle} sx={{ fontSize: getRewardCardFontSize(rewardTitle) }}>
          {rewardTitle}
        </Typography>
        <Box className={styles.infoWrapper}>
          <Typography className={styles.companyName}>{name}</Typography>
          {contact ? (
            <Box mt="4px" mb="6px">
              <Typography className={styles.infoText}>{`${HowToRewardCreatorsStrings.RedeemText}`}</Typography>
              <Typography className={styles.contact}>{`${contact}`}</Typography>
            </Box>
          ) : (
            <Box sx={{ height: '48px' }} />
          )}
          <Typography className={styles.infoText}>{format(new Date(userIncentiveCampaign.fulfilledAt), 'P')}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
