import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const usePhoneFieldInternationalStyles = makeStyles<
  Theme,
  { error: boolean; disabled: boolean; isSafari: boolean }
>((theme: Theme) => ({
  PhoneInputLabel: {
    fontSize: 'clamp(14px,  3.9vw, 21px)',
    fontWeight: 700,
    letterSpacing: '0.15px',
  },
  PhoneInput: () => ({
    position: 'relative',
    backgroundColor: 'inherit',
    boxSizing: 'content-box',
    color: 'white',
    '& .react-tel-input': {
      borderRadius: '8px',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: (props) => (props.error ? '20px' : '0'),
      width: '100%',
      transform: 'scaleX(1)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1)',
    },
    '& .special-label': {
      display: 'none',
      backgroundColor: 'inherit',
    },
    '& .react-tel-input .form-control': {
      backgroundColor: 'inherit',
      borderRadius: '8px !important',
      marginTop: '8px !important',
      color: 'inherit',
      '&::placeholder': {
        opacity: `1 !important`,
        color: '#A9B3C8',
      },
    },
    '& .react-tel-input .selected-flag': {
      backgroundColor: 'inherit',
      '& .flag': {
        top: '55% !important',
      },
    },
    '& .form-control': {
      width: '100%',
      color: (props) => (props.error ? theme.palette.error.main : '#C7C7D9'),
      fontSize: 'clamp(16px, 4.4vw, 24px)',
      backgroundColor: 'inherit',
      border: 'none',
      cursor: (props) => (props.disabled ? 'text' : ''),
    },
    '& .flag-dropdown ': {
      border: 'none',
    },
    '& .flag-dropdown.open': {
      border: 'none',
      backgroundColor: 'inherit',
      '& :hover': {
        // backgroundColor: 'inherit',
      },
    },
    '& .flag-dropdown.open .selected-flag': {
      backgroundColor: 'inherit',
      '& :hover': {
        backgroundColor: 'inherit',
      },
    },

    '& .open': {
      backgroundColor: 'inherit',
      '& :hover': {
        backgroundColor: 'inherit',
      },
    },
    '& .country-list': {
      marginTop: '0px !important',
      maxHeight: '165px',
      backgroundColor: '#fff !important',
      '& :hover': {
        // backgroundColor: 'var(--primaryBgrColor) !important',
        backgroundColor: '#fff !important',
      },
      '& .country.highlight': {
        backgroundColor: '#fff !important',
      },
      '& .country-name': {
        color: '#475467 !important',
      },
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#475467 !important',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
      },
    },
    '& .flag-dropdown': {
      backgroundColor: 'inherit',
      '& :hover': {
        backgroundColor: 'inherit',
      },
      '& .arrow': {
        left: '28px',
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderTop: '8px solid #fff',
      },
      '& .arrow.up': {
        left: '28px',
        borderBottom: '8px solid #fff',
      },

      '& .country': {
        '&:last-child': {
          marginBottom: (props) => (props.isSafari ? '6px' : ''),
        },
      },
    },
    '& input': {
      '&::placeholder': {
        opacity: '1 !important',
        color: `#e2e6ec !important`,
      },
    },
  }),
}));
