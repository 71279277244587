import { FC } from 'react';

export const WarningIcon: FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3199 10.94L10.5199 2.3C9.87486 1.1375 8.98236 0.5 7.99986 0.5C7.01736 0.5 6.12486 1.1375 5.47986 2.3L0.679861 10.94C0.0723611 12.0425 0.00486115 13.1 0.492361 13.9325C0.979861 14.765 1.93986 15.2225 3.19986 15.2225H12.7999C14.0599 15.2225 15.0199 14.765 15.5074 13.9325C15.9949 13.1 15.9274 12.035 15.3199 10.94ZM7.43736 5.75C7.43736 5.4425 7.69236 5.1875 7.99986 5.1875C8.30736 5.1875 8.56236 5.4425 8.56236 5.75V9.5C8.56236 9.8075 8.30736 10.0625 7.99986 10.0625C7.69236 10.0625 7.43736 9.8075 7.43736 9.5V5.75ZM8.53236 12.2825C8.49486 12.3125 8.45736 12.3425 8.41986 12.3725C8.37486 12.4025 8.32986 12.425 8.28486 12.44C8.23986 12.4625 8.19486 12.4775 8.14236 12.485C8.09736 12.4925 8.04486 12.5 7.99986 12.5C7.95486 12.5 7.90236 12.4925 7.84986 12.485C7.80486 12.4775 7.75986 12.4625 7.71486 12.44C7.66986 12.425 7.62486 12.4025 7.57986 12.3725C7.54236 12.3425 7.50486 12.3125 7.46736 12.2825C7.33236 12.14 7.24986 11.945 7.24986 11.75C7.24986 11.555 7.33236 11.36 7.46736 11.2175C7.50486 11.1875 7.54236 11.1575 7.57986 11.1275C7.62486 11.0975 7.66986 11.075 7.71486 11.06C7.75986 11.0375 7.80486 11.0225 7.84986 11.015C7.94736 10.9925 8.05236 10.9925 8.14236 11.015C8.19486 11.0225 8.23986 11.0375 8.28486 11.06C8.32986 11.075 8.37486 11.0975 8.41986 11.1275C8.45736 11.1575 8.49486 11.1875 8.53236 11.2175C8.66736 11.36 8.74986 11.555 8.74986 11.75C8.74986 11.945 8.66736 12.14 8.53236 12.2825Z"
        fill="#FC4741"
      />
    </svg>
  );
};
