import React, { FC, memo, useEffect, useState } from 'react';
import { ActivityState, ProgressButton } from './VideoRecordButton.style';
import { RECORD_TIME_LIMIT } from '../RecordVideoComponent/RecordVideoComponent';

interface ProgressComponentProps {
  handleStart: () => void;
  handleStop: () => void;
  isRecording: boolean;
}

export const VideoRecordButton: FC<ProgressComponentProps> = memo(({ isRecording, handleStop, handleStart }) => {
  const [isTimeLimitReached, setIsTimeLimitReached] = useState(false);

  useEffect(() => {
    if (isRecording) {
      let count = RECORD_TIME_LIMIT;
      const interval = setInterval(() => {
        if (count === 1) {
          clearInterval(interval);
          setIsTimeLimitReached(true);
          return;
        }

        count--;
      }, 1000);
    }
  }, [isRecording]);

  return (
    <ProgressButton
      onClick={isRecording ? handleStop : handleStart}
      className={isTimeLimitReached ? 'overTimeLimit' : ''}
      style={{
        border: '6px solid',
        borderColor: '#fff',
        background: isRecording ? 'transparent' : '#fff',
      }}
    >
      <ActivityState className={[isRecording ? 'active' : '', isTimeLimitReached ? 'overTimeLimit' : ''].join(' ')} />
    </ProgressButton>
  );
});
VideoRecordButton.displayName = 'Video Record Button';
