import { useEffect } from 'react';

interface UsePreventPageReloadProps {
  title?: string;
}

export const usePreventPageReload = (
  enabled: boolean,
  { title = 'Are you sure you want to leave the page?' }: UsePreventPageReloadProps = {},
) => {
  useEffect(() => {
    const reloadListener = (event: BeforeUnloadEvent) => {
      if (enabled) {
        event.preventDefault();
        event.returnValue = title;
        return event;
      }
    };

    window.addEventListener('beforeunload', reloadListener, { capture: true });

    return () => {
      window.removeEventListener('beforeunload', reloadListener, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
};
