import { Box, IconButton } from '@mui/material';
import React, { FC } from 'react';
import { FacebookBlueIcon } from '../../../../common/assets/shareIcons/FacebookBlueIcon';
import { LinkedInBlueIcon } from '../../../../common/assets/shareIcons/LinkedInBlueIcon';
import { XBlueIcon } from '../../../../common/assets/shareIcons/XBlueIcon';
import { SmsBlueIcon } from '../../../../common/assets/shareIcons/SmsBlueIcon';
import { facebookShareLink, linkedinShareLink, twitterShareLink } from '../../../../common/constants/constants';
import { useTypedSelector } from '../../../../store/store';
import { SharePlatformType, useShareStory } from '../../hooks/useShareStory';

type ShareTypes = 'Facebook' | 'LinkedIn' | 'X' | 'SMS';
interface ShareOption {
  link: string;
  icon: React.ReactNode;
}

const socials: Record<ShareTypes, ShareOption> = {
  SMS: {
    link: 'sms:?&body=',
    icon: <SmsBlueIcon />,
  },
  Facebook: {
    link: facebookShareLink,
    icon: <FacebookBlueIcon />,
  },
  LinkedIn: {
    link: linkedinShareLink,
    icon: <LinkedInBlueIcon />,
  },
  X: {
    link: twitterShareLink,
    icon: <XBlueIcon />,
  },
};

interface Props {
  shareLink: string | undefined;
  storyId: string;
}

const ShareLinks: FC<Props> = ({ shareLink, storyId }) => {
  const { accountAttributes } = useTypedSelector((state) => state.account);
  const brandColor = accountAttributes?.properties?.['webapp.config']?.['primary-color'] || '#CFB946';

  const { trackShare } = useShareStory({ storyId });

  const sharePlatformStrings: Record<string, SharePlatformType> = {
    SMS: 'SMS',
    Facebook: 'FACEBOOK',
    LinkedIn: 'LINKEDIN',
    X: 'TWITTER',
  };

  const handleTrackShare = (platform: SharePlatformType) => {
    trackShare(platform);
  };

  return (
    <Box display="flex" flexWrap="wrap" maxWidth="180px" justifyContent="center" margin="14px 0" gap="16px">
      {Object.entries(socials).map(([social, data], idx) => {
        const [left, right] = shareLink ? shareLink.split('?') : ['', ''];
        const query = encodeURIComponent(`${right}&sms=true`);
        const SMSSharelink = `${left}?${query}`;
        return (
          <IconButton
            key={`${social}_${idx}`}
            href={`${data.link}${social === 'SMS' ? SMSSharelink : shareLink}`}
            target="_blank"
            onClick={() => {
              handleTrackShare(sharePlatformStrings[social]);
            }}
            sx={{
              padding: '2px',
              height: 'fit-content',
              transition: '0.11s',
              '&:hover': { boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', backgroundColor: '#fff' },
              color: brandColor,
            }}
          >
            {data.icon}
          </IconButton>
        );
      })}
    </Box>
  );
};

export default ShareLinks;
