import * as Yup from 'yup';
import { SignInPageStrings } from '../../common/localization/en';

import { emailMaskRegex } from '../../services/utilities';

export const authSignInValidationSchema = () => {
  return Yup.object().shape({
    email: Yup.string().matches(emailMaskRegex, 'Email is not valid').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters long').required('Password is required'),
  });
};

export const formData = {
  useNameLabel: SignInPageStrings.UseNameLabel,
  emailLabel: SignInPageStrings.EmailLabel,
  submitButtonName: SignInPageStrings.Login,
  passwordPlaceholder: SignInPageStrings.Password,
  formName: SignInPageStrings.Login,
  text: SignInPageStrings.SignUp,
  authValidationSchema: authSignInValidationSchema,
  isTermsOfService: false,
  analyticsText: {
    action: SignInPageStrings.RedirectedText,
    buttonName: SignInPageStrings.SignUp,
  },
};
