import { Button, Box } from '@mui/material';
import { FC } from 'react';
import { CloseIcon } from '../../assets/CloseIcon';
import { useStyles } from './ErrorDialog.helper';

interface ErrorDialogProps {
  errorMessage?: string;
  isOpen: boolean;
  onClose: () => void;
}
export const ErrorDialog: FC<ErrorDialogProps> = ({ onClose, isOpen, errorMessage }) => {
  const s = useStyles();
  return (
    <div className={s.container}>
      <div className={[s.modal, isOpen ? s.isOpen : ''].join(' ')}>
        <div className={s.close} onClick={onClose}>
          <CloseIcon />
        </div>
        <Box className={s.errorText} mb="40px">
          {errorMessage}
        </Box>
        <Button color="primary" fullWidth variant="contained" onClick={onClose}>
          Ok
        </Button>
      </div>
    </div>
  );
};
