import React, { useState } from 'react';
import Image from 'next/image';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { TabNames, useUserProfileStyles } from './UserProfile.helper';
import { ProfileForm } from './ProfileForm/ProfileForm';
import { DefaultVideoTextForm } from './DefaultVideoTextForm/DefaultVideoTextForm';
import { useTypedSelector } from '../../../store/store';
import { getBestImage } from '../../../services/utilities';
import { Title } from '../../../common/components/Title/Title';
import { useThemeLayout } from '../../../common/hooks/useThemeLayout';
import { ProfilePageStrings } from '../../../common/localization/en';

export const UserProfile = () => {
  const classes = useUserProfileStyles();
  const { accountAttributes } = useTypedSelector((state) => state.account);
  const { isThemeLayoutEnabled, theme: layoutTheme } = useThemeLayout();
  const theme = useTheme();
  const matches = {
    tablet: useMediaQuery(theme.breakpoints.between('sm', 'lg')),
    mobile: useMediaQuery(theme.breakpoints.down('sm')),
    desktop: useMediaQuery(theme.breakpoints.up('lg')),
  };
  const backgroundImage = accountAttributes.properties?.['webapp.cards-welcome']
    ? getBestImage(accountAttributes.properties?.['webapp.cards-welcome'], matches)
    : null;

  const [activeTab] = useState<TabNames>(TabNames.ProfileForm);

  // const getTabStyles = (tabName: TabNames) => ({
  //   width: 160,
  //   height: 32,
  //   backgroundColor: activeTab === tabName ? theme.palette.secondary.dark : 'transparent',
  //   color: theme.palette.common.white,
  // });

  // const setActiveTabHandler = (tabName: TabNames) => () => {
  //   setActiveTab(tabName);
  // };

  return (
    <Box sx={{ padding: '10px' }}>
      {backgroundImage && !isThemeLayoutEnabled ? (
        <Image src={backgroundImage || ''} layout="fill" alt="" className={classes.imageBackground} />
      ) : (
        <Box className={classes.themeBackground} />
      )}
      <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: '360px' }}>
        {!isThemeLayoutEnabled && <Title secondaryText={ProfilePageStrings.TitleFirstText} noPadding />}
        {activeTab === TabNames.ProfileForm && (
          <ProfileForm isThemeLayoutEnabled={isThemeLayoutEnabled} layoutTheme={layoutTheme} />
        )}
        {activeTab === TabNames.VideoDefaultText && <DefaultVideoTextForm />}
      </Box>
    </Box>
  );
};
