import { FC, useState, useEffect } from 'react';
import { FormikProps, useFormik } from 'formik';
import { Grid, Typography, useTheme } from '@mui/material';

import { useTypedSelector, useAppDispatch } from '../../../../store/store';
import { AuthInitialFormValues } from '../../../../common/commonTypes';
import { updateUserRequest } from '../../../../store/slices/me';
import { getProfileValidationSchema } from '../../ProfilePage.helper';
import { authFormInitialValues } from '../../../../common/constants/constants';
import { AuthForm } from '../../../../common/components/AuthForm/AuthForm';
import { ProfilePicture } from '../../../../common/components/AuthForm/ProfilePicture/ProfilePicture';
import { ModalImageCrop } from '../../../../common/components/AuthForm/ProfilePicture/ModalImageCrop/ModalImageCrop';
import { AppRoutes } from '../../../../common/constants/routes';
import { uploadFileHandler } from './ProfileForm.helper';
import { Container } from './ProfileForm.style';
import { trackPageView } from '../../../../../utils/analytics/analytics';
import { useSignUpFieldsConfig } from '../../../../services/hooks/useSignUpFieldsConfig';
import { ProfilePageStrings } from '../../../../common/localization/en';
import { VideoAppTheme } from '../../../../api/models/venue';

interface Props {
  isThemeLayoutEnabled?: boolean;
  layoutTheme?: VideoAppTheme;
}

export const ProfileForm: FC<Props> = ({ isThemeLayoutEnabled }) => {
  const { account, accountAttributes } = useTypedSelector((state) => state.account);
  const { activeCampaign } = useTypedSelector((state) => state.rewards);
  const { email, firstName, lastName, phoneNumber, id, photoUrl: userPhotoUrl } = useTypedSelector((state) => state.me);
  const brandColor = accountAttributes?.properties?.['webapp.config']?.['primary-color'];
  const [isEnterpriseCaptcha, setIsEnterpriseCaptcha] = useState(true);
  const dispatch = useAppDispatch();
  const emailRequired = !!activeCampaign?.emailRequired;

  const { signUpConfig, requiredFormFields } = useSignUpFieldsConfig();

  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  const initialFormikValues = {
    ...authFormInitialValues,
    email: email || '',
    firstName: firstName || undefined,
    lastName: lastName || undefined,
    phoneNumber,
    photoUrl: userPhotoUrl,
  };

  useEffect(() => {
    trackPageView({
      url: AppRoutes.ProfileAccount,
      accountId: account.id,
      userId: id,
      email,
    });
  }, [email, id, firstName, lastName, account.id]);

  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
  const [userAvatar, setUserAvatar] = useState<string>('');
  const [croppedUserAvatar, setCroppedUserAvatar] = useState<string>('');
  const [isModal, setIsModal] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);

  useEffect(() => {
    setUserAvatar(userPhotoUrl || '');
  }, [userPhotoUrl]);

  const validationSchema = getProfileValidationSchema({
    emailRequired: emailRequired,
    firstName: signUpConfig.nameRequired,
    lastName: signUpConfig.nameRequired,
  });

  const formik = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmitHandler(values);
    },
  });
  const onSubmitHandler = async (values: AuthInitialFormValues) => {
    const { email, password, firstName, lastName, photoUrl } = values;
    let newPhotoUrl = photoUrl;
    if (userAvatar !== userPhotoUrl) {
      const uploadedPhotoUrl = await uploadFileHandler(photoUrl, account.id);
      if (uploadedPhotoUrl) {
        newPhotoUrl = uploadedPhotoUrl;
      }
    }

    setDisabledSubmitButton(true);
    dispatch(
      updateUserRequest({
        accountId: account.id,
        email,
        password,
        firstName,
        lastName,
        userId: id,
        photoUrl: newPhotoUrl,
      }),
    )
      .then(() => {
        setDisabledSubmitButton(false);
      })
      .catch(() => {
        setDisabledSubmitButton(false);
      });
  };

  const onSaveCroppedAvatarHandler = (croppedImg: string) => {
    setCroppedUserAvatar(croppedImg);
    formik.setFieldValue('photoUrl', croppedImg);
    toggleModalHandler();
  };

  const toggleModalHandler = () => {
    setIsModal(!isModal);
  };

  const setUserAvatarHandler = (base64Img: string) => {
    setUserAvatar(base64Img);
    toggleModalHandler();
  };

  const formData = {
    submitButtonName: ProfilePageStrings.SubmitButton,
    passwordPlaceholder: ProfilePageStrings.Password,
    authValidationSchema: validationSchema,
    isTermsOfService: false,
    isNameField: true,
    isEmailField: true,
    isPhoneField: true,
    isResetPassword: false,
  };

  return (
    <Container $isLayoutThemeEnabled={isThemeLayoutEnabled}>
      <ModalImageCrop
        onSave={onSaveCroppedAvatarHandler}
        onClose={toggleModalHandler}
        isModal={isModal}
        image={userAvatar}
      />
      <Grid container alignItems={'center'} justifyContent="center">
        {isThemeLayoutEnabled && (
          <Typography style={{ color: brandColor, fontSize: '16px', fontWeight: '500' }}>Profile</Typography>
        )}
        <ProfilePicture
          userAvatar={croppedUserAvatar || userAvatar}
          onChange={setUserAvatarHandler}
          primaryColor={primaryColor}
          userName={firstName ? `${firstName} ${lastName}` : ''}
        />
      </Grid>

      <AuthForm
        formik={formik as FormikProps<AuthInitialFormValues>}
        formFieldsData={formData}
        disabledSubmitButton={disabledSubmitButton}
        disabledFieldNames={['phoneNumber']}
        disablePasswordReset
        requiredFields={requiredFormFields}
        setCaptcha={setCaptchaResponse}
        isPhonePrepopulated={true}
        captchaResponse={captchaResponse}
        isEnterpriseCaptcha={isEnterpriseCaptcha}
        setIsEnterpriseCaptcha={setIsEnterpriseCaptcha}
      />
    </Container>
  );
};
