import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    zIndex: 1000000,
  },
  contentWrapper: {
    width: '100%',
    maxWidth: '380px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '0 auto',
  },

  button: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.black,
    textTransform: 'inherit',
  },

  subtitle: {
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'center',
    lineHeight: '24px',
    marginTop: 8,
    alignSelf: 'center',
  },
  btnClose: {
    wordBreak: 'break-word',
    fontWeight: 600,
    margin: '10px auto 0',
    fontSize: 16,
    borderRadius: '6px',
    width: 260,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    padding: 0,
    transition: 'all 0.15s ease-out',
    marginTop: '20px',

    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    '&:active': {
      transition: '0.3s',
      transform: 'scale(0.99)',
    },
  },
}));
