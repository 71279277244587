import { trackEvent as globalTrackEvent } from '../../../utils/analytics/analytics';
import { useTypedSelector } from '../../store/store';

export type IEventTracker = (event: string, additionalProperties?: Record<string, unknown>) => void;

export const useTrackEvent = () => {
  const { id, name, email, accountId } = useTypedSelector((state) => state.me);

  const trackEvent: IEventTracker = (event, additionalProperties) => {
    globalTrackEvent({
      action: event,
      location: window.location.href,
      accountId,
      email,
      name,
      userId: id,
      ...additionalProperties,
    });
  };

  return { trackEvent };
};
