import styled from 'styled-components';

export const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  &::before {
    content: '';
    display: block;
    height: 0;

    @supports (aspect-ratio: 1) {
      content: none;
    }
  }
`;

export const MockVideoWrapper = styled.video`
  display: block;
  width: 100%;
  background-color: #000;
  aspect-ratio: 9/16;
  z-index: 5;
`;

export const MockImgWrapper = styled.img`
  display: block;
  width: 100%;
  aspect-ratio: 9/16;
  z-index: 5;
`;
export const Video = styled.video`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;

  @supports (aspect-ratio: 1) {
    position: static;
    top: auto;
    left: auto;
    aspect-ratio: 9 / 16;
  }
`;

export const PreviewImage = styled.img`
  visibility: inherit;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: fill;
`;

export const PreviewImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
