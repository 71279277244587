export function useIsLoggedWithMagicLinkValue() {
  const loginWithMagicLink = localStorage.getItem('loginWithMagicLink');
  const isLoggedWithMagicLinkValue = loginWithMagicLink && JSON.parse(loginWithMagicLink);

  const updateLoggedWithMagicLinkValue = () => {
    localStorage.setItem('loginWithMagicLink', 'false');
  };

  return [isLoggedWithMagicLinkValue, updateLoggedWithMagicLinkValue];
}
