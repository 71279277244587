import { useTheme } from '@mui/styles';
import React from 'react';

export const FacebookBlueIcon = () => {
  const theme = useTheme();

  return (
    <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="55" height="55" rx="27.5" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6667 15.593C29.4067 15.593 26.7639 18.2481 26.7639 21.5233V25.7646C26.7639 26.3425 26.2975 26.8111 25.7222 26.8111H21.2083V30.2442H25.2391C25.8144 30.2442 26.2808 30.7127 26.2808 31.2907V41.407H29.9946V31.2907C29.9946 30.7127 30.4609 30.2442 31.0362 30.2442H34.5792L35.4851 26.7558H31.2778C30.7025 26.7558 30.2361 26.2873 30.2361 25.7093V22.9186C30.2361 20.7994 31.9461 19.0814 34.0555 19.0814H35.7917V15.593H32.6667ZM24.6806 21.5233C24.6806 17.0921 28.2561 13.5 32.6667 13.5H36.8333C37.4086 13.5 37.875 13.9685 37.875 14.5465V20.1279C37.875 20.7059 37.4086 21.1744 36.8333 21.1744H34.0555C33.0967 21.1744 32.3194 21.9553 32.3194 22.9186V24.6628H36.8333C37.156 24.6628 37.4604 24.813 37.6576 25.0695C37.8549 25.326 37.9227 25.6598 37.8413 25.9735L36.392 31.5549C36.2723 32.0157 35.858 32.3372 35.384 32.3372H32.0779V42.4535C32.0779 43.0315 31.6115 43.5 31.0362 43.5H25.2391C24.6638 43.5 24.1975 43.0315 24.1975 42.4535V32.3372H20.1667C19.5914 32.3372 19.125 31.8687 19.125 31.2907V25.7646C19.125 25.1866 19.5914 24.718 20.1667 24.718H24.6806V21.5233Z"
        fill={theme.palette.primary.contrastText}
      />
      <rect x="1" y="1" width="55" height="55" rx="27.5" stroke="currentColor" />
    </svg>
  );
};
