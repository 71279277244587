import { FC } from 'react';
import { SVGProps } from 'react';

export const CloseIcon: FC<SVGProps<SVGSVGElement>> = ({ color = '#8B89A0', ...props }) => (
  <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.40039 12.5984L12.6004 1.39844"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6006 12.5984L1.40058 1.39844"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
