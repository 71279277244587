export const getError = (name: string, touched: any, errors: any) => {
  const isTouched = Boolean(touched[name]);

  if (isTouched) {
    return errors[name] as string;
  }

  return null;
};

export const TERMS_OF_SERVICE_LINK_TRUBLU = './TRUBLU_SOCIAL_SMILES_TERMS_OF_SERVICE.pdf';
export const PRIVACY_POLICY_LINK_TRUBLU = './TRUBLU_SOCIAL_SMILES_PRIVACY_POLICY.pdf';
export const SV_USER_TERMS = 'https://www.socialvenu.com/users-terms';
export const PRIVACY_POLICY_LINK_SV_HEALTH = './SocialVenu_Video_Creator_Terms_of_Use_&_Privacy_Policy_01_01_2023.pdf';
