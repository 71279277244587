import React, { useEffect, useState } from 'react';
import { PageContainer } from '../../common/components/PageContainer/PageContainer';
import { GlassContainer } from '../../common/components/GlassContainer/GlassContainer';
import { Box, CircularProgress, Typography } from '@mui/material';
import { UploadProgressBar } from '../../common/components/UploadProgressBar/UploadProgressBar';
import { useStyles } from './VideoUploadingPage.styles';
import { UploadIcon } from '../../common/assets/UploadIcon';
import { UploadProgressStrings } from '../../common/localization/en';
import { useTypedSelector } from '../../store/store';
import { UploadErrorNotification } from '../../common/components/UploadErrorNotification/UploadErrorNotification';
import { useThemeLayout } from '../../common/hooks/useThemeLayout';

const AVERAGE_UPLOAD_SPEED = 0.6; // Mb per second;

interface Props {
  onRestartClick: () => void;
}

export const VideoUploadingPage: React.FC<Props> = ({ onRestartClick }) => {
  const classes = useStyles();
  const { isDarkTheme } = useThemeLayout();

  const { uploadingIterations, isUploadError } = useTypedSelector((state) => state.upload);

  const [isEstimationTimeCalculated, setIsEstimationTimeCalculated] = useState(false);
  const [estimationTime, setEstimationTime] = useState(0);

  useEffect(() => {
    if (uploadingIterations.length > 0 && !isEstimationTimeCalculated) {
      const totalSize = uploadingIterations[0].total / 1024 / 1024;

      setEstimationTime(totalSize / AVERAGE_UPLOAD_SPEED);
      setIsEstimationTimeCalculated(true);
    }
  }, [isEstimationTimeCalculated, uploadingIterations]);

  const minutes = Math.floor(estimationTime / 60);
  const seconds = Math.ceil(estimationTime % 60);

  return (
    <PageContainer withOffsetTop={false}>
      <GlassContainer my="-30px" hideGlass={true}>
        <Box className={classes.contentWrapper}>
          <UploadProgressBar />
          {isUploadError ? (
            <Box className={classes.uploadNotificationWrapper}>
              <UploadErrorNotification onRestartClick={onRestartClick} />
            </Box>
          ) : (
            <>
              <Box
                className={classes.estimationTimeWrapper}
                sx={{
                  border: `1px solid #DCDCDC`,
                }}
              >
                <UploadIcon cloudColor={'#E4E4E4'} circleColor={'#2D11AD'} />
                <Box
                  className={classes.timeSectionWrapper}
                  sx={{
                    background: isDarkTheme ? 'rgba(248, 248, 249, 0.20)' : '#fff',
                  }}
                >
                  <Typography
                    className={classes.estimatedText}
                    sx={{
                      color: isDarkTheme ? '#A1A1A1' : '#8B8B8B',
                    }}
                  >
                    {UploadProgressStrings.EstimatedTime}
                  </Typography>
                  <Typography
                    className={classes.timeText}
                    sx={{
                      color: '#1A1A1A',
                    }}
                  >
                    {isEstimationTimeCalculated ? (
                      `${minutes ? minutes + ' min ' : ''}${seconds} sec`
                    ) : (
                      <CircularProgress
                        size={16}
                        sx={{
                          marginTop: '5px',
                          color: '#1A1A1A',
                        }}
                      />
                    )}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </GlassContainer>
    </PageContainer>
  );
};
