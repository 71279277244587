import React, { FC, useEffect } from 'react';
import { useStyles } from './PointsTab.styles';
import { Box } from '@mui/system';
import { useTypedSelector } from '../../../store/store';
import { Typography } from '@mui/material';
import { PointsStrings } from '../../../common/localization/en';
import { orderCampaigns } from './PointsTab.helper';
import { CampaignsSwiper } from '../../../common/components/CampaignsSwiper/CampaignsSwiper';
import { useDispatch } from 'react-redux';
import { httpClient } from '../../../services/httpClient/httpClient';
import { getUserIncentiveCampaigns } from '../../../store/slices/rewards';
import { useThemeLayout } from '../../../common/hooks/useThemeLayout';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../../common/constants/routes';

export const PointsTab: FC = () => {
  const { isThemeLayoutEnabled, theme } = useThemeLayout();
  const classes = useStyles({ isThemeLayoutEnabled, layoutTheme: theme });
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const userId = httpClient.getUserId();
    if (userId) {
      dispatch(getUserIncentiveCampaigns({ userId }));
    }
  }, [dispatch]);
  const { userIncentiveCampaigns } = useTypedSelector((state) => state.rewards);

  const { fulfilledCampaigns, notFulfilledCampaigns } = orderCampaigns(userIncentiveCampaigns);

  const hasCampaignsToDisplay =
    userIncentiveCampaigns?.filter((campaign) => {
      // fullfilled campaigns with null reward are not displayed
      return !(campaign.fulfilled && campaign.campaign?.isNullReward);
    }).length > 0;

  useEffect(() => {
    if (!hasCampaignsToDisplay) {
      history.replace(AppRoutes.YourVideos);
    }
  }, [hasCampaignsToDisplay, history]);

  return (
    <Box className={classes.container}>
      {notFulfilledCampaigns.length > 0 && (
        <Box className={classes.blockWrapper}>
          <Typography className={classes.progressText}>{PointsStrings.MyProgress}</Typography>
          <CampaignsSwiper
            userIncentiveCampaigns={notFulfilledCampaigns}
            isThemeLayoutEnabled={isThemeLayoutEnabled}
            layoutTheme={theme}
          />
        </Box>
      )}
      {fulfilledCampaigns.length > 0 && (
        <Box className={classes.blockWrapper}>
          <Typography className={classes.progressText}>{PointsStrings.MyRewards}</Typography>
          <CampaignsSwiper
            userIncentiveCampaigns={fulfilledCampaigns}
            isThemeLayoutEnabled={isThemeLayoutEnabled}
            layoutTheme={theme}
          />
        </Box>
      )}
    </Box>
  );
};
