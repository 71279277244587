import React, { FC, memo, useState } from 'react';
import { useTheme } from '@mui/material';

import { PasswordField } from '../../FormComponents/PasswordField/PasswordField';
import { ResetText } from './ResetPassword.style';

import { AuthFormStrings } from '../../../localization/en';

interface ResetPasswordProps {
  label: string;
  newPassword: string;
  confirmPassword?: string;
  newPasswordError?: string | null | undefined;
  confirmPasswordError?: string | null | undefined;
  onChange: (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  setFieldTouched: (name: string, isTouched: boolean) => void;
  isFieldsOpened?: boolean;
}

export const ResetPassword: FC<ResetPasswordProps> = memo((props) => {
  const theme = useTheme();
  const {
    newPassword,
    newPasswordError,
    confirmPassword,
    confirmPasswordError,
    setFieldTouched,
    onChange,
    onBlur,
    isFieldsOpened = false,
  } = props;

  const [isFields, setIsFields] = useState<boolean>(isFieldsOpened);

  const onClickResetTextHandler = () => {
    setIsFields(!isFields);
  };

  return (
    <>
      {isFields && (
        <>
          <PasswordField
            onChange={onChange}
            onBlur={onBlur}
            setFieldTouched={setFieldTouched}
            value={newPassword}
            label={AuthFormStrings.NewPassword}
            name="password"
            errorText={newPasswordError}
          />
          <PasswordField
            onChange={onChange}
            onBlur={onBlur}
            setFieldTouched={setFieldTouched}
            value={confirmPassword}
            label={AuthFormStrings.ConfirmPassword}
            name="confirmPassword"
            errorText={confirmPasswordError}
          />
        </>
      )}

      {!isFields && (
        <ResetText theme={theme} onClick={onClickResetTextHandler}>
          {AuthFormStrings.ResetPassword}
        </ResetText>
      )}
    </>
  );
});
