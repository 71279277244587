import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import PhoneInput from 'react-phone-input-2';
import { trackEvent } from '../../../../utils/analytics/analytics';
import { EventNames } from '../../constants/constants';
import { Modal } from '../Modal/Modal';
import { useStyles } from './ExistingUserModal.helper';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  phoneNumber: string;
  goToSignup: () => void;
  trackProperties?: {
    accountId: string;
    phoneNumber?: string;
    name?: string;
    email?: string;
  };
}

export const ExistingUserModal: FC<Props> = ({ phoneNumber, isOpen, onClose, goToSignup, trackProperties }) => {
  const styles = useStyles();

  const trackClick = (action: string) => {
    trackEvent({
      action,
      location: window.location.href,
      accountId: trackProperties?.accountId || '',
      phoneNumber: phoneNumber,
      name: trackProperties?.name,
      email: trackProperties?.email,
    });
  };

  const onLoginWithThisNumber = () => {
    trackClick(EventNames.Existing_User_Modal_Proceed);

    onClose();
  };

  const onUseDifferentNumber = () => {
    trackClick(EventNames.Existing_User_Modal_Return_To_Signup);

    goToSignup();
    onClose();
  };

  const onOutsideClick = () => {
    trackClick(EventNames.Existing_User_Modal_Click_Outside);

    onClose();
  };

  return (
    <Container>
      {isOpen && (
        <Modal onClose={onOutsideClick} isCloseButton={false} semiTransparentBgr>
          <Box className={styles.container}>
            <Stack className={styles.container}>
              <Typography className={styles.title}>{'Existing User'}</Typography>
              <div className={styles.phoneWrapper} style={{ flexDirection: 'column' }}>
                <Typography component={'span'} className={styles.phoneNumber}>
                  <PhoneInput value={phoneNumber} disabled />
                </Typography>
                <Typography className={styles.subtitle}>
                  {'The number you provided is connected with an existing account.'}
                </Typography>
              </div>

              <Button size="small" variant="contained" onClick={onLoginWithThisNumber} className={styles.btnVerify}>
                {'Login With This Number'}
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  onUseDifferentNumber();
                }}
                className={styles.outlinedbtnVerify}
              >
                {'Use a different number'}
              </Button>
            </Stack>
          </Box>
        </Modal>
      )}
    </Container>
  );
};
