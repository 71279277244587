import styled from 'styled-components';
import { Theme } from '@mui/material';
import { theme } from '../../../theme';

import { defaultPageSizing } from '../../constants/constants';

interface TitleTextProps {
  addPadding?: boolean;
  doubleLined?: boolean;
}
export const TitleText = styled.h1<TitleTextProps>`
  font-size: 18px;
  font-weight: 800;
  z-index: 2;
  ${(props) => props.addPadding && 'padding: var(--titlePadding);'}
  ${(props) => props.doubleLined && 'display: flex; flex-direction: column;'}
  margin-bottom: 10px;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    font-size: 24px;
  }

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    //  width: ${defaultPageSizing.desktopPageWidth};
    //  margin: 0 auto 32px;
  }
`;

export const FirstPart = styled.span`
  color: var(--white);
`;

export const SecondPart = styled.span<{ theme: Theme }>`
  color: ${(props) => props.theme?.palette?.primary?.main};
`;
