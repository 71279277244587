import { FormHelperText } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { isSafari, isFirefox, isIOS } from 'react-device-detect';

import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';

import { TwilioSupportedCountries } from '../../../constants/constants';
import { usePhoneFieldInternationalStyles } from './PhoneFieldInternational.style';
import { useThemeLayout } from '../../../hooks/useThemeLayout';
import { Box } from '@mui/system';
import { useTypedSelector } from '../../../../store/store';
import { getAlteredTheme } from '../../../../services/hooks/themeHook';

interface Props {
  name: string;
  value: string;
  label?: string;
  className?: string;
  onChange: (val: string) => void;
  setTouched: () => void;
  placeholder: string;
  error?: string | null;
  disabled?: boolean;
  showErrorOnBlur?: boolean;
  isPrepopulated?: boolean;
  trackCountryCodeChange?: () => void;
  isOutlinedForLightTheme?: boolean;
  isProfilePage?: boolean;
  inputFontSize?: string;
}

enum PhoneState {
  Filled,
  NotFilled,
}

export const PhoneFieldInternational: FC<Props> = ({
  value,
  onChange,
  label,
  placeholder,
  error,
  disabled,
  showErrorOnBlur,
  setTouched,
  isPrepopulated,
  trackCountryCodeChange,
  isOutlinedForLightTheme,
  isProfilePage,
  inputFontSize,
}) => {
  const [isBlur, setIsBlur] = useState(false);
  const { isThemeLayoutEnabled, themeTextColor, themeBackgroundColor, isLightTheme } = useThemeLayout();

  const showError = useMemo(() => {
    return !!error && (showErrorOnBlur ? isBlur : true);
  }, [error, isBlur, showErrorOnBlur]);

  const classes = usePhoneFieldInternationalStyles({
    error: showError,
    disabled: disabled || false,
    isSafari: isSafari || isFirefox,
  });
  const [phoneValue, setPhoneValue] = useState(value);
  const [countryCode, setCountryCode] = useState('us');
  const [phoneState, setPhoneState] = useState(isPrepopulated ? PhoneState.Filled : PhoneState.NotFilled);
  const isFilled = phoneState === PhoneState.Filled;

  const { accountAttributes } = useTypedSelector((state) => state.account);

  const theme = getAlteredTheme(accountAttributes);

  useEffect(() => {
    if (value.length === 11 && !isFilled) {
      const resultVal = `1${phoneValue}`;
      setPhoneValue(resultVal);
      onChange(resultVal);
      setPhoneState(PhoneState.Filled);
    }
  }, [isFilled, phoneValue, value]);

  const selectedCountryColorLight = '#dde8ffbf';

  return (
    <Box
      className={classes.PhoneInput}
      sx={{
        marginTop: isLightTheme && isOutlinedForLightTheme ? '3px !important' : '',
        '& .flag': {
          width: '24px',
        },
        '& .flag-dropdown .arrow': {
          borderTop: `8px solid #475467 !important`,
        },
        '& .flag-dropdown .arrow.up': {
          display: 'none',
          borderTop: `8ps solid #475467 !important`,
          borderBottom: `8px solid ${isThemeLayoutEnabled ? themeTextColor : '#fff'} ${
            isThemeLayoutEnabled ? '!important' : ''
          }`,
        },
        '& .country-list': {
          backgroundColor: `${isThemeLayoutEnabled ? themeBackgroundColor : 'var(--primaryBgrColor)'} !important`,
          '&::-webkit-scrollbar-track': {
            background: `${isLightTheme ? selectedCountryColorLight : 'var(--darkGreyButtonColor)'} !important`,
          },
        },
        '& .country-name': {
          color: `${isThemeLayoutEnabled ? themeTextColor : '#fff'} !important`,
        },
        '& .country.highlight': {
          backgroundColor: `${isLightTheme ? selectedCountryColorLight : 'var(--darkGreyButtonColor)'} !important`,
          '& :hover': {
            backgroundColor: `${isLightTheme ? selectedCountryColorLight : 'var(--darkGreyButtonColor)'} !important`,
          },
        },
        '& .country': {
          '&:hover': {
            backgroundColor: `${isLightTheme ? selectedCountryColorLight : 'var(--darkGreyButtonColor)'} !important`,
          },
        },

        '& input': {
          border: showError
            ? `1px solid #ED2E38 !important`
            : isLightTheme && isOutlinedForLightTheme
            ? `1px solid  ${showError ? theme.palette.error.main : '#D0D5DD'} !important`
            : 'unset',
          borderRadius: isLightTheme && isOutlinedForLightTheme ? '8px' : 'unset !important',
          height: '12.2vw',
          maxHeight: '52px',
        },

        '& input:-webkit-autofill, input:-webkit-autofill:focus, input:-webkit-autofill:hover': {
          WebkitTextFillColor: '#2D11AD',
        },

        '& input:focus': {
          border: showError
            ? `1px solid #ED2E38 !important`
            : isLightTheme && isOutlinedForLightTheme
            ? `2px solid  ${showError ? theme.palette.error.main : '#D0D5DD'} !important`
            : 'unset',
          paddingTop: '2px',
        },
      }}
    >
      <label
        className={classes.PhoneInputLabel}
        style={{
          color: showError ? '#ED2E38' : '#475467',
          marginBottom: '8px !important',
        }}
        // color: (props) => (props.error ? theme.palette.error.main : theme.palette.common.white || '#fff'),
      >
        {label}
      </label>
      <PhoneInput
        country={'us'}
        autoFormat={true}
        placeholder={placeholder}
        value={phoneValue}
        onChange={(val, countryData) => {
          if (trackCountryCodeChange && countryCode !== (countryData as CountryData).countryCode) {
            setCountryCode((countryData as CountryData).countryCode);
            trackCountryCodeChange();
          }
          onChange(val);
          setPhoneValue(val);
        }}
        disableCountryGuess={true}
        disabled={disabled}
        countryCodeEditable={!isFilled}
        disableCountryCode={!isFilled}
        onlyCountries={TwilioSupportedCountries}
        buttonStyle={{
          display: isFilled && !isProfilePage ? 'block' : 'none',
        }}
        inputStyle={{
          paddingLeft: isFilled && !isProfilePage ? '60px' : '12px',
          color: '#2D11AD',
          fontSize: inputFontSize || '16px',
          border: '1px solid #D0D5DD',
          borderRadius: '8px !important',
          height: '12.2vw',
          maxHeight: '52px',
          marginTop: '8px !important',
        }}
        dropdownStyle={{
          position: isIOS ? 'absolute' : 'fixed',
        }}
        key={phoneState}
        onBlur={() => {
          setIsBlur(true);
          setTouched();
        }}
      />

      {showError && (
        <FormHelperText sx={{ color: '#ED2E38' }} error>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};
