import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  countDownText: {
    fontSize: '150px',
    textShadow: '0px 0px 14px rgba(0, 0, 0, 0.35)',
    fontFamily: 'Poppins',
  },
  recordTimeCounterWrapper: {
    position: 'absolute',
    minWidth: '60px',
    width: 'auto',
    height: '28px',
    borderRadius: '30px',
    zIndex: 20,
    background: 'rgba(0, 0, 0, 0.60)',
    top: '21px',
    right: '20px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 7px',
    justifyContent: 'space-between',
  },
  recordDot: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    background: '#F00',
    marginRight: '8px',
  },
  recordTimeText: {
    fontSize: '20px',
    fontWeight: 600,
    textShadow: '0px 0px 5px rgba(0, 0, 0, 0.35)',
    lineHeight: '100%',
  },
}));
