import { makeStyles } from '@mui/styles';

export const useReCAPTCHAStyles = makeStyles(() => ({
  captcha: {
    // marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: '5px',
  },
}));
