import { getTextWidth } from '../../../services/utilities';

export const getRewardCardFontSize = (text: string): number => {
  const MAX_FONT_SIZE = 25;
  const MAX_TEXT_WIDTH = 280;

  let fontSize = MAX_FONT_SIZE;
  const getFont = (fontSize: number) => `700 ${fontSize}px "Poppins", sans-serif`;

  let textWidth = getTextWidth(text, getFont(fontSize));

  while (textWidth > MAX_TEXT_WIDTH) {
    fontSize--;
    textWidth = getTextWidth(text, getFont(fontSize));
  }

  return fontSize;
};
