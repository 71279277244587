import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Image from 'next/image';
import { VideoPlayIcon } from '../../../common/assets/playerIcons/VideoPlayIcon';
import { defaultPagination, EventNames } from '../../../common/constants/constants';
import { AppRoutes } from '../../../common/constants/routes';
import { SortingStrings, VideoEmptyStateStrings } from '../../../common/localization/en';
import { getStoryVideos, resetStoriesCount, resetStoriesItems, updateStoryById } from '../../../store/slices/stories';
import { useAppDispatch, useTypedSelector } from '../../../store/store';
import { MediaList } from './MediaList/MediaList';

import { getSortingForRequest, useStyles } from './Videos.helper';
import { useTrackEvent } from '../../../common/hooks/useTrackEvent';
import { getBestImage } from '../../../services/utilities';

interface VideosParams {
  page: number;
  size: number;
  sort: string;
  isPublic?: boolean;
  tags?: string | null;
}

export interface ISortingParams {
  criteria: string;
  descending: boolean;
}

export const Videos: FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [sorting, setSorting] = useState(defaultPagination.sortByLastCreated);

  const [sortingParams] = useState<ISortingParams>({
    criteria: SortingStrings.DateAdded,
    descending: true,
  });

  useEffect(() => {
    setSorting(getSortingForRequest(sortingParams));
  }, [sortingParams]);

  const { stories, selectedTags } = useTypedSelector((state) => state.stories);
  const [items, setItems] = useState(stories.items);
  const { me } = useTypedSelector((state) => state);
  const { account, accountAttributes } = useTypedSelector((state) => state.account);
  const matches = {
    tablet: useMediaQuery(theme.breakpoints.between('sm', 'lg')),
    mobile: useMediaQuery(theme.breakpoints.down('sm')),
    desktop: useMediaQuery(theme.breakpoints.up('lg')),
  };
  const newTip = accountAttributes.properties?.['webapp.cards-welcome']
    ? getBestImage(accountAttributes.properties?.['webapp.cards-welcome'], matches)
    : null;
  const classes = useStyles({ url: newTip });
  const { trackEvent } = useTrackEvent();

  // useEffect(() => {
  //   trackPageView({
  //     url: AppRoutes.DefaultProfileRoute,
  //     venueId: account.id,
  //     userId: me.id,
  //     email: me.email,
  //     username: me.name,
  //   });
  // }, [me.email, me.id, me.name, account.id]);

  useEffect(() => {
    setItems(stories.items);
  }, [stories.items]);

  useEffect(() => {
    if (account.id && me.id) {
      const params: VideosParams = {
        page: defaultPagination.page,
        size: 100,
        sort: sorting,
        tags: Array.isArray(selectedTags) ? (selectedTags.length ? selectedTags.join(',') : '') : null,
      };

      dispatch(getStoryVideos(params));
    }
  }, [account.id, me.id, dispatch, selectedTags, sorting]);

  useEffect(() => {
    trackEvent(EventNames.My_Videos_Page);

    dispatch(resetStoriesItems());
    return () => {
      dispatch(resetStoriesItems());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (stories.items.length) {
      dispatch(resetStoriesCount());
    }
  }, [dispatch, stories.items]);

  const updateStoryHandler = ({ id, isPublic }: { id: string; isPublic: boolean }) => {
    dispatch(updateStoryById({ id, isPublic }));
  };

  const [background, setBackground] = useState<string | null>(null);
  useEffect(() => {
    if (newTip) setBackground(newTip);
  }, [newTip]);

  const CreateVideoButton = () => (
    <Box sx={{ marginTop: '148px' }}>
      <Grid container justifyContent="center">
        <Grid item xs={10} md={11} lg={5}>
          <Box>
            <Link to={AppRoutes.Home}>
              <Button size="large" variant="contained" color="primary" fullWidth className={classes.btnCreateVideo}>
                {VideoEmptyStateStrings.ButtonText}
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  if (!stories.items.length && !stories.isLoading) {
    return (
      <Box>
        {newTip && (
          <Box className={classes.imageWrapper}>
            <Image src={newTip || ''} layout="fill" alt="" className={classes.imageBackground} />
          </Box>
        )}
        <Grid
          container
          justifyContent="center"
          flexDirection="column"
          sx={{ marginTop: '120px', zIndex: '1' }}
          className={classes.noContentContainer}
        >
          <Grid item justifyContent="center" sx={{ textAlign: 'center' }}>
            <Box className={classes.iconWrapper}>
              <VideoPlayIcon />
            </Box>
          </Grid>
          <Grid item sx={{ textAlign: 'center' }}>
            <Typography className={classes.isNotVideos}>{VideoEmptyStateStrings.Title}</Typography>
            <Typography className={classes.isNotVideoSubtitle}>{VideoEmptyStateStrings.Subtitle}</Typography>
          </Grid>
          {CreateVideoButton()}
        </Grid>
      </Box>
    );
  }

  return (
    <>
      {/* removed sorting SV-163. Remove the sort feature from the web app My Videos section */}
      {/* <Typography className={classes.videosTitle}>{BottomNavStrings.myVideos}</Typography>
          <SortFilterSection
            sorting={sorting}
            setSorting={setSorting}
            sortingParams={sortingParams}
            setSortingParams={setSortingParams}
          /> */}
      <MediaList
        isLoading={false}
        items={items}
        updateStory={updateStoryHandler}
        sorting={sorting}
        setSorting={setSorting}
        backgroundImage={background || ''}
      />
    </>
  );
};
