import React, { FC, useState } from 'react';
import { useTheme } from '@mui/material';

import { TextFieldStyled, useTextFieldStyles } from './FormTextField.style';
import { useThemeLayout } from '../../../hooks/useThemeLayout';
interface TextFieldProps {
  label: string;
  name: string;
  value: string | number;
  errorText?: string | null | undefined;
  type?: string;
  onChange: (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  setFieldTouched?: (name: string, isTouched: boolean) => void;
  disabled?: boolean;
  multiline?: boolean;
  variant?: 'outlined';
  className?: string;
  maxRows?: number;
  placeholder?: string;
  isOutlinedForLightTheme?: boolean;
}

export const FormTextField: FC<TextFieldProps> = (props) => {
  const theme = useTheme();
  const { isThemeLayoutEnabled, isLightTheme, isDarkTheme, themeTextColor } = useThemeLayout();

  const {
    label,
    name,
    value,
    setFieldTouched,
    errorText,
    type = 'text',
    onChange,
    onBlur,
    onKeyDown,
    onKeyUp,
    disabled = false,
    multiline = false,
    variant = 'standard',
    maxRows = 1,
    className,
    placeholder,
    isOutlinedForLightTheme,
  } = props;

  const onBlurHandler = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (setFieldTouched) {
      setFieldTouched(name, true);
    }
    onBlur(event);
  };

  const styles = useTextFieldStyles();

  const [_, setIsFocused] = useState(false);
  const darkThemeInputUnderlineColor = 'rgba(208, 213, 221, 0.50)';

  return (
    <TextFieldStyled
      theme={theme}
      autoComplete="off"
      disabled={disabled}
      name={name}
      error={Boolean(errorText)}
      label={label}
      value={value}
      type={type}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onChange={onChange}
      InputProps={{ disableUnderline: isLightTheme && isOutlinedForLightTheme }}
      sx={{
        '.MuiInput-underline:before': {
          borderBottom:
            isLightTheme && isOutlinedForLightTheme
              ? 'unset'
              : `1px solid ${isDarkTheme ? darkThemeInputUnderlineColor : theme?.palette?.primary?.main} !important`,
          bottom: '6px',
          transition: 'unset',
        },
        '.MuiInput-underline:after': {
          borderBottom:
            isLightTheme && isOutlinedForLightTheme
              ? 'unset'
              : `2px solid ${isDarkTheme ? darkThemeInputUnderlineColor : theme?.palette?.primary?.main}`,
          bottom: '6px',
          transition: 'unset',
        },
        '& .MuiInput-input': {
          color: isThemeLayoutEnabled ? themeTextColor : '#fff',
          border:
            isLightTheme && isOutlinedForLightTheme
              ? `1px solid  ${errorText ? theme.palette.error.main : '#D0D5DD'}`
              : 'unset',
          borderRadius: '8px',
        },
        '& .MuiInput-input:focus': {
          border:
            isLightTheme && isOutlinedForLightTheme
              ? `2px solid  ${errorText ? theme.palette.error.main : '#D0D5DD'}`
              : 'unset',
          padding: isLightTheme && isOutlinedForLightTheme ? '9.3px 10px' : '10px 0',
        },
        '& .MuiInput-input:-webkit-autofill, .MuiInput-input:-webkit-autofill:focus, .MuiInput-input:-webkit-autofill:hover':
          {
            WebkitTextFillColor: isThemeLayoutEnabled ? themeTextColor : '#fff',
          },
        '.MuiFormLabel-root': {
          color: isLightTheme ? '#767676' : '#fff',
          marginBottom: '8px',
        },
        '.MuiFormLabel-root.Mui-focused': {
          color: isLightTheme ? '#767676' : '#fff',
        },
        '& .MuiInput-root:hover:not(.Mui-disabled):before': {
          borderBottom:
            isLightTheme && isOutlinedForLightTheme
              ? 'unset'
              : `1px solid ${isDarkTheme ? darkThemeInputUnderlineColor : theme?.palette?.primary?.main} !important`,
        },
        '& .MuiInput-root': {
          marginTop: '24px',
        },
      }}
      onBlur={(e) => {
        setIsFocused(false);
        onBlurHandler(e);
      }}
      onFocus={() => {
        setIsFocused(true);
      }}
      variant={variant}
      className={[className, styles.TextField].join(' ')}
      helperText={errorText}
      multiline={multiline}
      maxRows={maxRows}
      InputLabelProps={{ shrink: true }}
      placeholder={placeholder}
    />
  );
};
