import { FC } from 'react';

interface IconProps {
  color: string;
}

export const ArrowUpPasswordIcon: FC<IconProps> = ({ color }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9201 15.05L13.4001 8.53001C12.6301 7.76001 11.3701 7.76001 10.6001 8.53001L4.08008 15.05"
        stroke={color || '#17B790'}
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M4 22H20" stroke={color || '#17B790'} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
