import { GetServerSideProps } from 'next';

import { Header } from '../common/header';
import { getServerSideHelper } from '../src/services/utilities';
import { HomePageProps } from '../src/api/models/venue';
import { HomePage } from '../src/pages/HomePage/HomePage';

const Home = (props: HomePageProps) => {
  return (
    <>
      <Header {...props} />
      <HomePage />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { props } = (await getServerSideHelper(context)) as any;

  return {
    props: { ...props },
  };
};

export default Home;
