/* eslint-disable @next/next/no-img-element */
import React, { FC, useEffect, useRef, useState } from 'react';
import { useStyles } from './LaunchScreen.styles';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useTypedSelector } from '../../store/store';
import { getCoverBackground, getRevealBackground } from './LaunchScreen.helpers';
import { SignUpScreenStrings } from '../../common/localization/en';

const TEXT_PADDING = 40;

interface Props {
  isLoading: boolean;
  setIsLaunchScreen: (isLaunchScreen: boolean) => void;
}

const LaunchScreen: FC<Props> = ({ isLoading, setIsLaunchScreen }) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const {
    account: { logo, name },
    accountAttributes: { properties },
  } = useTypedSelector((state) => state.account);

  const reveal = properties?.['webapp.launch-screen']?.reveal;
  const type = properties?.['webapp.launch-screen']?.type;

  const brandColor = properties?.['webapp.config']?.['primary-color'];
  const splashImage = properties?.['webapp.cards-welcome']?.desktop.original;
  const revealValue = reveal ? reveal : splashImage ? 'splash' : 'branding';
  const launchType = type ? type : logo ? 'logo' : 'name';

  const coverBackground = getCoverBackground(properties?.['webapp.launch-screen']?.cover, brandColor);
  const revealBackground = getRevealBackground(revealValue, brandColor, splashImage);
  const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);

  useEffect(() => {
    setIsLaunchScreen(true);
  }, [setIsLaunchScreen]);

  useEffect(() => {
    if (revealValue !== 'splash' || !splashImage) {
      setIsBackgroundImageLoading(false);
    } else {
      const image = new Image();
      image.src = revealBackground;
      image.onload = () => {
        setTimeout(() => {
          setIsBackgroundImageLoading(false);
        }, 300);
      };
    }
  }, [revealBackground, revealValue, splashImage]);

  useEffect(() => {
    let redirectTimer: ReturnType<typeof setTimeout>;

    if (!isLoading && !isBackgroundImageLoading) {
      redirectTimer = setTimeout(() => {
        setIsLaunchScreen(false);
      }, 2000);
    }

    return () => {
      clearTimeout(redirectTimer);
    };
  }, [dispatch, isBackgroundImageLoading, isLoading, setIsLaunchScreen]);

  const containerElement = useRef<HTMLDivElement>(null);
  const textElement = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const container = containerElement.current;
    const textContainer = textElement.current;
    let fontSize = 44;

    if (container && textContainer) {
      const textNodes = textContainer.children;

      for (let i = 0; i < textNodes.length; i++) {
        const textNode = textNodes.item(i) as HTMLSpanElement;
        let textWidth = textNode.offsetWidth;

        while (textWidth >= container.offsetWidth - TEXT_PADDING) {
          textContainer.style.fontSize = `${fontSize}px`;
          textWidth = textNode.offsetWidth;
          --fontSize;
        }
      }
    }
  }, [containerElement, textElement]);

  return (
    <>
      <Box className={styles.container} sx={{ background: coverBackground }} ref={containerElement}>
        {launchType === 'logo' ? (
          <img src={logo} alt="Company logo" className={styles.logo} />
        ) : (
          <Typography className={styles.companyName} sx={{ color: brandColor }} ref={textElement}>
            {name.split(' ').map((text) => (
              <span key={text}>{text} </span>
            ))}
          </Typography>
        )}
        <Box
          className={styles.revealWrapper}
          sx={{
            background: revealValue === 'branding' ? revealBackground : `url(${revealBackground})`,
            clipPath:
              isLoading || isBackgroundImageLoading ? 'ellipse(0% 0% at 100% 100%)' : 'ellipse(140% 180% at 100% 100%)',
          }}
        >
          <Typography
            className={styles.companyName}
            sx={{ textShadow: revealValue === 'splash' ? `2px 2px 0px ${brandColor}` : 'unset', color: '#FFF' }}
          >
            {SignUpScreenStrings.LetsGo}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default LaunchScreen;
