import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '210px',
  },
  contentWrapper: {
    width: '303px',
    borderRadius: '12px',
  },
  rewardNameWrapper: {
    width: '100%',
  },
  rewardName: {
    fontWeight: 600,
    lineHeight: '32px',
    marginTop: '26px',
    textAlign: 'center',
  },
  cardWrapper: {
    marginTop: '24px',
  },
  progressWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  progressBar: {
    width: '100%',
    height: '8px',
    borderRadius: '100px',
    marginRight: '12px',
    backgroundColor: '#fff',
    '& .MuiLinearProgress-root': {
      backgroundColor: '#fff',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      background: '#4F96FF',
    },
  },
  percentsText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#fff',
  },
  descriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '16px',
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '10px',
    width: '100%',
  },
  achievedText: {
    fontWeight: 600,
    fontSize: '14px',
    margin: '10px 0',
  },
  moreVideosText: {
    marginTop: '20px',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '20px',
  },
  eligibilityText: {
    marginTop: '8px',
    fontWeight: 500,
    fontSize: '13px',
    marginBottom: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    width: '280px',
  },
  cardImage: {
    width: '100%',
    height: 'auto',
    margin: '20px 0 ',
  },
}));
