export const getCoverBackground = (
  cover: 'white' | 'branding' | 'black' | undefined,
  brandColor: string | undefined,
) => {
  switch (cover) {
    case 'white':
      return '#FFF';
    case 'branding':
      return brandColor || '#FFF';
    case 'black':
      return '#151515';
    default:
      return '#FFF';
  }
};

export const getRevealBackground = (
  cover: 'branding' | 'splash',
  brandColor: string | undefined,
  splashImage: string | undefined | null,
) => {
  switch (cover) {
    case 'branding':
      return brandColor || '#FFF';
    case 'splash':
      return splashImage || '#151515';
    default:
      return brandColor || '#FFF';
  }
};
