import React, { FC, memo, useMemo, useCallback } from 'react';
import { Box } from '@mui/material';

import { AvatarEditorIcon } from '../../../assets/AvatarEditorIcon';
import { Container, PictureWrapper, PicturedLabel, PicturedInput, MediaIconWrapper } from './ProfilePicture.style';

interface ProfilePictureProps {
  userAvatar?: File | string;
  userName: string;
  onChange: (base64Img: string) => void;
  primaryColor: string;
}

export const ProfilePicture: FC<ProfilePictureProps> = memo((props) => {
  const { userAvatar, onChange, primaryColor, userName } = props;

  const readFile = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const onChangeHandler = useCallback(
    async (event) => {
      const base64Img = (await readFile(event.target.files[0])) as string;
      event.target.value = '';
      onChange(base64Img);
    },
    [onChange],
  );

  const getUserAvatar = useCallback(() => {
    if (typeof userAvatar !== 'string') {
      return URL.createObjectURL(userAvatar);
    }

    return userAvatar;
  }, [userAvatar]);

  const PictureBlock = useMemo(() => {
    if (userAvatar) {
      return <img src={getUserAvatar()} alt="user_picture" />;
    }

    return (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={primaryColor}
        fontSize="1.25rem"
      >
        {userName?.charAt(0)}
      </Box>
    );
  }, [getUserAvatar, userAvatar]);

  return (
    <Container>
      <PicturedLabel htmlFor="userAvatar">
        <PictureWrapper>
          {PictureBlock}
          <MediaIconWrapper>
            <AvatarEditorIcon />
          </MediaIconWrapper>

          <PicturedInput id="userAvatar" type="file" accept=".png, .jpg, .jpeg" onChange={onChangeHandler} />
        </PictureWrapper>
      </PicturedLabel>
    </Container>
  );
});
