import { Box } from '@mui/material';
import React from 'react';

interface PageContainerProps {
  withOffsetTop?: boolean;
  withoutTopBar?: boolean;
}

export const PageContainer: React.FC<PageContainerProps> = ({ children, withoutTopBar }) => {
  return (
    <Box display="flex" justifyContent="center">
      <Box width="100%" maxWidth="540px" mt={withoutTopBar ? '-30px' : '16px'}>
        {children}
      </Box>
    </Box>
  );
};
