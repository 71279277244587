import React, { FC } from 'react';
import { Box, Button, Typography, Dialog } from '@mui/material';
import { useStyles } from './RecordErrorModal.styles';
import { useThemeLayout } from '../../hooks/useThemeLayout';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const RecordErrorModal: FC<Props> = ({ isOpen, onClose }) => {
  const styles = useStyles();
  const { isDarkTheme } = useThemeLayout();
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      className={styles.container}
      sx={{
        '& .MuiPaper-root': {
          background: isDarkTheme ? '#2C2B2B' : '#fff',
        },
      }}
    >
      <Box
        className={styles.contentWrapper}
        style={{
          background: isDarkTheme ? '#2C2B2B' : '#fff',
        }}
      >
        <Typography className={styles.subtitle} color={isDarkTheme ? 'rgba(255, 255, 255, 0.80)' : '#000'}>
          {'The recorded video size is too big.'}
        </Typography>
        <Typography className={styles.subtitle} color={isDarkTheme ? 'rgba(255, 255, 255, 0.80)' : '#000'}>
          {'Please record a shorter video '}
        </Typography>
        <Typography className={styles.subtitle} color={isDarkTheme ? 'rgba(255, 255, 255, 0.80)' : '#000'}>
          {'(less than 2 min)'}
        </Typography>

        <Button size="small" variant="contained" onClick={onClose} className={styles.btnClose}>
          Try again
        </Button>
      </Box>
    </Dialog>
  );
};
