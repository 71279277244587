import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: '81.9vw',
    maxWidth: '515px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: 'clamp(18px,  5vw, 27px)',
    fontWeight: 500,
    lineHeight: '100%',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 'clamp(12px,  3.36vw, 18px)',
    fontWeight: 500,
    lineHeight: '160%',
    textAlign: 'center',
    marginTop: '14px',
  },
  btn: {
    wordBreak: 'break-word',
    fontWeight: 600,
    fontSize: 'clamp(16px, 4.4vw, 24px)',
    width: '81.9vw',
    maxWidth: '515px',
    height: '12.2vw',
    maxHeight: '86px',
    borderRadius: 10,
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
    textTransform: 'none',
    padding: 0,
    marginTop: '45px',
    '&:active': {
      transition: '0.1s',
      transform: 'scale(0.99)',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
