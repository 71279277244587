import { Box, Button, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useTypedSelector } from '../../../store/store';
import { AuthFormValues, AuthInitialFormValues } from '../../commonTypes';
import { EventNames } from '../../constants/constants';
import { AppRoutes } from '../../constants/routes';
import { AuthFormStrings } from '../../localization/en';
import { FormTextField } from '../FormComponents/FormTextField/FormTextField';
import { PasswordField } from '../FormComponents/PasswordField/PasswordField';
import { TermsOfServiceLink } from '../TermsOfServiceLink/TermsOfServiceLink';
import { getError, PRIVACY_POLICY_LINK_TRUBLU, TERMS_OF_SERVICE_LINK_TRUBLU } from './AuthForm.helper';
import { FSForm, FSFormFields, LinkBetweenRegAndSignIn, useAuthStyles, WrapperButton } from './AuthForm.style';
import { ResetPassword } from './ResetPassword/ResetPassword';
import TermsOfServicePopup from './TermsOfServicePopup';

import { PhoneFieldInternational } from '../FormComponents/PhoneFieldInternational/PhoneFieldInternational';

import { trackEvent } from '../../../../utils/analytics/analytics';
import { WarningIcon } from '../../assets/shareIcons/WarningIcon';
import { FormNames } from '../AuthPopup/AuthPopup.helper';
import { CustomCheckBox } from '../CustomCheckBox/CustomCheckBox';
import { ReCaptcha, ReCaptchaRefType } from '../FormComponents/ReCAPTCHA/ReCaptcha';
import { useClientType } from '../../../services/hooks/useClientType';
import { useThemeLayout } from '../../hooks/useThemeLayout';

interface AuthFormProps {
  formik: FormikProps<AuthInitialFormValues>;
  formFieldsData: AuthFormValues;
  disabledSubmitButton: boolean;
  transitionBetweenForms?: () => void;
  disabledFieldNames?: string[];
  disablePasswordReset?: boolean;
  requiredFields?: Array<keyof AuthInitialFormValues>;
  setCaptcha: Dispatch<SetStateAction<string | null>>;
  formName?: FormNames;
  isVerificationScreen?: boolean;
  isPhonePrepopulated?: boolean;
  captchaResponse: string | null;
  onBackToSignUpClick?: () => void;
  captchaNotRequired?: boolean;
  isEnterpriseCaptcha?: boolean;
  setIsEnterpriseCaptcha: (value: boolean) => void;
  ecaptchaRef?: React.MutableRefObject<ReCaptchaRefType>;
}

export const AuthForm: FC<AuthFormProps> = ({
  formFieldsData,
  disabledSubmitButton,
  formik,
  transitionBetweenForms = () => {},
  disabledFieldNames,
  requiredFields = [],
  setCaptcha,
  formName,
  isVerificationScreen,
  isPhonePrepopulated,
  captchaResponse,
  onBackToSignUpClick,
  captchaNotRequired,
  isEnterpriseCaptcha,
  setIsEnterpriseCaptcha,
  ecaptchaRef,
}) => {
  const { account, accountAttributes } = useTypedSelector((state) => state.account);
  const layoutTheme = accountAttributes?.properties?.['webapp.config']?.['theme'];
  const { currentCampaign } = useTypedSelector((state) => state.rewards);
  const { isSvHealth, isMarketingClient, isTrubluClient } = useClientType();

  const [termsAndServiceModal, setTermsAndServiceModal] = useState(false);

  const { handleSubmit, values, handleBlur, handleChange, setFieldTouched, errors, isValid, touched } = formik;
  const history = useHistory();
  const { isThemeLayoutEnabled, isLightTheme, isDarkTheme, themeTextColor } = useThemeLayout();

  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean | null>(null);
  const [isSVTOSAccepted, setISVTOSAccepted] = useState<boolean | null>(isSvHealth ? null : true);

  const showEmailOnVerificationScreen = currentCampaign?.emailRequired && isVerificationScreen;

  const transitionBetweenFormsHandler = () => {
    transitionBetweenForms();
  };

  const onCaptchChange = (value: string | null) => {
    setCaptcha(value);
    if (value === null) {
      setIsEnterpriseCaptcha(false);
    }
    handleCaptchaChange(true);
  };
  const getErrorHandler = (name: string, disabled?: boolean) => {
    if (disabled) {
      return values[name] ? errors[name] : '';
    }
    return getError(name, touched, errors);
  };

  const submitButtonText = useMemo(
    () => (disabledSubmitButton ? AuthFormStrings.Processing : formFieldsData.submitButtonName),
    [disabledSubmitButton, formFieldsData.submitButtonName],
  );

  const isProfilePage = history.location.pathname.includes(AppRoutes.ProfileAccount);
  const isSignupPage = history.location.pathname === AppRoutes.Home || history.location.pathname === AppRoutes.Auth;

  const styles = useAuthStyles({
    clientHeight: window.innerHeight,
    layoutTheme: isThemeLayoutEnabled ? layoutTheme : undefined,
    isThemeLayoutEnabled,
    isProfilePage,
  });

  const isSubmitButtonEnabled = useMemo(() => {
    const isFielEntered = formName === 0 ? !!values.firstName && !!values.lastName : !!values.phoneNumber;

    return (
      isValid &&
      isFielEntered &&
      !disabledSubmitButton &&
      (captchaResponse || isProfilePage || captchaNotRequired || isEnterpriseCaptcha) &&
      (isSignupPage && isSvHealth ? isSVTOSAccepted : true)
    );
  }, [
    disabledSubmitButton,
    isValid,
    values.phoneNumber,
    values.firstName,
    values.lastName,
    formName,
    captchaResponse,
    isProfilePage,
    isSVTOSAccepted,
    captchaNotRequired,
  ]);

  const handleCloseTermsOfServiceModal = () => {
    setTermsAndServiceModal(false);
  };

  const openTermsOfServiceModal = (action: string) => {
    trackEvent({
      action: action,
      location: window.location.href,
      accountId: account.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
    });

    // setTermsAndServiceModal(true);
  };

  const onForgotPasswordClick = () => {
    // tracking only
    trackEvent({
      action: EventNames.LoginFormForgotPassword,
      location: window.location.href,
      accountId: account.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
    });
  };

  const trackedHandleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!isProfilePage) {
      if (isTrubluClient && isTermsAccepted === null) {
        setIsTermsAccepted(false);
        return;
      }

      if (isSvHealth && isSVTOSAccepted === null) {
        setISVTOSAccepted(false);
        return;
      }
    }
    trackEvent({
      action: !!formName ? EventNames.Login_Button_Click : EventNames.Signup_Button_Click,
      location: window.location.href,
      accountId: account.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      form: isVerificationScreen ? 'verification_screen' : !!formName ? 'Login' : 'Sign up',
    });
    handleSubmit();
  };

  const handlePhoneChange = (val: string) => {
    formik.setFieldValue('phoneNumber', '+' + val);
  };

  const handleTrackPhoneNumberBlur = () => {
    trackEvent({
      action: EventNames.Signup_Phone_Typed,
      location: window.location.href,
      accountId: account.id,
      phoneNumber: values.phoneNumber,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      form: isVerificationScreen ? 'verification_screen' : !!formName ? 'Login' : 'Sign up',
    });
  };

  const constructRequiredLabel = ({ field, label }: { field: keyof AuthInitialFormValues; label: string }) => {
    if (requiredFields.includes(field)) {
      // return `${label}*`;
      return `${label}`;
    }
    return label;
  };

  const isRequiredField = (field: keyof AuthInitialFormValues) => {
    return requiredFields.includes(field);
  };

  const handleChangeAndTrackEvent = (e: React.ChangeEvent<any>) => {
    handleChange(e);
  };

  const trackedHandleBlur = (e: React.FocusEvent<any>) => {
    const eventName =
      e.target.name === 'firstName'
        ? EventNames.Signup_Name_Typed
        : e.target.name === 'lastName'
        ? EventNames.Signup_Last_Name_Typed
        : e.target.name === 'email'
        ? EventNames.Signup_Email_Typed
        : e.target.name === 'phoneNumber'
        ? EventNames.Signup_Phone_Typed
        : '';
    handleBlur(e);
    trackEvent({
      action: eventName,
      location: window.location.href,
      accountId: account.id,
      [e.target.name]: e.target.value,
      form: isVerificationScreen ? 'verification_screen' : !!formName ? 'Login' : 'Sign up',
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      email: values.email,
    });
  };

  const handleCaptchaChange = (success: boolean) => {
    if (!success) setIsEnterpriseCaptcha(false);
    trackEvent({
      action: success ? EventNames.Captcha_Check_Success : EventNames.Captcha_Check_Failed,
      location: window.location.href,
      accountId: account.id,
      form: isVerificationScreen ? 'verification_screen' : !!formName ? 'Login' : 'Sign up',
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      email: values.email,
    });
  };

  const handleTrackTosOrPPLinkClick = (action: string) => {
    trackEvent({
      action: action,
      location: window.location.href,
      accountId: account.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      form: isVerificationScreen ? 'verification_screen' : !!formName ? 'Login' : 'Sign up',
    });
  };

  const trackCountryCodeChange = () => {
    trackEvent({
      action: EventNames.Phone_Country_Select,
      location: window.location.href,
      accountId: account.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      form: isVerificationScreen ? 'verification_screen' : !!formName ? 'Login' : 'Sign up',
    });
  };

  return (
    <>
      {termsAndServiceModal && <TermsOfServicePopup handleCloseTermsOfServiceModal={handleCloseTermsOfServiceModal} />}

      <FSForm
        onSubmit={trackedHandleSubmit}
        id="signuploginSubmitForm"
        className={styles.Form}
        style={{
          marginTop: isVerificationScreen ? '34px' : '0',
        }}
      >
        <FSFormFields>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {formFieldsData.isNameField && (
              <FormTextField
                onChange={handleChangeAndTrackEvent}
                onBlur={trackedHandleBlur}
                setFieldTouched={setFieldTouched}
                value={values.firstName || ''}
                label={constructRequiredLabel({
                  field: 'firstName',
                  label: AuthFormStrings.FirstName,
                })}
                name="firstName"
                errorText={getErrorHandler('firstName', formFieldsData.isNameFieldDisabled)}
                disabled={formFieldsData.isNameFieldDisabled || !!disabledFieldNames?.includes('firstName')}
                placeholder={''}
                className={`${styles.textField} ${styles.fixedWidth}`}
                isOutlinedForLightTheme={false}
              />
            )}
            {formFieldsData.isNameField && (
              <FormTextField
                onChange={handleChangeAndTrackEvent}
                onBlur={trackedHandleBlur}
                setFieldTouched={setFieldTouched}
                value={values.lastName || ''}
                label={constructRequiredLabel({
                  field: 'lastName',
                  label: AuthFormStrings.LastName,
                })}
                name="lastName"
                errorText={getErrorHandler('lastName', formFieldsData.isNameFieldDisabled)}
                disabled={formFieldsData.isNameFieldDisabled || !!disabledFieldNames?.includes('lastName')}
                placeholder={''}
                className={`${styles.textField} ${styles.fixedWidth}`}
                isOutlinedForLightTheme={false}
              />
            )}
          </Box>

          {formFieldsData.isPhoneField && (
            <>
              <PhoneFieldInternational
                onChange={handlePhoneChange}
                name="phoneNumber"
                value={values.phoneNumber || ''}
                error={errors.phoneNumber}
                placeholder={isRequiredField('phoneNumber') ? 'Required' : ''}
                label={constructRequiredLabel({
                  field: 'phoneNumber',
                  label: 'Cell Phone',
                })}
                disabled={isProfilePage}
                className={styles.textField}
                showErrorOnBlur={true}
                setTouched={() => {
                  formik.setFieldTouched('phoneNumber', true);
                  handleTrackPhoneNumberBlur();
                }}
                isPrepopulated={isPhonePrepopulated}
                trackCountryCodeChange={trackCountryCodeChange}
                isOutlinedForLightTheme={false}
                isProfilePage={isProfilePage}
                inputFontSize="13px"
              />
            </>
          )}

          {(formFieldsData.isEmailField || showEmailOnVerificationScreen) && (
            <FormTextField
              onChange={handleChangeAndTrackEvent}
              onBlur={trackedHandleBlur}
              setFieldTouched={setFieldTouched}
              value={values.email}
              label={constructRequiredLabel({
                field: 'email',
                label: AuthFormStrings.Email,
              })}
              disabled={formFieldsData.isEmailFieldDisabled || !!disabledFieldNames?.includes('email')}
              name="email"
              type="email"
              errorText={getErrorHandler('email', formFieldsData.isEmailFieldDisabled)}
              placeholder={isProfilePage ? 'Enter an email' : isRequiredField('email') ? 'Required' : undefined}
              className={styles.textField}
              isOutlinedForLightTheme={false}
            />
          )}

          {formFieldsData.isInstagramField && (
            <FormTextField
              onChange={handleChange}
              onBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              value={values.instagramHandle || ''}
              label={constructRequiredLabel({
                field: 'instagramHandle',
                label: AuthFormStrings.InstagramHandle,
              })}
              name="instagramHandle"
              errorText={getErrorHandler('instagramHandle')}
            />
          )}

          {formFieldsData.isPasswordField && (
            <PasswordField
              onChange={handleChange}
              onBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              value={values.password}
              label={constructRequiredLabel({
                field: 'password',
                label: AuthFormStrings.Password,
              })}
              name="password"
              errorText={getErrorHandler('password')}
              disabled={!!disabledFieldNames?.includes('password')}
            />
          )}

          {formFieldsData.isConfirmPassword && (
            <PasswordField
              onChange={handleChange}
              onBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              value={values.confirmPassword}
              label={constructRequiredLabel({
                field: 'confirmPassword',
                label: AuthFormStrings.ConfirmPassword,
              })}
              name="confirmPassword"
              errorText={getErrorHandler('confirmPassword')}
              disabled={!!disabledFieldNames?.includes('password')}
            />
          )}

          {formFieldsData.isInstagramAuthField && (
            <FormTextField
              onChange={handleChange}
              onBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              value={values.instagramHandle || ''}
              label={constructRequiredLabel({
                field: 'instagramHandle',
                label: AuthFormStrings.InstagramHandleOptional,
              })}
              name="instagramHandle"
              errorText={getErrorHandler('instagramHandle')}
            />
          )}
          {!isProfilePage && !captchaNotRequired && (
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" marginTop="10px">
              <ReCaptcha
                onChange={onCaptchChange}
                key={formName}
                ref={ecaptchaRef}
                onError={() => {
                  handleCaptchaChange(false);
                }}
                onExpired={() => {
                  handleCaptchaChange(false);
                }}
                isEnterprise={isEnterpriseCaptcha}
                onClick={() => {
                  trackEvent({
                    action: EventNames.Captcha_Checkbox_Click,
                    location: window.location.href,
                    accountId: account.id,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    phoneNumber: values.phoneNumber,
                    form: isVerificationScreen ? 'verification_screen' : !!formName ? 'Login' : 'Sign up',
                  });
                }}
              />
            </Box>
          )}

          {formFieldsData.isResetPassword && (
            <ResetPassword
              onChange={handleChange}
              onBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              newPassword={values.password || ''}
              confirmPassword={values.confirmPassword}
              newPasswordError={getErrorHandler('password')}
              confirmPasswordError={getErrorHandler('confirmPassword')}
              label={formFieldsData.passwordPlaceholder || ''}
              isFieldsOpened={formFieldsData.isResetFieldsOpened}
            />
          )}
        </FSFormFields>

        <WrapperButton
          style={{
            marginTop: !isVerificationScreen ? '15px' : showEmailOnVerificationScreen ? '5px' : '24px',
            height: isVerificationScreen ? '60px' : '150px',
            marginBottom: '20px',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '290px',
          }}
        >
          <Button
            classes={{ disabled: styles.DisabledSubmitButton }}
            className={styles.AuthSubmitBtn}
            sx={{
              marginTop: isVerificationScreen || formFieldsData.isTermsOfService ? '0' : '35px',
              height: '48px',
              borderRadius: '12px',
            }}
            variant="contained"
            fullWidth
            disabled={
              !isSubmitButtonEnabled ||
              (!isProfilePage && isSignupPage && (isTermsAccepted === false || isSVTOSAccepted === false))
            }
            type="submit"
          >
            {isVerificationScreen ? 'Send Verification Code' : submitButtonText}
          </Button>
          {isVerificationScreen && (
            <Typography className={styles.messageRateText} color={isLightTheme ? '#767676' : '#fff'}>
              {AuthFormStrings.MessageRate}
            </Typography>
          )}
          {isVerificationScreen && history.location.pathname !== AppRoutes.video && (
            <Box display="flex" justifyContent="center" width="100%">
              <Button onClick={onBackToSignUpClick}>Back to Sign Up</Button>
            </Box>
          )}

          {isTrubluClient && !isProfilePage && isSignupPage && (
            <>
              <Box
                className={styles.TermsWrapper}
                sx={{
                  marginBottom: isTermsAccepted ? '10px !important' : '0 !important',
                  background: isDarkTheme ? '#222124' : isLightTheme ? '#DFDFE2' : 'unset',
                  padding: isThemeLayoutEnabled ? '5px' : '0',
                  borderRadius: '5px',
                  marginTop: isThemeLayoutEnabled ? '16px' : '10px',
                }}
              >
                <CustomCheckBox
                  isChecked={isTermsAccepted || false}
                  onChange={(_, isChecked) => {
                    setIsTermsAccepted(isChecked);

                    trackEvent({
                      action: EventNames.Tb_Tos_Checkbox_Click,
                      location: window.location.href,
                      accountId: account.id,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      email: values.email,
                      phoneNumber: values.phoneNumber,
                      form: isVerificationScreen ? 'verification_screen' : !!formName ? 'Login' : 'Sign up',
                      tos: isChecked,
                    });
                  }}
                  outlineColor={isTermsAccepted === false ? '#FC4741' : '#FFFFFF'}
                />
                <Typography className={styles.TermsText} color={isThemeLayoutEnabled ? themeTextColor : '#fff'}>
                  {'I accept the '}
                  <a
                    onClick={() => handleTrackTosOrPPLinkClick(EventNames.Tb_Tos_Link_Click)}
                    className={styles.TermsLink}
                    href={TERMS_OF_SERVICE_LINK_TRUBLU}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: isThemeLayoutEnabled ? themeTextColor : '#fff',
                    }}
                  >
                    {'Terms of Use '}
                  </a>
                  &
                  <a
                    onClick={() =>
                      handleTrackTosOrPPLinkClick(
                        isSvHealth
                          ? EventNames.Sv_Health_Tos_Privacy_Policy_Click
                          : EventNames.Tb_Tos_Privacy_Policy_Click,
                      )
                    }
                    className={styles.TermsLink}
                    href={PRIVACY_POLICY_LINK_TRUBLU}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: isThemeLayoutEnabled ? themeTextColor : '#fff',
                    }}
                  >
                    {AuthFormStrings.PrivacyPolicy}
                  </a>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: isThemeLayoutEnabled ? 'start' : 'center' }}>
                {!isProfilePage && isTermsAccepted === false && isTrubluClient && (
                  <Box
                    className={styles.TermsError}
                    mt={isThemeLayoutEnabled ? '5px' : '0'}
                    marginLeft={isThemeLayoutEnabled ? '42px' : ''}
                  >
                    <WarningIcon />
                    {AuthFormStrings.CheckToContinue}
                  </Box>
                )}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {formFieldsData.isTermsOfService && !formFieldsData.isForgotPasswordLink && !isVerificationScreen && (
                  <TermsOfServiceLink
                    handleClick={() => openTermsOfServiceModal(EventNames.Sv_Tos_Link_Click)}
                    pretext={true}
                  />
                )}
              </Box>
            </>
          )}

          {formFieldsData.isTermsOfService &&
            !formFieldsData.isForgotPasswordLink &&
            !isVerificationScreen &&
            !isTrubluClient && (
              <>
                <Box
                  className={styles.TOSWrapper}
                  style={{
                    background: isDarkTheme ? '#222124' : isLightTheme ? '#DFDFE2' : 'unset',
                    padding: '5px',
                    borderRadius: '5px',
                    marginTop: isThemeLayoutEnabled ? '16px' : '10px',
                  }}
                >
                  <CustomCheckBox
                    isChecked={isSVTOSAccepted || false}
                    onChange={(_, isChecked) => {
                      setISVTOSAccepted(isChecked);

                      trackEvent({
                        action: EventNames.Sv_Tos_Checkbox_Click,
                        location: window.location.href,
                        accountId: account.id,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        phoneNumber: values.phoneNumber,
                        form: isVerificationScreen ? 'verification_screen' : !!formName ? 'Login' : 'Sign up',
                        tos: isChecked,
                      });
                    }}
                    outlineColor={isTermsAccepted === false ? '#FC4741' : '#FFFFFF'}
                  />
                  <TermsOfServiceLink handleClick={() => openTermsOfServiceModal(EventNames.Sv_Tos_Link_Click)} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: isThemeLayoutEnabled ? 'start' : 'center',
                    marginLeft: isThemeLayoutEnabled ? '22px' : '0',
                    marginTop: isThemeLayoutEnabled ? '3px' : '0',
                  }}
                >
                  {isSVTOSAccepted === false && (isSvHealth || isMarketingClient) && (
                    <Box className={styles.TermsError}>
                      <WarningIcon />
                      {AuthFormStrings.CheckToContinue}
                    </Box>
                  )}
                </Box>
              </>
            )}
        </WrapperButton>

        {formFieldsData.isForgotPasswordLink && (
          <Link to={AppRoutes.ForgotPassword} className={styles.ForgotPasswordLink}>
            <Box component="div" onClick={onForgotPasswordClick}>
              {AuthFormStrings.ForgotPassword}
            </Box>
          </Link>
        )}

        {formFieldsData.isLinkBetweenForms && (
          <LinkBetweenRegAndSignIn onClick={transitionBetweenFormsHandler}>
            {formFieldsData.text ?? ''}
          </LinkBetweenRegAndSignIn>
        )}
      </FSForm>
    </>
  );
};
