import styled from 'styled-components';
import { Button } from '../../styles/commonStyles.style';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BREAK_HEIGHT } from '../../constants/constants';
import { VideoAppTheme } from '../../../api/models/venue';

export const FSForm = styled.form<{ theme: Theme }>`
  padding: 5px 0 0 0;
  color: ${(props) => props?.theme?.palette?.common?.white};
`;

export const LinkBetweenRegAndSignIn = styled(Button)<{ theme: Theme }>`
  width: 100%;
  margin: 10px 0 10px 0;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  color: var(--white);
  background: transparent;
  border: 1px solid var(--blue);
`;

export const SocialButtonsWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-evenly;
`;

export const SocialError = styled.p`
  margin-top: 10px;
  text-align: justify;
  color: var(--error);
`;

export const WrapperButton = styled.div`
  position: relative;
  margin-bottom: 0px;
  text-align: left;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 11px;
  top: 11px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  color: #000;
  text-align: center;
  line-height: 2;
  font-size: 16px;

  + div {
    margin-top: 20px;
  }
`;

export const FSFormFields = styled.div`
  > div {
    margin-bottom: 14px;
  }
`;

export const useAuthStyles = makeStyles<
  Theme,
  { clientHeight: number; isThemeLayoutEnabled?: boolean; layoutTheme?: VideoAppTheme; isProfilePage?: boolean }
>((theme: Theme) => ({
  AuthSubmitBtn: () => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: '16px',
    fontWeight: 400,
  }),

  textField: (props) => ({
    marginBottom: props.clientHeight > BREAK_HEIGHT ? '14px !important' : '12px !important',

    '& .MuiFormLabel-root': {
      fontSize: '14px !important',
      fontWeight: 700,
      letterSpacing: '0.15px',
    },
    '& input': {
      paddingLeft: '0',
      fontSize: '16px',

      // color: `${theme.palette.common.white} !important`,
      '&::placeholder': {
        opacity: '1 !important',
        color: `#94A5AB !important`,
      },
    },
  }),
  fixedWidth: {
    maxWidth: '144px',
  },
  ForgotPasswordLink: {
    wordBreak: 'break-word',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.01em',
    textAlign: 'center',
    color: `${theme.palette.primary.main} !important`,
    cursor: 'pointer',
    marginBottom: '24px',
  },
  DisabledSubmitButton: {
    backgroundColor: '#ccc !important',
  },

  TOSWrapper: {
    margin: '0 auto',
    width: '290px',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
  },

  TermsWrapper: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // width: '282px',
  }),
  TermsText: {
    textAlign: 'center',
    fontSize: '11px',
    [`@media (max-width: 380px)`]: {
      width: '220px',
      textAlign: 'center',
    },
  },
  TermsLink: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  TermsError: {
    color: '#FC4741',
    fontSize: '11px',
    height: '15px',
    width: '245px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dontCloseText: {
    textAlign: 'center',
    color: theme.palette.common.white,
    marginTop: '42px',
    fontSize: '15px',
    fontWeight: '500',
  },
  messageRateText: {
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center',
    paddingTop: '5px',
    opacity: '0.5',
  },
}));
