import { FC } from 'react';

export const RecordPlayIcon: FC = () => {
  return (
    <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_1_44891)">
        <circle cx="41" cy="41" r="41" fill="white" fillOpacity="0.5" />
      </g>
      <path
        d="M28.8536 40.9995V35.5935C28.8536 28.8817 33.6066 26.1332 39.4225 29.4891L44.1147 32.1921L48.807 34.895C54.6229 38.2509 54.6229 43.748 48.807 47.1039L44.1147 49.8069L39.4225 52.5098C33.6066 55.8658 28.8536 53.1172 28.8536 46.4054V40.9995Z"
        fill="white"
      />
      <defs>
        <filter id="filter0_b_1_44891" x="-20" y="-20" width="122" height="122" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1_44891" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1_44891" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
