import { FC } from 'react';

interface Props {
  cloudColor?: string;
  circleColor?: string;
}

export const UploadIcon: FC<Props> = ({ cloudColor, circleColor }) => {
  return (
    <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M84.6649 71.1245C83.1649 72.4579 81.5399 73.5829 79.7899 74.4995C78.2899 75.2495 76.7899 73.7912 77.0399 72.1245C77.2066 70.9162 77.2899 69.6662 77.2899 68.3745C77.2899 53.4579 65.1232 41.2912 50.2066 41.2912C35.2899 41.2912 23.1232 53.4579 23.1232 68.3745C23.1232 70.2912 23.3316 72.1245 23.7066 73.9162C23.9982 75.3745 22.8732 76.8329 21.4149 76.4579C4.95655 72.4579 4.87322 47.1245 23.2899 45.8329H23.4982C10.1232 8.62454 66.5399 -6.25046 72.9982 32.8329C91.0399 35.1245 98.3316 59.1662 84.6649 71.1245Z"
        fill={cloudColor || 'white'}
        fillOpacity="0.2"
      />
      <path
        d="M50.3333 48C38.8333 48 29.5 57.3333 29.5 68.8333C29.5 71.9167 30.1667 74.8333 31.4167 77.4167C31.75 78.1667 32.125 78.875 32.5417 79.5417C36.125 85.5833 42.75 89.6667 50.3333 89.6667C57.9167 89.6667 64.5417 85.5833 68.125 79.5417C68.5417 78.875 68.9167 78.1667 69.25 77.4167C70.5 74.8333 71.1667 71.9167 71.1667 68.8333C71.1667 57.3333 61.8333 48 50.3333 48ZM58.9583 67.0417L50.0833 75.25C49.5 75.7917 48.7083 76.0833 47.9583 76.0833C47.1667 76.0833 46.375 75.7917 45.75 75.1667L41.625 71.0417C40.4167 69.8333 40.4167 67.8333 41.625 66.625C42.8333 65.4167 44.8333 65.4167 46.0417 66.625L48.0417 68.625L54.7083 62.4583C56 61.2917 57.9583 61.375 59.125 62.625C60.2917 63.875 60.2083 65.8333 58.9583 67.0417Z"
        fill={circleColor || '#4F96FF'}
      />
      <path
        d="M64.5 68.5C64.5 75.9558 58.4558 82 51 82C43.5441 82 37.5 75.9558 37.5 68.5C37.5 61.0442 43.5441 55 51 55C58.4558 55 64.5 61.0442 64.5 68.5Z"
        fill={circleColor || '#4F96FF'}
      />
      <path
        d="M49.5 78C49.5 78.5523 49.9477 79 50.5 79C51.0523 79 51.5 78.5523 51.5 78L49.5 78ZM51.2071 59.2929C50.8166 58.9024 50.1834 58.9024 49.7929 59.2929L43.4289 65.6569C43.0384 66.0474 43.0384 66.6805 43.4289 67.0711C43.8195 67.4616 44.4526 67.4616 44.8431 67.0711L50.5 61.4142L56.1569 67.0711C56.5474 67.4616 57.1805 67.4616 57.5711 67.0711C57.9616 66.6805 57.9616 66.0474 57.5711 65.6569L51.2071 59.2929ZM51.5 78L51.5 60L49.5 60L49.5 78L51.5 78Z"
        fill="white"
      />
    </svg>
  );
};
