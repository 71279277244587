import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { FC, useState } from 'react';
import { theme } from '../../../theme';
import { ProfilePageStrings } from '../../localization/en';

const useStyles = makeStyles({
  checked: {
    transform: 'translateX(94%) !important',
  },
  wrapper: {
    position: 'relative',
    background: theme.palette.common.black,
    borderRadius: '20px',
    width: '180px',
    height: '34px',
    cursor: 'pointer',
    padding: '3px',
  },
  thumbn: {
    background: theme.palette.common.white,
    width: '90px',
    height: '28px',
    borderRadius: '20px',
  },
  public: {
    position: 'absolute',
    left: '26px',
    top: '3px',
    fontWeight: 600,
    fontSize: '12px',
    marginTop: '5px',
    color: '#323232',
    zIndex: '10',
  },
  private: {
    position: 'absolute',
    right: '24px',
    top: '3px',
    fontWeight: 600,
    fontSize: '12px',
    marginTop: '5px',
    color: '#323232',
    zIndex: '10',
  },
});

interface CustomSwitchProps {
  onChange: (isPublic: boolean) => void;
  isPublic: boolean;
}

const CustomSwitch: FC<CustomSwitchProps> = ({ onChange, isPublic }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(!isPublic);

  const handleChange = () => {
    onChange(checked);
    setChecked(!checked);
  };

  return (
    <Box className={classes.wrapper} onClick={handleChange}>
      <Typography className={classes.public}>{ProfilePageStrings.Public}</Typography>
      <Typography className={classes.private}>{ProfilePageStrings.Private}</Typography>
      <Box className={`${classes.thumbn} ${checked ? classes.checked : null}`}></Box>
      {/*  <Switch
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        checked={checked}
        onChange={handleChange}
      /> */}
    </Box>
  );
};
export default CustomSwitch;
