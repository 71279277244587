import styled from 'styled-components';
import { Theme } from '@mui/material';
import { theme } from '../../../theme';

export const TermsOfService = styled.div<{ theme: Theme; isChecked?: null | boolean }>`
  margin-bottom: 0px;
  font-size: 11px;
  display: flex;
  // align-items: center;
  justify-content: center;
  // margin-left: auto;
  // margin-right: auto;

  ,
  span {
    font-weight: 400;
    text-align: center;
    line-height: 162%;
    letter-spacing: 0.01em;
  }

  button {
    color: ${(props) => (props.isChecked === false ? '#FC4741' : '#fff')} !important;
    margin-left: 4px;
    margin-right: 4px;
    cursor: 'pointer';
  }

  // @media (max-width: ${theme.breakpoints.values.md}px) {
  //   width: 100%;
  // }
`;
