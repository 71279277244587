import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';

import { AppRoutes, autenticatedRoutes } from '../../constants/routes';
import { TermsOfService } from './TermsOfServiceLink.style';

import { TermsOfServiceStrings } from '../../localization/en';
import { useThemeLayout } from '../../hooks/useThemeLayout';

interface TermsOfServiceLinkProps {
  handleClick?: () => void;
  pretext?: boolean;
  isChecked?: boolean | null;
}

export const TermsOfServiceLink: FC<TermsOfServiceLinkProps> = ({ isChecked, handleClick, pretext }) => {
  const theme = useTheme();
  const { isThemeLayoutEnabled, themeTextColor } = useThemeLayout();

  if (typeof handleClick !== 'undefined') {
    return (
      <TermsOfService isChecked={isChecked} theme={theme}>
        <span
          style={{
            color: isThemeLayoutEnabled ? themeTextColor : '#fff',
          }}
        >
          {pretext && 'By proceeding, '}
          {TermsOfServiceStrings.Text}
          {/* <button type="button" onClick={handleClick}>
            {t('TermsOfServiceStrings.TermsOfService')}
          </button>
          &
          <button type="button" onClick={handleClick}>
            {t('TermsOfServiceStrings.PrivacyPolicy')}
          </button> */}
          <a
            href={autenticatedRoutes.PrivacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: isThemeLayoutEnabled ? themeTextColor : '#fff',
              textDecoration: 'underline',
              textAlign: 'center',
              fontWeight: isThemeLayoutEnabled ? 600 : 400,
            }}
            onClick={handleClick}
          >
            {TermsOfServiceStrings.TermsOfService}{' '}
            <span style={{ textDecoration: 'none', marginLeft: '4px', marginRight: '4px' }}>&</span>
            {TermsOfServiceStrings.PrivacyPolicy}
          </a>
        </span>
      </TermsOfService>
    );
  }

  return (
    <TermsOfService theme={theme}>
      <span>
        <Link target="_blank" to={AppRoutes.PrivacyPolicy}>
          {TermsOfServiceStrings.Link}
        </Link>
      </span>
    </TermsOfService>
  );
};
