import { FC } from 'react';

export const AvatarEditorIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <circle cx="10" cy="10" r="10" fill="#fff"></circle>
      <path fill="#416DFD" d="M10 11.6a1.6 1.6 0 100-3.2 1.6 1.6 0 000 3.2z"></path>
      <path
        fill="#416DFD"
        d="M8.5 5l-.915 1H6c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h8c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1h-1.585L11.5 5h-3zm1.5 7.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"
      ></path>
    </svg>
  );
};
