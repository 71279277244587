import { createSignedUploadStorageUrl, uploadFile } from '../../../../services/utilities';

export const uploadFileHandler = async (photoUrl: string, accountId: string): Promise<string | undefined> => {
  const photoResponse = await fetch(photoUrl);
  const blobPhoto = await photoResponse.blob();
  const avatarAsFile = new File([blobPhoto], `avatar.png`, { type: 'image/png' });
  const response = await createSignedUploadStorageUrl('image/png', accountId);
  if (response) {
    await uploadFile({ options: response, data: avatarAsFile });
    return response.downloadUrl;
  }
};
