import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    maxWidth: '540px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  tabItem: {
    fontWeight: 400,
    fontSize: 'clamp(14px, 3.9vw, 17px)',
    width: '100%',
  },
}));
