import React, { FC, useState, useCallback } from 'react';
import { Button, Box } from '@mui/material';
import Cropper from 'react-easy-crop';

import { Modal } from '../../../Modal/Modal';
import { SmallSquareButton, SmallSquareButtonType } from '../../../SmallSquareButton/SmallSquareButton';
import { getCroppedImg, PixelCropValues, useStyles } from './ModalImageCrop.helper';

import { SharePageStrings } from '../../../../localization/en';

interface ModalImageCropProps {
  onSave: (croppedImg: string) => void;
  onClose: () => void;
  isModal: boolean;
  image: string;
}

export const ModalImageCrop: FC<ModalImageCropProps> = ({ onSave, isModal, onClose, image }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<PixelCropValues | null>(null);

  const classes = useStyles();

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onSaveHandler = useCallback(async () => {
    if (croppedAreaPixels) {
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels);
        onSave(croppedImage);
      } catch (e) {
        console.error(e);
      }
    }
  }, [image, croppedAreaPixels]);

  const onCloseHandler = useCallback(() => {
    onClose();
  }, []);

  return (
    <div>
      {isModal && (
        <Modal onClose={onCloseHandler} isCloseButton={false}>
          <Box position="relative" width="300px" height="auto" padding="12px" bgcolor="#1C1D22" borderRadius="10px">
            <Box position="absolute" top="10px" right="10px" zIndex={1}>
              <SmallSquareButton buttonType={SmallSquareButtonType.Close} onButtonClick={onClose} />
            </Box>

            <Box width="100%" height="300px" position="relative" mt={4}>
              <Cropper
                image={image}
                crop={crop}
                zoom={1.2}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                cropShape="round"
                objectFit="vertical-cover"
              />
            </Box>

            <Box marginTop={2}>
              <Button onClick={onSaveHandler} fullWidth size="large" variant="contained" className={classes.button}>
                {SharePageStrings.Save}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};
