export const SliderNextArrow = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7275 10.5563H0.173828V7.44227H12.7275L7.91282 2.31522L9.98061 0.113281L18.3252 8.99927L9.98061 17.8853L7.91282 15.6833L12.7275 10.5563V10.5563Z"
        fill="#8B89A0"
      />
    </svg>
  );
};
