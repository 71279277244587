import { Box, IconButton } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';

import { trackEvent } from '../../../../utils/analytics/analytics';
import { StoryDetails } from '../../../api/models/auth';
import { useTypedSelector } from '../../../store/store';
import { EventNames } from '../../constants/constants';
import { PlayVideoIcon } from './../../assets/playerIcons/PlayIcon';
import { useStyles } from './AllVideosComponent.helper';

interface AllVideosComponentProps {
  url: string;
  thumbnailUrl?: string;
  muted?: boolean;
  autoPlay?: boolean;
  isPlayButton?: boolean;
  isPause?: boolean;
  storyDetails?: StoryDetails;
  setCurrentVideoId: (id: string) => void;
  videoId: string;
  currentVideoId: string;
  duration: number;
  onClick: () => void;
}

export const AllVideosComponent: FC<AllVideosComponentProps> = (props) => {
  const { thumbnailUrl, isPlayButton = false, duration, onClick } = props;
  const refVideo = useRef<HTMLVideoElement | null>(null);
  const [isVideoPlayButtonState, setIsVideoPlayButtonState] = useState(true);
  const { account } = useTypedSelector((state) => state.account);
  const { id, email, name } = useTypedSelector((state) => state.me);

  useEffect(() => {
    if (props.currentVideoId !== props.videoId) {
      refVideo.current?.pause();
      setIsVideoPlayButtonState(true);
    }
  }, [props.currentVideoId]);

  const styles = useStyles({ thumbnailUrl: thumbnailUrl || '' });

  const formatedDuration = (durationInSeconds: number) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;

    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = Math.round(seconds).toString().padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleClick = () => {
    trackEvent({
      action: EventNames.MyVideosPagePlayVideoClick,
      accountId: account.id,
      userId: id,
      email: email,
      name,
    });
    onClick();
  };

  return (
    <Box onClick={handleClick} className={styles.videoBlockWrapper}>
      <Box className={styles.videoDuration}>{formatedDuration(duration)}</Box>
      <Box className={styles.videoBlockOverlay}>
        <Box className={styles.videoBlockOverlayInner}>
          {
            // eslint-disable-next-line @next/next/no-img-element
            <img className={styles.videoOverlayImg} src={thumbnailUrl} alt="" />
          }
        </Box>
      </Box>
      <Box className={styles.videoWrapper}>
        {/*  <VideoElement
          style={{ objectFit: 'contain' }}
          ref={refVideo}
          onClick={handleTap}
          preload="auto"
          poster={thumbnailUrl || ''}
          src={url}
          muted={muted}
          autoPlay={autoPlay}
          playsInline
          loop
        /> */}
        {
          // eslint-disable-next-line @next/next/no-img-element
          <img style={{ width: '135px', height: '240px', margin: '0 auto' }} src={thumbnailUrl} alt="" />
        }
        {isVideoPlayButtonState && isPlayButton && (
          <IconButton
            className={[styles.icons, styles.buttonPlayPause].join(' ')}
            size="small"
            sx={{
              opacity: !isVideoPlayButtonState ? 0 : 1,
            }}
            /*  onClick={onPlayVideoHandler} */
          >
            <PlayVideoIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
