import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { VideoAppTheme } from '../../../api/models/venue';

interface Props {
  isThemeLayoutEnabled?: boolean;
  layoutTheme?: VideoAppTheme;
}

export const useStyles = makeStyles<Theme, Props>(() => ({
  container: {
    margin: '0 auto',
    width: '100%',
    minHeight: 530,
    marginTop: '16px',
    position: 'relative',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingRight: '8px',
  },
  progressText: ({ isThemeLayoutEnabled, layoutTheme }) => ({
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '28px',
    marginBottom: '10px',
    color: isThemeLayoutEnabled && layoutTheme === VideoAppTheme.Light ? '#475467' : '#fff',
  }),
  blockWrapper: {
    marginBottom: '20px',
  },
}));
