import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { defaultPagination } from '../../../common/constants/constants';
import { SortingStrings } from '../../../common/localization/en';
import { ISortingParams } from './Videos';

export const useStyles = makeStyles<Theme, { url: string | null }>((theme: Theme) => ({
  button: {
    height: 58,
    backgroundColor: theme.palette.primary.main,
  },
  videosTitle: {
    color: theme.palette.primary.main,
    marginBottom: '12px',
    fontSize: 16,
    fontWeight: 600,
  },
  btnCreateVideo: {
    borderRadius: '12px',
    padding: 12,
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: 16,
    height: '56px',
    color: theme.palette.common.white,
  },
  noContentContainer: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1,
    width: 336,
    minHeight: 530,
    marginTop: '16px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.46) 35.94%, rgba(0, 0, 0, 0.46) 100%)',
    backdropFilter: 'blur(3.5px)',
    borderRadius: '12px',
  },
  iconWrapper: {
    opacity: 0.7,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '75px',
    height: '75px',
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  isNotVideos: {
    marginTop: '20px',
    fontWeight: 600,
    lineHeight: '28px',
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  isNotVideoSubtitle: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '162%',
    color: theme.palette.common.white,
    marginTop: 14,
  },
  isNotVideosImage: {
    margin: '16px auto',
    width: '245px',
    height: '275px',
    position: 'relative',
  },
  tab: {
    minHeight: 30,
    marginBottom: 24,
    border: '1px solid #121527',
    borderRadius: 8,
  },
  tabItem: {
    minHeight: 30,
    opacity: 1,
    fontSize: 12,
    color: theme.palette.common.white,
    textTransform: 'capitalize',

    '&$selected': {
      backgroundColor: '#121527',
      color: theme.palette.common.white,
      borderRadius: 7,
    },
  },
  selected: {},
  indicator: {
    display: 'none',
  },
  imageWrapper: {
    zIndex: 0,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      '& img': {
        objectPosition: 'center',
      },
    },
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      flexGrow: 1,
      '& img': {
        objectPosition: 'top',
        objectFit: 'cover',
      },
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      // backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  imageBackground: {
    width: '100% !important',
    // maxWidth: '670px !important',
    minWidth: 'auto !important',
  },
  listWrapper: {
    backgroundRepeat: 'repeat',
    backgroundSize: 'contain',
    width: '100% !important',
    minWidth: 'auto !important',
  },
}));

export const getSortingForRequest = (params: ISortingParams) => {
  let order = defaultPagination.sortByLastCreated;
  switch (params.criteria) {
    case SortingStrings.DateAdded:
      order = 'createdAt,' + (params.descending ? 'desc' : 'asc');
      return order;
    case SortingStrings.Points:
      order = 'points,' + (params.descending ? 'desc' : 'asc');
      return order;
    case SortingStrings.Shares:
      order = 'shares,' + (params.descending ? 'desc' : 'asc');
      return order;
    // case SortingStrings.Title:
    //   order = 'details.metaTitle,' + (params.descending ? 'desc' : 'asc');
    //   return order;
    default:
      return order;
  }
};
