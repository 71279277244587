import { Theme } from '@mui/material';

import styled from 'styled-components';
import { theme } from '../../../../theme';

export const Container = styled.div`
  position: relative;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ShareButton = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin: 0 16px;
  color: ${theme.palette.secondary.contrastText};
  background: ${theme.palette.primary.main};
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
`;

export const WrapperPlayButton = styled.div`
  position: absolute;
  pointer-events: none;

  svg {
    font-size: 3.7rem;
  }
`;

export const SkeletonWrapper = styled.div`
  > span {
    display: flex;
    flex-direction: column;

    > span {
      margin-right: 10px;
    }
  }
`;
export const CustomGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
`;

export const PerformanceList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 10px;
  width: 100%;
  padding: 16px;
`;
export const PerformanceBlock = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;
export const PerformanceItem = styled.div<{ backgroundColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 50%;
  padding: 8px;
  border-radius: 8px;
  font-size: 12px;
  background-color: ${(props) => props.backgroundColor || 'rgb(33, 33, 33, 1)'};
  & > * {
    margin-right: 5px;
  }
  & > p {
    display: inline-block;
    margin-right: 6px;
  }
  & > span {
    font-size: 12px;
  }
`;

export const MediaItemWrapper = styled.div`
  position: relative;
`;

export const MediaItem = styled.div<{ isThemeLayoutEnabled?: boolean }>`
  margin: auto;
  position: relative;
  border-radius: 16px;
  border-radius: 16px;
  width: 332px;
  z-index: 1;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.11);
`;

interface MediaWrapperProps {
  backgroundImage?: string;
}

export const MediaWrapper = styled.div<MediaWrapperProps & { theme: Theme }>`
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;
