import { MutableRefObject, useEffect, useState } from 'react';

interface PlayerState {
  currentTime: number;
  isPlaying: boolean;
  isMuted: boolean;
  isLoading: boolean;
}
export const useVideoControl = (videoRef: MutableRefObject<HTMLVideoElement | null>) => {
  const [playerState, setPlayerState] = useState<PlayerState>({
    currentTime: videoRef.current ? videoRef.current.currentTime : 0,
    isPlaying: false,
    isMuted: false,
    isLoading: true,
  });

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const callback = () => {
      setPlayerState((currentState) => ({ ...currentState, isLoading: false }));
    };

    // can't use "canplay" because of safari:
    // https://stackoverflow.com/a/50102809
    video.addEventListener('loadedmetadata', callback);
    return () => {
      video.removeEventListener('loadedmetadata', callback);
    };
  }, [videoRef]);

  const play = () => {
    if (!videoRef.current) return;

    videoRef.current.play().then(() => {
      setPlayerState((currentState) => ({ ...currentState, isPlaying: true }));
    });
  };

  const pause = () => {
    if (!videoRef.current) return;

    videoRef.current.pause();
    setPlayerState((currentState) => ({ ...currentState, isPlaying: false }));
  };

  const togglePlay = () => {
    if (!videoRef.current) return;

    if (videoRef.current.paused) {
      play();
    } else {
      pause();
    }
  };

  const toggleMute = () => {
    if (!videoRef.current) return;

    const isMuted = videoRef.current.muted;

    videoRef.current.muted = !isMuted;
    setPlayerState((currentState) => ({ ...currentState, isMuted: !isMuted }));
  };

  const forwardVideo = () => {
    if (!videoRef.current) return;
    const nextTime = videoRef.current?.currentTime + 15.0;
    videoRef.current!.currentTime = nextTime;
  };

  const backwardVideo = () => {
    if (!videoRef.current) return;
    const nextTime = videoRef.current.currentTime - 15.0;
    videoRef.current!.currentTime = nextTime;
  };

  const handleOnEnded = () => {
    setPlayerState((currentState) => ({ ...currentState, isPlaying: false }));
  };

  return {
    play,
    pause,
    forwardVideo,
    backwardVideo,
    togglePlay,
    playerState,
    setPlayerState,
    toggleMute,
    handleOnEnded,
  };
};
