import React, { FC } from 'react';
import { Box } from '@mui/material';

import { Title } from '../../common/components/Title/Title';
import { DesktopPageContainer, DesktopPageContent, PrivacyPolicyTextStyle } from './PrivacyPolicyPage.style';
import { AppRoutes } from '../../common/constants/routes';
import { useTypedSelector } from '../../store/store';
import { useWindowResize } from '../../services/hooks/useWindowResize';
import { defaultPageSizing, LayoutMode } from '../../common/constants/constants';
import { TermsOfServiceStrings } from '../../common/localization/en';
import { trackPageView } from '../../../utils/analytics/analytics';

export const PrivacyPolicyPage: FC = () => {
  const { id, email, name } = useTypedSelector((state) => state.me);
  const accountId = useTypedSelector((state) => state.account.account.id);
  const device = useWindowResize();

  let width: string;
  switch (device) {
    case LayoutMode.Desktop: {
      width = defaultPageSizing.desktopContentWidth;
      break;
    }
    case LayoutMode.Tablet: {
      width = defaultPageSizing.tabletPageWidth;
      break;
    }
    case LayoutMode.Mobile: {
      width = defaultPageSizing.mobilePageWidth;
      break;
    }
    default: {
      width = defaultPageSizing.mobilePageWidth;
    }
  }

  React.useEffect(() => {
    trackPageView({
      url: AppRoutes.PrivacyPolicy,
      accountId,
      userId: id,
      email,
    });
  }, [email, id, name, accountId]);

  const content = (
    <PrivacyPolicyTextStyle maxWidth={width}>
      {device !== LayoutMode.Desktop && (
        <Title
          firstText={TermsOfServiceStrings.TitleFirstText}
          secondaryText={TermsOfServiceStrings.TitleSecondText}
          noPadding
        />
      )}
      <Box mt={2} />
      <strong>
        SocialVenu, Inc.
        <br />
        <br />
        Terms of Use
      </strong>
      &nbsp;
      <br />‍<br />
      Welcome to SocialVenu! <br />
      <br />
      These Terms of Use (“Terms”) govern your use of SocialVenu and all of its products and services (collectively,
      “SocialVenu,” “SV,” “we,”“us,” or “our.”). When you create a SV account or use SV, you agree to these terms. These
      Terms of Use therefore constitute an agreement between you SocialVenu, Inc., and our partners and affiliates.{' '}
      <br />
      <br />
      PLEASE READ THIS POLICY. <br />
      <br />
      If you do not agree with any of these terms, do not use any part of SV’s services or products. You should
      additionally review the SocialVenu Privacy Policy at (https://www.socialvenu.com/terms-of-use-privacy-policy)
      <br />
      To make these Terms of Use easier to read, our website located atwww.socialvenu.com (the “Site”) and any web
      applications made available by us(each an “App”), and any of our services, products, or information provided
      thereby, are collectively called the “Service.”
      <br />
      Our Service requires that we collect some information about you. By using ourService, including, without
      limitation, by accessing, sharing, uploading, using, or tagging yourself in any SocialVenu digital content, you
      agree to share digital content and other information about yourself. This may include your image, any other images
      or content you upload, and information about your attendance at SV partner events like a concert, sporting event,
      rally, or gathering of any kind (“Event”).
      <br />‍<br />
      <strong>
        IMPORTANT NOTICE REGARDING ARBITRATION: WHEN YOU AGREE TO THESE TERMS OFUSE, YOU ARE AGREEING (WITH LIMITED
        EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND SOCIALVENU THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN
        IN COURT. THIS INCLUDES AN AGREEMENT YOU WILL NOT PARTICIPATE IN ANY CLASS ACTION OR OTHER REPRESENTATIVE ACTION.
        PLEASE REVIEW CAREFULLY THE SECTION BELOW TITLED “DISPUTE RESOLUTION” FOR DETAILS REGARDING ARBITRATION,
        INCLUDING THE PROCEDURE TO OPT OUT OF ARBITRATION.
        <br />‍
      </strong>
      <br />
      <strong>Introduction.</strong>&nbsp;While these Terms of Use contain the terms of use applicable to your general
      use of the Service, these terms do not contain all applicable terms, conditions, limitations or exclusions related
      to our products or services. When applicable, you will be prompted by us to agree to additional terms and
      conditions.
      <br />
      <br />
      <strong>Change, Suspension or Termination.</strong> We reserve the right, at our sole discretion, to change,
      suspend, or terminate your use of the Service at any time for any reason(including, without limitation, if we learn
      that you have violated these Terms of Use or if you are under age 13) without notice to you. You agree that we
      shall not be liable to you or any third party for any change, suspension, or termination of your use of the
      Service. You may change, suspend or terminate your use ofService at any time for any reason without notice to us.
      <br />
      <br />
      <strong>Basic Terms.</strong>
      <br />
      The following outlines the basic terms regarding your use of the Service:
      <br />
      1.&nbsp;&nbsp;&nbsp;&nbsp;You must be at least 13 years old to use the Service.
      <br />
      2.&nbsp;&nbsp;&nbsp;&nbsp;You must not upload any content you do not own or otherwise have the right or license to
      upload to SV platform. <br />
      3.&nbsp;&nbsp;&nbsp;&nbsp;You must not access or use the Service for any commercial purpose.
      <br />
      4.&nbsp;&nbsp;&nbsp;&nbsp;You must not use the Service in any manner that violates or infringes on someone else’s
      intellectual property rights, or their rights of publicity or privacy.
      <br />
      5.&nbsp;&nbsp;&nbsp;&nbsp;You must not use the Service for any unlawful purpose or for the promotion of
      <br />
      defamatory, hateful, misleading, false, threatening, pornographic, sexually suggestive, violence inciting, immoral,
      or inappropriate activities.
      <br />
      6.&nbsp;&nbsp;&nbsp;&nbsp;You must not use the Service to bully, intimidate, harass, or disparage any person,
      product, client, partner, sponsor, or entity.
      <br />
      7.&nbsp;&nbsp;&nbsp;&nbsp;You are responsible for any activity that occurs through your account, and you agree you
      will not sell, transfer, license, or assign your account, followers, username, or any other account rights.
      <br />
      8.&nbsp;&nbsp;&nbsp;&nbsp;You must not create an account for anyone other than yourself.
      <br />
      9.&nbsp;&nbsp;&nbsp;&nbsp;You must not solicit, collect or use the login credentials of others without permission.
      <br />
      10.&nbsp; You represent that all information you provide or provided to us is accurate, current, and complete, and
      you agree to update your information as necessary to maintain its truth and accuracy.11.&nbsp; You must not
      interfere with the proper functioning of the Service.
      <br />
      12.&nbsp; You must not make any automated use of the Service system, or take any action that we deem to impose or
      to potentially impose an unreasonable or disproportionately large load on our servers or network infrastructure.
      <br />
      13.&nbsp; You must not interfere or disrupt the Service or servers or networks connected to theService, including
      by transmitting any worms, viruses, spyware, malware or any other code of a destructive or disruptive nature.
      <br />
      14.&nbsp; You may not inject content or code or otherwise alter or interfere with the way our page is rendered or
      displayed in a user’s browser or device.
      <br />
      15.&nbsp; You must not use domain names or web URLs in your username without prior written consent from us.
      <br />
      16.&nbsp; We reserve the right to refuse access to the Service to anyone for any reason or for no reason, and with
      or without notice.
      <br />
      17.&nbsp; We reserve the right to force forfeiture of any username for any reason.
      <br />
      18.&nbsp; You are solely responsible for your interaction with other users of the Service, whether online or
      offline. You agree that we are not responsible or liable for the conduct of any user. We reserve the right, but
      have no obligation, to monitor or become involved in disputes between you and other users.19.&nbsp; If you attend
      anEvent where the Service is being provided:
      <br />
      a.&nbsp;&nbsp;&nbsp;&nbsp;You acknowledge and agree any rights you have granted to the venue at which such Event is
      being held regarding the taking and use of your digital content or likeness shall also benefit us to the fullest
      extent possible. The foregoing shall in no way limit the rights you are granting to us in these Terms of Use.
      <br />
      b.&nbsp;&nbsp;&nbsp;&nbsp;You must not use the Service to obtain digital content of people other than yourself at
      the Event other than those people who happen to be in your vicinity at the time the SocialVenu digital content is
      taken and would reasonably be included in the digital content that includes you.
      <br />
      c.&nbsp;&nbsp;&nbsp;&nbsp;You must not alter any SV content you obtain from or through the Service. <br />‍<br />
      <strong>
        Definitions.
        <br />‍
      </strong>
      For purposes of these Terms: (i) “Content” means text, graphics, images, music, software, audio, video, works of
      authorship of any kind, and information or other materials that are posted, generated, provided, or otherwise made
      available through the Service; and (ii) “User Content” means any Content that account holders (including you)
      provide, upload, or otherwise make available through the Service. Content includes all User Content.
      <br />‍<br />
      <strong>Posting and Conduct Restrictions.</strong>
      <br />
      You are solely responsible for the User Content that make available on or through the Service. &nbsp;We, however,
      reserve the right to remove any UserContent from the Service at our discretion. The following rules pertain to
      UserContent. By transmitting and submitting any User Content while using theService, you agree as follows:
      <br />
      <br />
      You understand and agree that any liability, loss, or damage that occurs as a result of any User Content you make
      available or access through your use of theService is solely your responsibility. We are not responsible for any
      public display or misuse of your User Content. SocialVenu does not, and cannot, pre-screen or monitor all User
      Content. However, at our discretion, we, or technology we employ, may monitor your interactions with the Service.
      <br />
      <br />
      <strong>Online Content Disclaimer.</strong>
      <br />
      Opinions, advice, statements, offers or other information or content made available through the Service, but not
      directly made available by us, are those of their respective authors, and should not necessarily be relied upon.
      Such authors are solely responsible for such content. We do not guarantee the accuracy, completeness, or usefulness
      of any information on the Service and neither do we adopt nor endorse, nor are we responsible for, the accuracy or
      reliability of any opinion, advice, or statement made by third parties. We take no responsibility and assume no
      liability for any User Content that you or any other user or third-party posts or sends over the Service. Under no
      circumstances will we be responsible for any loss or damage resulting from anyone’s reliance on information or
      other content posted on the Service, or transmitted to users.
      <br />
      <br />
      Though we strive to enforce these Terms of Use, you may be exposed to content that is inaccurate or objectionable.
      We reserve the right, but have no obligation, to monitor the materials posted in the public areas of the Service or
      to limit or deny a user’s access to the Service or take other appropriate action if a user violates these Terms of
      Use or engages in any activity that violates the rights of any person or entity or which we deem unlawful,
      offensive, abusive, harmful or malicious. We shall have the right to remove any such material that in our sole
      opinion violates, or is alleged to violate, any applicable law or these Terms of Use or which might be offensive,
      or that might violate the rights, harm, or threaten the safety of users or others. Unauthorized use may result in
      criminal and/or civil prosecution under federal, state and local law. If you become aware of misuse of our Service,
      please contact us immediately at&nbsp;support@socialvenu.com.
      <br />
      <br />
      <strong>Proprietary Rights.</strong>
      <br />
      The Content provided on and through the Service is licensed to or copyrighted by us and/or our partners and
      affiliates. All rights are reserved. We retain and exclusively own all right, title and interest in and to our
      trademarks, copyrights or other intellectual property rights in and to the Content or otherwise contained in the
      Service. You acknowledge that the Service andContent are protected by copyright, trademark, and other laws of the
      UnitedStates and foreign countries. You agree not to remove, alter or obscure any copyright, trademark, service
      mark or other proprietary rights notices incorporated in or accompanying the Service or Content. Other than the
      intended use of a SocialVenu digital content that contains you (i.e., for sharing via social media, downloading,
      printing or other similar personal uses), no part of the Content may be reproduced or transmitted in any form by
      any means without our express written permission. Without limiting the foregoing, you shall not:(1) direct link,
      inline link, deep link, or otherwise hyperlink any page of theService other than the home page of the
      www.socialvenu.com;&nbsp;www.myfanstory.com; www.amplfi.com or (2) page-scrape, robot, spider, hack, password mine
      or use any similar automatic or manual program or process to use the Service.
      <br />
      <br />
      <strong>Links to Other Sites and/or Materials.</strong>
      <br />
      As part of the Service, we may provide you with links to third-party website(s)(“Third Party Sites”) as well as
      content or items belonging to or originating from third parties (the “Third Party Applications, Software or
      Content”). These links are provided as a courtesy to Service users. We have no control overThird Party Sites and
      Third Party Applications, Software, or Content posted on, available through or installed from the Service,
      including the content, accuracy, offensiveness, opinions, reliability, privacy practices or other policies of or
      contained in the Third Party Sites or the Third PartyApplications, Software or Content. Inclusion of, linking to or
      permitting the use or installation of any Third Party Site or any Third Party Applications,Software or Content does
      not imply approval or endorsement thereof by us. Ify ou decide to leave the Service and access the Third Party
      Sites or to use or install any Third Party Applications, Software or Content, you do so at your own risk and you
      should be aware that our terms and policies no longer govern such separate systems. You should review the
      applicable terms and policies, including privacy and data gathering practices, of any site to which you navigate
      from the Service or relating to any applications you use or install from the site.
      <strong>&nbsp;Partnership Relationships and Terms of Use.</strong>As part of the Service, we may partner with
      business customers, affiliates, partners, customers, and other third parties(collectively “Partners”). Those
      Partners may have their own terms of use, privacy policies, or other policies regarding promotions, including
      sweepstakes, contests, and giveaways. Those policies are found on the partner links at the top of this page.
      <br />‍<br />
      <strong>Rights Granted by You.</strong>
      <br />
      By attending any Event at which the Service is available, or by accessing or posting any User Content via the
      Service, you expressly grant, and you represent and warrant that you have a right to grant, to SocialVenu a
      royalty-free, sublicensable, transferable, perpetual, irrevocable, non-exclusive, worldwide license to use,
      reproduce, modify, publish, list information regarding, edit, translate, distribute, publicly perform, publicly
      display, and make derivative works of any SocialVenu digital content with you in it, all such User Content and your
      name, voice, and/or digital content/likeness as contained in such SocialVenu digital content and &nbsp;yourUser
      Content, if applicable, in whole or in part, and in any form, media or technology, whether now known or hereafter
      developed, for use in connection with the Service.
      <br />
      <br />
      Some of the Service is supported by advertising revenue and may display advertisements and promotions, and you
      hereby agree that we may place such advertising and promotions on the Service (including the SocialVenu digital
      content itself) or on, about, or in conjunction with your User Content. The manner, mode and extent of such
      advertising and promotions are subject to change without specific notice to you. You acknowledge that we may not
      always identify paid services, sponsored content, or commercial communications as such. <br />
      <strong>
        <br />
        Rights and Terms for Apps.
        <br />‍
      </strong>
      Rights in App Granted by SocialVenu.<strong>&nbsp;</strong>Subject to your compliance with these Terms of Use,
      SocialVenu grants to you a limited non-exclusive, non-transferable license, with no right to sublicense, to
      download and install a copy of the App on a mobile device or computer that you own or control and to run such copy
      of the App solely for your own personal non-commercial purposes. You may not copy the App, except for making a
      reasonable number of copies for backup or archival purposes. Except as expressly permitted in these Terms of Use,
      you may not: (i) copy, modify or create derivative works based on the App; (ii) distribute, transfer, sublicense,
      lease, lend or rent the App to any third party; (iii) reverse engineer, decompile or disassemble the App; or (iv)
      make the functionality of the App available to multiple users through any means. SocialVenu reserves all rights in
      and to the App not expressly granted to you under these Terms of Use.Accessing App from App Store
      <strong>.</strong>&nbsp;The following applies to any App accessed through or downloaded from any app store or
      distribution platform(like the Apple App Store or Google Play) where the App may now or in the future be made
      available (each an “<strong>App Provider</strong>”). You acknowledge and agree that:
      <br />
      <br />
      1.These Terms of Use are concluded between you and SocialVenu, and not with theApp Provider, and SocialVenu (not
      the App Provider), is solely responsible for the App.
      <br />
      2. The App Provider has no obligation to furnish any maintenance and support services with respect to the App.
      <br />
      3. In the event of any failure of the App to conform to any applicable warranty, you may notify the App Provider,
      and the App Provider will refund the purchase price for the App to you (if applicable) and, to the maximum extent
      permitted by applicable law, the App Provider will have no other warranty obligation whatsoever with respect to the
      App. As between the App Provider and SocialVenu, any other claims, losses, liabilities, damages, costs or expenses
      attributable to any failure to conform to any warranty will be the sole responsibility of SocialVenu.
      <br />
      4. The App Provider is not responsible for addressing any claims you have or any claims of any third party relating
      to the App or your possession and use of the App, including, but not limited to: (i) product liability claims; (ii)
      any claim that the App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising
      under consumer protection, privacy, or similar legislation.
      <br />
      5. In the event of any third-party claim that the App or your possession and use of that App infringes that third
      party’s intellectual property rights, SocialVenu will be solely responsible for the investigation, defense,
      settlement and discharge of any such intellectual property infringement claim to the extent required by these Terms
      of Use.
      <br />
      6. The App Provider, and its subsidiaries, are third-party beneficiaries of these Terms of Use as related to your
      license to the App, and that, upon your acceptance of the Terms of Use, the App Provider will have the right (and
      will be deemed to have accepted the right) to enforce these Terms of Use as related to your license of the App
      against you as a third-party beneficiary thereof.
      <br />
      7. You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government
      embargo, or that has been designated by the U.S.Government as a terrorist-supporting country; and (ii) you are not
      listed on any U.S. Government list of prohibited or restricted parties.
      <br />
      8. You must also comply with all applicable third-party terms of service when using the App.
      <br />
      <strong>
        <br />
        DMCA/Copyright Policy.
      </strong>
      <br />
      SocialVenu respects copyright law and expects its users to do the same. It is SocialVenu’s policy to terminate in
      appropriate circumstances account holders who repeatedly infringe or are believed to be repeatedly infringing the
      rights of copyright holders.
      <br />
      <strong>Notice.</strong>&nbsp;Any notices or other communications provided by SocialVenu under these Terms of Use,
      including those regarding modifications to theseTerms of Use, will be given: (i) via email; or (ii) by posting to
      the Service.For notices made by e-mail, the date of receipt will be deemed the date on which such notice is
      transmitted. Communication made through the Service’s e-mail and messaging system, will not constitute legal notice
      to us or any of our officers, employees, agents or representatives in any situation where notice to us is required
      by contract or any law or regulation.
      <br />
      <br />
      <strong>User Consent to Receive Communications in Electric Form.</strong>
      <br />
      For contractual purposes, you consent to receive communication from us in an electronic form via the email address
      you have submitted. We may also use your email address to send you other messages, including information about us
      and special offers. You may opt out of such email by changing your account settings or sending an email
      to&nbsp;support@socialvenu.com.All SocialVenu partners, affiliates, customers, and paid advertisers may contact you
      in any manner in which SocialVenu may contact you under theseTerms. <br />
      <br />
      <strong>Disclaimers.</strong>&nbsp;USE OF THE SERVICE IS AT YOUR SOLE RISK. WE DO NOT WARRANT THE ACCURACY,
      COMPLETENESS OR THE RELIABILITY OF THE SERVICE. YOU ACKNOWLEDGE THAT ANY RELIANCE ON THE SERVICE SHALL BE AT YOUR
      SOLE RISK. THE SERVICE IS PROVIDED “AS AVAILABLE” AND “AS IS” WITHOUT ANY REPRESENTATION OR WARRANTY,EXPRESS OR
      IMPLIED, OF ANY KIND (INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OR FITNESS FOR
      ANY PARTICULAR PURPOSE). SOMEJURISDICTIONS DO NOT ALLOW FOR THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE
      EXCLUSIONS MAY NOT APPLY TO YOU. OTHERWISE, THIS PARAGRAPH IS INTENDED TOBE APPLICABLE TO THE MAXIMUM EXTENT
      ALLOWED BY LAW.
      <br />
      <br />
      <strong>Limitation of Liabilities.</strong>&nbsp;SOCIALVENU WILL NOT BE LIABLE FOR ANY INCIDENTAL, SPECIAL,
      EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS
      OPPORTUNITY, LOSS OF DATAOR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF
      SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF USE OR FROM THE USE OF OR
      INABILITY TO USE THE SERVICE OR CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),PRODUCT
      LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT SOCIALVENU&nbsp;HAS BEEN INFORMED OF THE POSSIBILITY OF
      SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME
      JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE
      ABOVE LIMITATION MAY NOT APPLY TOYOU.
      <br />
      <br />
      IN NO EVENT WILL SOCIALVENU’S TOTAL LIABILITY ARISING OUT OFOR IN CONNECTION WITH THESE TERMS OF USE OR FROM THE
      USE OF OR INABILITY TO USETHE SERVICE OR CONTENT EXCEED THE AMOUNTS YOU HAVE PAID TO SOCIALVENU &nbsp;FORUSE OF THE
      SERVICE OR CONTENT OR ONE HUNDRED DOLLARS ($100), IF YOU HAVE NOTHAD ANY PAYMENT OBLIGATIONS TO SOCIALVENU, AS
      APPLICABLE.
      <br />
      <br />
      THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN
      BETWEEN SOCIALVENU ANDYOU.
      <br />
      <br />
      <strong>
        Governing Law and Forum Choice.
        <br />‍
      </strong>
      These Terms of Use and any action related thereto will be governed by theFederal Arbitration Act, federal
      arbitration law, and the laws of the State ofCalifornia, without regard to its conflict of laws provisions. Except
      as otherwise expressly set forth in the “Dispute Resolution” Section, the exclusive jurisdiction for all Disputes
      that you and SocialVenu are not required to arbitrate will be the state and federal courts located in theNorthern
      District of California, and you and SocialVenu each waive any objection to jurisdiction and venue in such
      courts.&nbsp;<strong>Dispute Resolution.</strong>Mandatory Arbitration of Disputes. We each agree that any dispute,
      claim or controversy arising out of or relating to these Terms of Use or the breach, termination, enforcement,
      interpretation or validity thereof or the use of the Service or Content (collectively, “Disputes”) will be
      resolved&nbsp;solely by binding, individual arbitration and not in a class, representative, or consolidated action
      or proceeding. You and SocialVenu agree that the U.S. Federal Arbitration Act governs the interpretation and
      enforcement of these Terms of Use, and that you and SocialVenu are each waiving the right to a trial by jury or to
      participate in a class action. This arbitration provision shall survive termination of these Terms of Use.
      &nbsp;Exceptions and Opt-out. As limited exceptions to the“Mandatory Arbitration of Disputes” Section above: (1)
      you may seek to resolve a Dispute in small claims court if it qualifies; and (2) we each retain the right to seek
      injunctive or other equitable relief from a court to prevent (or enjoin) the infringement or misappropriation of
      our intellectual property rights. In addition,&nbsp;you&nbsp;will retain the right to opt out of arbitration
      entirely and litigate any Dispute&nbsp;if you provide us with written notice of your desire to do so&nbsp;by email
      at support@socialvenu.comor by regular mail at 6114 La Salle Ave, Suite 208, Oakland, CA 94611 within thirty (30)
      days following the date you first agree to these Terms of Use. &nbsp;Conducting Arbitration and Arbitration Rules.
      The arbitration will be conducted by the American Arbitration Association (“AAA”)under its Consumer Arbitration
      Rules (the “AAA Rules”) then in effect, except as modified by these Terms of Use. The AAA Rules are available
      at&nbsp;www.adr.org&nbsp;or by calling 1-800-778-7879. A party who wishes to start arbitration must submit a
      written Demand for Arbitration toAAA and give notice to the other party as specified in the AAA Rules. The AAA
      provides a form Demand for Arbitration at&nbsp;www.adr.org.If your claim is for U.S. $10,000 or less, you may
      choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator,
      through a telephonic or video-conference hearing, or by anin-person hearing as established by the AAA Rules.
      &nbsp;If your claim exceedsU.S. $10,000, the right to a hearing will be determined by the AAA Rules. Any
      arbitration hearings will take place in the county (or parish) where you live, unless we both agree to a different
      location. The parties agree that the arbitrator shall have exclusive authority to decide all issues relating to the
      interpretation, applicability, enforceability and scope of this arbitration agreement.&nbsp;Arbitration Costs.
      Payment of all filing, administration and arbitrator fees will be governed by the AAA Rules. We’ll pay for all
      filing, administration and arbitrator fees and expenses if your Dispute is for less than $10,000, unless the
      arbitrator finds your Dispute frivolous. If we prevail in arbitration we’ll pay all of our attorneys’ fees and
      costs and won’t seek to recover them from you. If you prevail in arbitration you will be entitled to an award of
      attorneys’ fees and expenses to the extent provided under applicable law.&nbsp;Class Action Waiver. &nbsp;
      <br />‍<br />‍
      <strong>
        YOU AND SOCIALVENU AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLYIN YOUR OR ITS INDIVIDUAL CAPACITY, AND
        NOT AS A PLAINTIFF OR CLASS MEMBER INANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
      </strong>
      &nbsp;&nbsp;Further, if the parties’ dispute is resolved through arbitration, the arbitrator may not consolidate
      another person’s claims with your claims, and may not otherwise preside over any form of a representative or class
      proceeding. &nbsp;If this specific provision is found to be unenforceable, then the entirety of this“Dispute
      Resolution” Section shall be null and void.&nbsp;Effect of Changes on Arbitration. Notwithstanding the provisions
      of&nbsp;
      <strong>”</strong>Miscellaneous” Section below, if we change any of the terms of this “Dispute Resolution” Section
      after the date you first accepted these Terms of Use (or accepted any subsequent changes to these Terms of Use),
      you may reject any such change by us written notice (including by email to support@socialvenu.com) within 30 days
      of the date such change became effective, as indicated in the “Last Updated” date or in the date of our email to
      you notifying you of such change. By rejecting any change, you are agreeing that you will arbitrate any Dispute
      between you and SocialVenu in accordance with the terms of this “Dispute Resolution” Section as of the date you
      first accepted these Terms of Use (or accepted any subsequent changes to these Terms of Use). Severability.
      &nbsp;With the exception of any of the provisions of these Terms of Use (“Class Action Waiver”), if an arbitrator
      or court of competent jurisdiction decides that any part of these Terms of Use is invalid or unenforceable, the
      other parts of these Terms of Use will still apply.&nbsp;
      <br />
      <br />‍<strong>Events Beyond Control.</strong>
      <br />
      Under no circumstances shall we be held liable for any delay or failure in theService directly or indirectly
      resulting from, arising out of, relating to orin connection with events beyond our reasonable control, including,
      without limitation,Internet failures, equipment failures, electrical power failures, strikes, labor disputes,
      riots, insurrections, civil disturbances, shortages of labor or materials, fires, floods, storms, earthquakes,
      explosions, acts of God, war, terrorism, intergalactic struggles, governmental actions, orders of courts, agencies
      or tribunals or non-performance of third parties. The provisions of this paragraph are in addition to, and not
      intended to limit or modify, the limitation of liabilities paragraph of these Terms of Use.&nbsp;
      <strong>Indemnification.</strong>&nbsp;You agree to defend, indemnify, and hold harmless SocialVenu, our
      affiliates, customers, clients, and partners and their respective directors, officers, employees, independent
      contractors, agents, and other representatives for, from, and against any losses, liabilities, suits, claims,
      costs, damages and expenses (including, without limitation, reasonable attorneys’ fees and expenses) directly or
      indirectly resulting from, arising out of, relating to or in connection with:(1) your violation of these Terms of
      Use: and/or (2) your use of the Service, including but not limited to you posting, uploading, or using in any way
      UserContent that is defamatory, abusive, infringing, or unlawful in any way. This paragraph is intended to be
      applicable to the maximum extent allowed by law.
      <br />
      <br />
      <strong>Miscellaneous.</strong>
      <br />
      Except as provided above under the “Dispute Resolution” Section, we reserve the right, at our sole discretion, to
      change, modify, add or remove any portion of these Terms of Use, in whole or in part, at any time. It’s important
      that you review the Terms of Use whenever we update them or you use the Service. Your continued use of the Service
      after we have posted an updated Terms of Use shall be considered acceptance of those changes, modifications,
      additions or removals. These Terms of Use constitute the entire agreement between you and us in connection with
      your use of the Service and regarding Content and theseTerms of Use supersede and replace any and all prior oral or
      written understandings or agreements between you and us regarding the Service and Content. SocialVenu’s failure to
      enforce any right or provision of these Terms of Use will not be considered a waiver of such right or provision.
      The waiver of any such right or provision will be effective only if in writing and signed by aduly authorized
      representative of SocialVenu. &nbsp;Except as expressly set forth in these Terms of Use, the exercise by either
      party of any of its remedies under these Terms of Use will be without prejudice to its other remedies under these
      Terms of Use or otherwise. Any cause of action you may have with respect to your use of the Service must be
      commenced within one (1)year after the claim or cause of action arises. If for any reason a court of competent
      jurisdiction or arbitrator finds any provision of these Terms of Use to be unenforceable, then such provision shall
      be enforced to the maximum extent permissible by law so as to effect the intent of these Terms of Use and the
      remainder of these Terms of Use shall continue in full force and effect.You may not assign or transfer these Terms
      of Use, by operation of law or otherwise, without SocialVenu’s prior written consent. Any attempt by you to assign
      or transfer these Terms of Use, without such consent, will be null. SocialVenu may freely assign or transfer these
      Terms of Use without restriction. Subject to the foregoing, these Terms of Use will bind and inure to the benefit
      of the parties, their successors and permitted assigns. In the event of any termination of these Terms of Use or
      your account, this paragraph(Miscellaneous) and the following paragraphs will survive: Proprietary Rights,Rights
      Granted by You, Disclaimers, Limitation of Liabilities, Governing Law and Forum Choice, Dispute Resolution, Events
      Beyond Control andIndemnification&nbsp;&nbsp;
      <strong>Privacy Policy.</strong>&nbsp;Please see our Privacy Policy for a description of the privacy and related
      policies of the Service.&nbsp;
      <strong>Last Modified.</strong>&nbsp;These Terms of Use were last modified August 2020.
      <br />
      <br />
      &nbsp;
      <strong>
        SocialVenu Privacy Policy
        <br />
        <br />‍
      </strong>
      SocialVenu,Inc. (the “Company”) is committed to maintaining robust privacy protections for its users. Our Privacy
      Policy (“Privacy Policy”) is designed to help you understand how we collect, use and safeguard the information you
      provide to us and to assist you in making informed decisions when using our Service. For purposes of this Privacy
      Policy, “Service” refers to our website atsocialvenu.com and the services we offer our business customers through
      our website and via our customizable mobile application where our business customers’ users can access photos taken
      by the SocialVenu system and share them via third party social networks, post their own content, play social games,
      view leader boards and use other functionality as determined by our business customer. We are not responsible for
      the privacy practices of the third-party social networks or our business customers. Please refer to their privacy
      policies directly.
      <br />
      <br />‍
      <strong>
        INFORMATION WE COLLECT
        <br />‍
      </strong>
      The types of personal information we collect and our privacy practices depend on whether you are a business
      customer, a user, or a visitor and the requirements of applicable law.&nbsp;Business Account Creation: When you
      create a business account to use our Services, we collect your name, title, email and physical address, phone
      number, username, and password.&nbsp;User AccountCreation: When you create a user account through our business
      customer’s mobile app, we and our business customers collect your name, email address, phone number, username, and
      password.
      <br />
      <strong>
        <br />
        Use the Services
      </strong>
      .<br />
      We may collect the types of content you interact with and the frequency and duration of your activities. We may
      capture information about the photos you share on social media such as how many likes.. We may also add information
      to your account that we receive from our business customers or from social networking sites.
      <br />‍<br />‍<strong>Your Devices.</strong>
      <br />
      We may collect Information from your devices automatically when you access ourServices, depending on the
      permissions you’ve granted. This information may include the operating system, hardware version, device settings
      and unique identifiers, device locations (including specific geographic locations), your mobile operator or ISP,
      browser type, language, IP address, pages that you visit before, during and after using the Services, information
      about the links you click, and other information about how you use the Services. Information we collect may be
      associated with your account and other devices.
      <br />‍<br />‍
      <strong>
        Cookies, Analytics and Interest-Based Advertising
        <br />‍
      </strong>
      We, as well as third parties that provide content, advertising, or other functionality on the Services, may use
      cookies, pixel tags, local storage, and other technologies (“Technologies”) to automatically collect information
      through the Services. Technologies are essentially small data files placed on your computer, tablet, mobile phone,
      or other devices that allow us to record certain pieces of information whenever you visit or interact with our site
      andServices.
      <br />• Cookies. Cookies are small text files placed in visitors’ computer browsers to store their preferences.
      Most browsers allow you to block and delete cookies. However, if you do that, the Services may not work properly.
      <br />• Pixel Tags/Web Beacons. A pixel tag (also known as a web beacon) is a piece of code embedded on the site
      that collects information about users’ engagement on that web page. The use of a pixel allows us to record, for
      example, that a user has visited a particular web page or clicked on a particular advertisement.
      <br />• Analytics. We may also use Google Analytics, MixPanel and other service providers to collect information
      regarding visitor behavior and visitor demographics on our site and Services. For more information about
      GoogleAnalytics, please visit www.google.com/ policies/privacy/partners/. You can opt out of Google’s collection
      and Processing of data generated by your use of theServices by going
      to&nbsp;http://tools.google.com/dlpage/gaoptout. Our uses of such Technologies fall into the following general
      categories:
      <br />
      <br />‍<strong>Operationally Necessary</strong>.<br />
      This includes Technologies that allow you access to our site, Services, applications, and tools that are required
      to identify irregular site behavior, prevent fraudulent activity and improve security or that allow you to make use
      of our functions such as shopping-carts, saved search, or similar functions;
      <br />
      <br />‍<strong>Performance Related</strong>.<br />
      We may use Technologies to assess the performance of our Services, including aspart of our analytic practices to
      help us understand how our visitors use theServices;
      <br />
      <br />‍<strong>Functionality Related</strong>.<br />
      We may use Technologies that allow us to offer you enhanced functionality when accessing or using our site and
      Services. This may include identifying you when you sign into our Site or Services or keeping track of your
      specified preferences, interests, or past items viewed;
      <br />
      <br />‍<strong>Advertising or Targeting Related</strong>.<br />
      We may use first party or third party Technologies to deliver content, including ads relevant to your interests, on
      our site and Services or on ThirdParty sites.
      <br />
      <br />‍
      <strong>
        Third-Party Websites, Social Media Platforms and Software Development Kits
        <br />‍
      </strong>
      The Services may contain links to other websites and other websites may reference or link to our site or other
      Services. These other domains and websites are not controlled by us. We encourage our users to read the privacy
      policies of each website and application with which they interact. We do not endorse, screen or approve, and are
      not responsible for the privacy practices or content of such other websites or applications. Visiting these other
      websites or applications is at your own risk. Our Services may include publicly accessible blogs, forums, social
      media pages, and private messaging features.By using such services, you assume the risk that the personal
      information provided by you may be viewed and used by Third Parties for any number of purposes. We may use third
      party APIs and software development kits (“SDKs”) aspart of the functionality of our Services. APIs and SDKs may
      allow third parties including advertising partners to collect your personal information to provide content that is
      more relevant to you. &nbsp;For more information about our use of APIs and SDKs, please contact us as set forth
      below.
      <br />
      <br />‍<strong>Information from Other Sources.</strong>
      <br />
      We may obtain information about you from other sources, including through our business partners, third party
      services and organizations. For example, if you access our Services through a third-party application, such as an
      app store, a third-party login service like Facebook or Google, or a social networking site, we may collect
      information about you from that third party application that you have made public via your privacy settings such as
      your name, your user identification number, your user name, location, gender, birth date, email, and profile
      picture.
      <br />
      <br />‍
      <strong>
        HOW WE USE YOUR INFORMATION
        <br />
        <br />
        Communicating with Us.
      </strong>
      &nbsp;The personal information you provide to us isused to help us communicate with you. For example, we use
      personal information to communicate with you about your account, events and Services, solicit your feedback,
      conduct surveys, verify your identity and provide technical support.
      <br />
      <br />‍
      <strong>
        Providing the Service.
        <br />‍
      </strong>
      We may use personal information to measure interest and engagement, conduct research and development, provide and
      improve our Services, develop newServices, ensure internal quality control, prevent fraud or prohibited or illegal
      activities; enforce our terms, and customize the user experience.
      <br />
      <br />‍<strong>Posting and Tagging.</strong>
      <br />
      Any content you provide on the third party social media services may be considered “public” depending on your
      settings and is subject to the privacy policies of the third party sites. You should also be aware that when you
      share information publicly, it may be indexable by search engines.
      <br />
      <br />‍<strong>Registering for Sweepstakes or Contests.</strong>
      <br />
      From time to time, we or our business customers may offer you the chance to participate in sweepstakes, contests,
      and surveys through our Service, whichare governed by a different privacy policy and/or terms and conditions. For
      more, see: www.socialvenu.com/partnerpromotions. <br /> <br />‍
      <strong>De-identified and Aggregated Information Use</strong>.<br />
      We also aggregate information in order to track trends and analyze use patterns on the site. We may use personal
      information and other information about you to create de-identified and aggregated information, such as
      de-identified demographic information, de-identified location information, information about the computer or device
      from which you access our site and Services.&nbsp;&nbsp;
      <strong>Sharing Content with Friends or Colleagues</strong>
      .<br />
      Our site and Services may offer various tools and functionalities. For example, we may allow you to forward or
      share content with a friend or colleague.
      <br />
      <br />‍
      <strong>
        HOW WE SHARE INFORMATION
        <br />
        <br />
        Vendors and Service Providers.
      </strong>
      &nbsp;We may share any information we receive with vendors and service providers. The types of service providers
      (processors)to whom we entrust personal information include service providers for the provision of: IT,
      communications and related services; information andServices; payment processing; customer service activities; and
      the provision of the site and Services. Those vendors use your personal information only at our direction and in
      accordance with our agreements with them.
      <br />
      <br />‍
      <strong>
        Information We Process on Behalf of Our Business Customers.
        <br />
      </strong>
      Our business customers may choose to use our Services to process certain data of their own, which may contain your
      personal information. The data that we process through our Services is processed by us purely on behalf of our
      business customer, and our privacy practices will be governed by the contracts that we have in place with our
      business customers, not this Privacy Policy.
      <br />
      <br />‍<strong>Business Partners.</strong>
      <br />
      We may provide personal information to business partners with whom we jointly offer products or services. In such
      cases, our business partner’s name will appear along with ours.
      <br />
      <br />‍<strong>Marketing – Interest-Based Advertising and Third Party Marketing.</strong>
      &nbsp;&nbsp;
      <br />
      Through our Services, we may allow third-party advertising partners to setTechnologies and other tracking tools to
      collect information regarding your activities and your device (e.g., your IP address, mobile identifiers,
      page(s)visited, location, time of day). We may also combine and share such information and other information (such
      as demographic information and past purchase history) with third party advertising partners. These advertising
      partners may use this information (and similar information collected from other websites)for purposes of delivering
      targeted advertisements to you when you visit third party websites within their networks. This practice is commonly
      referred to as“interest-based advertising” or “online behavioral advertising. We may allow access to other data
      collected by the site and Services to share information that may be useful, relevant, valuable or otherwise of
      interest to you. If you prefer not to share your personal information with third party advertising partners, you
      may follow the instructions below.
      <br />
      <br />‍<strong>Disclosures to Protect Us or Others.</strong>
      <br />
      We may access, preserve, and disclose your personal information, other account information, and content if we
      believe doing so is required or appropriate to:comply with law enforcement or national security requests and legal
      process, such as a court order or subpoena; respond to your requests; protect your, our or others’ rights,
      property, or safety; to enforce our policies or contracts; to collect amounts owed to us; when we believe
      disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an
      investigation or prosecution of suspected or actual illegal activity; or if we, in good faith, believe that
      disclosure is otherwise necessary or advisable.
      <strong>Merger,Sale, or Other Asset Transfers.</strong>&nbsp;If we are involved in a merger, acquisition, financing
      due diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or transition of service to
      another provider, then your information may be sold or transferred as part of such a transaction as permitted by
      law and/or contract.
      <br />
      <br />‍
      <strong>
        HOW WE PROTECT INFORMATION
        <br />
        <br />‍
      </strong>
      We implement security measures designed to protect your information from unauthorized access. Your account is
      protected by your account password, and we urge you to take steps to keep your personal information safe by not
      disclosing your password and by logging out of your account after each use. We take steps to ensure that your
      information is treated securely and in accordance with thisPrivacy Policy. Unfortunately, the Internet cannot be
      guaranteed to be 100%secure, and we cannot ensure or warrant the security of any information you provide to us. To
      the fullest extent permitted by applicable law, we do not accept liability for unintentional disclosure.
      <br />
      <br />‍
      <strong>
        DATA RETENTION
        <br />‍<br />‍
      </strong>
      We retain the personal information we receive as described in this PrivacyPolicy for as long as you use our
      Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve
      disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements,
      and comply with applicable laws.
      <br />
      <br />
      <strong>
        YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION
        <br />
        <br />‍
      </strong>
      If you would like us to delete your personal information or your account, transfer your data, remove your photo or
      untag you from the Service, please contact support@socialvenu.com. Be sure to tell us where you are in the photo,
      and we will remove you from it by blurring or covering your digital content. To protect your privacy, we will take
      steps to verify your identity before fulfilling your request. You have the right at any time to prevent us from
      contacting you for marketing purposes. When we send a promotional communication to a user, the user can opt out of
      further promotional communications by following the unsubscribe instructions provided in each promotional e-mail.
      You may also indicate that you do not wish to receive marketing communications from us in the “Settings” section of
      the site. Please note that notwithstanding the promotional preferences you indicate, we may continue to send you
      administrative emails.We may send you push notifications through our mobile application. You may at any time
      opt-out from receiving these types of communications by changing the settings on your device. We may also collect
      location-based information if you use our Services. You may opt-out of this collection by changing your device
      settings.&nbsp;”Do Not Track”&nbsp;Do NotTrack (“DNT”) is a privacy preference that users can set in certain web
      browsers. Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web
      browsers.
      <br />‍<br />‍
      <strong>
        Cookies and Interest-Based Advertising
        <br />‍
      </strong>
      You may stop or restrict the placement of technologies on your device or remove them by adjusting your preferences
      as your browser or device permits.The online advertising industry also provides websites from which you may opt out
      of receiving targeted ads from data partners and other advertising partners that participate in self-regulatory
      programs. You can learn more about targeted advertising and exercise consumer choice at
      www.networkadvertising.org/managing/opt_out.asp, https://youradchoices.ca/choices/, andwww.aboutads.info/choices/.
      To separately make choices for mobile apps on a mobile device, you can download DAA’s AppChoices application from
      your device’s app store. Please note you must separately opt out in each browser and on each device. Advertisements
      on third party websites that contain the AdChoices link may have been directed to you based on information
      collected by advertising partners over time and across websites. These advertisements provide a mechanism to opt
      out of the advertising partners’ use of this information for interest-based advertising purposes.
      <br />
      <br />
      <br />‍
      <strong>
        CHILDREN’S INFORMATION
        <br />
        <br />‍
      </strong>
      The site and Services are not directed to children under 13 (or other age as required by law), and we do not
      knowingly collect personal information from children. If you learn that your child has provided us with personal
      information without your consent, you may contact us as set forth herein. If we learn that we have collected any
      personal information in violation of applicable law, we will promptly take steps to delete such information and
      terminate the child’s account.
      <br />
      <br />‍
      <strong>
        CHANGES TO OUR PRIVACY POLICY
        <br />
        <br />‍
      </strong>
      The Company reserves the right to change this Privacy Policy and our Terms of Service at any time. We will notify
      you of significant changes to our PrivacyPolicy by sending a notice to the primary email address specified in your
      account or by placing a prominent notice on our site. You should periodically check the site and this privacy page
      for updates.
      <br />
      <br />‍
      <strong>
        CONTACT US
        <br />
        <br />‍
      </strong>
      If you have any questions regarding this Privacy Policy or our privacy practices, please contact us by sending an
      email to support@socialvenu.com.
      <br />
      <br />‍<strong>Last Updated:</strong>&nbsp;This Privacy Policy was last updated August 2020.
    </PrivacyPolicyTextStyle>
  );

  if (device === LayoutMode.Desktop) {
    return (
      <DesktopPageContainer>
        <Box mt={3} />
        <Title
          firstText={TermsOfServiceStrings.TitleFirstText}
          secondaryText={TermsOfServiceStrings.TitleSecondText}
          noPadding
        />
        <DesktopPageContent>{content}</DesktopPageContent>
      </DesktopPageContainer>
    );
  }

  return content;
};
