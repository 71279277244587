import { Box } from '@mui/material';
import { useCallback, useEffect } from 'react';

const SocialversesPage = () => {
  const svId = location.pathname.split('/').pop();

  const getSvComponent = useCallback(() => {
    const component = document.createElement('section');
    component.style.width = '100%';

    component.innerHTML = `<gidget-component
     widget-type='block'
     heading='Customers Love Transcription Account'
     socialverse-id='${svId}'
     show-block-hero-name='true' />`;

    return component;
  }, [svId]);

  useEffect(() => {
    setTimeout(async () => {
      const container = document.getElementById('sv-wrapper');
      if (container && container.hasChildNodes()) {
        container.innerHTML = '';
      }
      container?.prepend(getSvComponent());
    }, 0);
  }, [getSvComponent]);

  return (
    <div style={{ height: '100%', width: '100%', background: 'white' }}>
      <Box id="sv-wrapper" />
    </div>
  );
};

export default SocialversesPage;
