import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    position: 'relative',
    height: 'var(--app-height)',
    minHeight: '550px',
  },
  navPanel: {
    position: 'absolute',
    width: '100%',
    bottom: '0',
    transform: 'translateX(-50%)',
    left: '50%',
    zIndex: 2,
  },
  screenWrapper: {
    width: '100%',
    height: '100%',
    background: '#fff',
  },
  launchScreenWrapper: {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
  },
}));
