import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { useStyles } from './ShareVideoModal.styles';
import { SharePageStrings } from '../../../../common/localization/en';
import { copyToClipboard } from '../../../../services/utilities';
import ShareLinks from './ShareLinks';
import { useThemeLayout } from '../../../../common/hooks/useThemeLayout';
import { CloseIcon } from '../../../../common/assets/CloseIcon';

interface Props {
  storyLink: string | undefined;
  isOpen: boolean;
  onClose: () => void;
  onDownloadModalOpen?: () => void;
  storyId: string;
}

const ShareVideoModal: FC<Props> = ({ storyLink, isOpen, onClose, onDownloadModalOpen, storyId }) => {
  const styles = useStyles();

  const [isCopied, setIsCopied] = useState(false);
  const timerRef = useRef<number | null>(null);

  const { isThemeLayoutEnabled, themeBackgroundColor, themeTextColor } = useThemeLayout();

  const handleCopyLink = () => {
    if (!storyLink) return;

    setIsCopied(true);
    copyToClipboard(storyLink);

    timerRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <Modal open={isOpen} onClose={onClose} BackdropProps={{ sx: { backdropFilter: 'blur(2px)' } }}>
      <Box className={styles.container} sx={{ backgroundColor: isThemeLayoutEnabled ? themeBackgroundColor : '#FFF' }}>
        <IconButton
          style={{ position: 'absolute', top: '10px', right: '10px' }}
          onClick={() => onClose()}
          aria-label="close"
          size="medium"
        >
          <CloseIcon color={isThemeLayoutEnabled ? themeTextColor : '#000'} />
        </IconButton>

        <Typography className={styles.title} color={isThemeLayoutEnabled ? themeTextColor : '#000'}>
          {SharePageStrings.ShareYourVideo}
        </Typography>
        <Typography className={styles.title} color={isThemeLayoutEnabled ? themeTextColor : '#000'}>
          {SharePageStrings.LinkTo}
        </Typography>

        <ShareLinks shareLink={storyLink} storyId={storyId} />

        <Typography className={styles.copyTitle}>
          {isCopied ? SharePageStrings.LinkCopied : SharePageStrings.CopyLink}
        </Typography>
        <Box className={styles.copyWrapper} onClick={handleCopyLink}>
          <Typography className={styles.copyLink}>{storyLink?.split('//')[1]}</Typography>
          <Box className={`${isCopied && styles.copyAnimation}`} />
        </Box>
        {onDownloadModalOpen && (
          <Button
            variant="text"
            className={styles.close}
            onClick={() => {
              onClose();
              onDownloadModalOpen();
            }}
            sx={{
              '&:hover': {
                backgroundColor: isThemeLayoutEnabled ? themeBackgroundColor : '#FFF',
              },
            }}
          >
            {SharePageStrings.DownloadVideo}
          </Button>
        )}
      </Box>
    </Modal>
  );
};

export default ShareVideoModal;
