export enum TabNames {
  ProfileForm = 'ProfileForm',
  VideoDefaultText = 'VideoDefaultText',
}

import makeStyles from '@mui/styles/makeStyles';

export const useUserProfileStyles = makeStyles((theme) => ({
  imageWrapper: {
    zIndex: 0,
    maxWidth: '665px',
    margin: '0 auto',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      '& img': {
        objectPosition: 'center',
      },
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
    },
  },
  imageBackground: {
    width: '100% !important',
    minWidth: 'auto !important',
  },
  themeBackground: {
    width: '100% !important',
    minWidth: 'auto !important',
    height: '100vh',
    position: 'absolute',
    top: '0',
    left: '0',
  },
}));
