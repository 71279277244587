import { forwardRef, useEffect } from 'react';
import EReCAPTCHA from 'react-google-recaptcha-enterprise';
import { useReCAPTCHAStyles } from './ReCaptcha.style';

declare global {
  interface Window {
    grecaptcha: {
      reset: () => void;
    };
    recaptchaOptions: {
      useRecaptchaNet: boolean;
      enterprise: boolean;
    };
  }
}

export type ReCaptchaRefType = null | EReCAPTCHA;

interface ReCaptchaProps {
  onChange?: (value: string | null) => void;
  onClick?: () => void;
  onError?: () => void;
  onExpired?: () => void;
  isEnterprise?: boolean;
}

export const ReCaptcha = forwardRef<ReCaptchaRefType, ReCaptchaProps>(
  ({ onChange, onError, onExpired, onClick, isEnterprise = false }, ref) => {
    const classes = useReCAPTCHAStyles();
    useEffect(() => {
      window.recaptchaOptions = {
        enterprise: isEnterprise,
        useRecaptchaNet: isEnterprise,
      };
    }, [isEnterprise]);

    const sitekey = isEnterprise ? process.env.NEXT_PUBLIC_ENTERPRISE_RECAPTCHA : process.env.REACT_APP_RECAPTCHA;

    return (
      <div className={classes.captcha} key={isEnterprise ? 'invisible-captcha' : 'normal-captcha'}>
        <EReCAPTCHA
          sitekey={sitekey || ''}
          onClick={onClick}
          onChange={onChange}
          size={isEnterprise ? 'invisible' : 'normal'}
          theme="light"
          onErrored={onError}
          onExpired={onExpired}
          ref={ref}
        />
      </div>
    );
  },
);

ReCaptcha.displayName = 'ReCaptcha';
