import React, { FC } from 'react';
import { useTheme } from '@mui/material';

import { TitleText, FirstPart, SecondPart } from './Title.style';

interface TitleProps {
  firstText?: string;
  secondaryText?: string;
  noPadding?: boolean;
  style?: React.CSSProperties;
}

export const Title: FC<TitleProps> = ({ firstText, secondaryText, noPadding, style }) => {
  const theme = useTheme();
  return (
    <TitleText addPadding={!noPadding} style={style ? style : {}}>
      {firstText && <FirstPart>{`${firstText} `}</FirstPart>}
      {secondaryText && <SecondPart theme={theme}>{secondaryText}</SecondPart>}
    </TitleText>
  );
};
