import { useEffect, ReactNode, FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { httpClient } from '../../../services/httpClient/httpClient';
import { useAppDispatch, useTypedSelector } from '../../../store/store';
import { AppRoutes, autenticatedRoutes } from '../../constants/routes';
import { useQuery } from '../../../services/hooks/useQuery';
import { loginWithMagicLink } from '../../../store/slices/me';

interface AuthHandlerProps {
  children: ReactNode;
}

export const AuthHandler: FC<AuthHandlerProps> = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const isLoggedIn = httpClient.isLoggedIn();
  const { email, name, phoneNumber } = useTypedSelector((state) => state.me);
  const { name: venueName } = useTypedSelector((state) => state.account.account);
  const authenticatedRouteArray = Object.values(autenticatedRoutes) as string[];
  const pathname = location.pathname.split('/')[1];

  const dispatch = useAppDispatch();
  const query = useQuery();
  const magicLinkToken = query.get('ml');

  useEffect(() => {
    if (magicLinkToken) {
      dispatch(loginWithMagicLink(magicLinkToken));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [magicLinkToken]);

  useEffect(() => {
    if (isLoggedIn && window.$crisp) {
      // window.$crisp.push([
      //   'set',
      //   'session:data',
      //   [
      //     [
      //       ['email', email],
      //       ['name', name],
      //       ['phone', phoneNumber],
      //     ],
      //   ],
      // ]);

      window.$crisp.push([
        'set',
        'user:nickname',
        [`(WEB-APP) ${name ? name + ', ' : ''}${phoneNumber ? phoneNumber + ', ' : ''}from: [${venueName}]`],
      ]);

      email && window.$crisp.push(['set', 'user:email', [email]]);
    }
  }, [isLoggedIn, window.$crisp, email, name, phoneNumber]);

  useEffect(() => {
    if (!isLoggedIn && authenticatedRouteArray.includes(`/${pathname}`)) {
      history.push(`${AppRoutes.Home}${location.search}`);
    }
  }, [pathname, isLoggedIn]);

  return <div>{children}</div>;
};
