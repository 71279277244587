import { SVGProps } from 'react';

export const PlayVideoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.5 11.9994V8.43941C4.5 4.01941 7.63 2.20941 11.46 4.41941L14.55 6.19941L17.64 7.97941C21.47 10.1894 21.47 13.8094 17.64 16.0194L14.55 17.7994L11.46 19.5794C7.63 21.7894 4.5 19.9794 4.5 15.5594V11.9994Z"
      fill="white"
    />
  </svg>
);
