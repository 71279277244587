import { FC } from 'react';

import { tabList, useStyles } from './ProfilePage.helper';

import { useLocation } from 'react-router-dom';
import { PageContainer } from '../../common/components/PageContainer/PageContainer';

const ProfilePage: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const activeTabName = location.pathname.split('/').pop() as string;

  if (!activeTabName) return null;

  const tabComponent = tabList[activeTabName]?.component;

  return (
    <div className={classes.profilePageWrapper}>
      <PageContainer>
        <div className={classes.contentWrapper}>{tabComponent}</div>
      </PageContainer>
    </div>
  );
};

export default ProfilePage;
