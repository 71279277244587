import { isMobile } from 'react-device-detect';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    position: 'absolute',
    top: isMobile ? '' : '50%',
    bottom: isMobile ? '216px' : '',
    left: '50%',
    transform: isMobile ? 'translate(-50%, 0%)' : 'translate(-50%, -50%)',
    width: 327,
    borderRadius: '16px',
    paddingBottom: '3px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 'normal',
    marginTop: 16,
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 12,
    textAlign: 'center',
    lineHeight: '18px',
    width: 'fit-content',
    alignSelf: 'center',
  },
  btn: {
    wordBreak: 'break-word',
    fontWeight: 600,
    fontSize: 14,
    width: 295,
    height: 48,
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    textTransform: 'none',
    margin: '8px auto 16px',
    padding: 0,
    '&:active': {
      transition: '0.3s',
      transform: 'scale(0.98)',
    },
  },
}));
