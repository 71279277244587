import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '16px 58px',
    borderRadius: '16px',
    boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
  },
  copyTitle: {
    fontSize: '13px',
    fontWeight: 700,
    color: '#767676',
    marginBottom: '2px',
  },
  copyWrapper: {
    maxWidth: '200px',
    border: '1px solid rgba(118, 118, 118, 0.40)',
    borderRadius: '4px',
    padding: '9px 33px',
    cursor: 'pointer',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    position: 'relative',
  },
  copyLink: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    fontWeight: 600,
    color: '#767676',
    wordBreak: 'keep-all',
    textWrap: 'nowrap',
  },
  copyAnimation: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    backgroundColor: '#4F96FF1A',
    animation: `$animateBackground 3000ms ${theme.transitions.easing.easeInOut}`,
  },
  close: {
    fontSize: '14px',
    fontWeight: 600,
    textDecoration: 'underline',
    color: '#767676',
    padding: 0,
    marginTop: '18px',
  },

  '@keyframes animateBackground': {
    '0%': {
      width: '0%',
    },
    '10%': {
      width: '100%',
    },
    '95%': {
      width: '100%',
    },
    '100%': {
      width: '0%',
    },
  },
}));
