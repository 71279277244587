import { FC, useEffect } from 'react';

import { Title } from '../../common/components/Title/Title';
import { SignInForm } from './SignInForm/SignInForm';
import { useTypedSelector } from '../../store/store';
import { AppRoutes } from '../../common/constants/routes';
import { SignInPageStrings } from '../../common/localization/en';
import { trackPageView } from '../../../utils/analytics/analytics';

export const SignInPage: FC = () => {
  const accountId = useTypedSelector((state) => state.account.account.id);
  const { id: userId, email, name } = useTypedSelector((state) => state.me);

  useEffect(() => {
    trackPageView({
      url: AppRoutes.SignIn,
      accountId,
      userId,
      email,
    });
  }, [email, name, userId, accountId]);

  return (
    <>
      <Title firstText={SignInPageStrings.TitleFirstText} secondaryText={SignInPageStrings.TitleSecondText} />
      <SignInForm />
    </>
  );
};
