import { ClientsTypes } from '../../api/models/common';
import { useTypedSelector } from '../../store/store';

export function useClientType() {
  const { clientType } = useTypedSelector((state) => state.account.account);

  const isTrubluClient = clientType === ClientsTypes.TRUBLU;
  const isEtsClient = clientType === ClientsTypes.ETS;
  const isFundraisingClient = clientType === ClientsTypes.FUNDRAISING;
  const isSalesClient = clientType === ClientsTypes.SALES;
  const isMarketingClient = clientType === ClientsTypes.MARKETING;
  const isAthleticSolutionsClient = clientType === ClientsTypes.ATHSOL;
  const isSvHealth = clientType === ClientsTypes.SV_HEALTH;
  const isHealthCare = isTrubluClient || isSvHealth;
  const isUVMClient = clientType === ClientsTypes.BRAND360;

  return {
    isTrubluClient,
    isEtsClient,
    isFundraisingClient,
    isSalesClient,
    isMarketingClient,
    isAthleticSolutionsClient,
    isSvHealth,
    isHealthCare,
    isUVMClient,
  };
}
