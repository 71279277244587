import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { StoryVideosApiModel } from '../../../../api/models/videos';

export interface UserVideosValues {
  items: StoryVideosApiModel[];
  totalPages: number;
}

export const useStyles = makeStyles<Theme, { isThemeLayoutEnabled?: boolean; isDarkTheme?: boolean }>(
  (theme: Theme) => ({
    root: {
      marginLeft: 0,
    },
    label: {
      fontSize: '12px',
    },
    paper: {
      padding: theme.spacing(2),
      backgroundColor: '#1c1d22',
      borderRadius: '10px',
      color: theme.palette.common.white,
    },
    shareDialog: ({ isThemeLayoutEnabled, isDarkTheme }) => ({
      padding: theme.spacing(2),
      backgroundColor: `${
        isThemeLayoutEnabled ? (isDarkTheme ? '#2C2B2B' : theme.palette.common.white) : theme.palette.common.white
      } !important`,
      borderRadius: '10px',
      color: `#1c1d22 !important`,
    }),
    content: {
      padding: 0,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      padding: 0,
      color: theme.palette.grey[500],
      zIndex: '10',
    },
    dialogTitle: {
      position: 'absolute',
      top: '0',
      right: '0',
    },
    switchBase: {
      color: '#c7c7d9',

      '&$checked': {
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
        },
      },
    },
    checked: {},
    switch: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '15px',
    },
    info: ({ isThemeLayoutEnabled, isDarkTheme }) => ({
      boxSizing: 'content-box',
      height: '46px',
      padding: '6px 24px 10px 20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: isThemeLayoutEnabled ? (isDarkTheme ? '#fff' : '#101828') : '#101828',
      background: isThemeLayoutEnabled ? (isDarkTheme ? '#494949' : '#fff') : '#fff',
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px',
      '& p': {
        fontSize: '17px',
        fontWeight: '500',
        lineHeight: '28px',
      },
    }),
    icons: {
      display: 'flex',
      '& svg': {
        cursor: 'pointer',
      },
      '& svg:last-child': {
        height: '28px',
        marginLeft: '11px',
      },
    },
    unchecked: {
      color: '#c7c7d9',
    },
    track: {
      opacity: 1,
      backgroundColor: '#6772b8',
    },
    storyText: {
      position: 'absolute',
      bottom: 90,
      left: 24,
      right: 24,
      padding: '3px 14px',
      backgroundColor: theme.palette.common.black,
      borderRadius: 4,
      color: theme.palette.common.white,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1.6,
      letterSpacing: 0.15,
    },
    shareButton: {
      // color: theme.palette.secondary.contrastText,
      // backgroundColor: theme.palette.primary.main,
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '12px',
      fontWeight: '600',
      borderRadius: '60px',
      cursor: 'pointer',
      fontSize: 16,
    },
    downloadOption: ({ isThemeLayoutEnabled, isDarkTheme }) => ({
      padding: '16px',
      background: isThemeLayoutEnabled ? (isDarkTheme ? '#1A1A1A' : '#fff') : 'white',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      borderRadius: '12px',
      border: `1px solid ${isThemeLayoutEnabled ? (isDarkTheme ? '#D0D5DD' : '#EAECF0') : '#EAECF0'}`,
      minWidth: '311px',
      marginBottom: '12px',
    }),
  }),
);
