import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100000,
  },
  modal: {
    zIndex: 2000,
    position: 'relative',
    width: 'calc(100% - 48px)',
    maxWidth: 500,
    padding: '56px 16px 16px 16px',
    borderRadius: 10,
    backgroundColor: '#1C1D22',
    boxSizing: 'border-box',
    opacity: 0,
    transform: 'translateY(200px) scale(0.9)',
    transition: 'all .25s ease-in-out',
  },
  isOpen: {
    opacity: 1,
    transform: 'translateY(-40%) scale(1)',
    pointerEvents: 'auto',
  },
  errorText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '26px',
    whiteSpace: 'pre-line',
  },
  close: {
    position: 'absolute',
    right: 16,
    top: 16,
    cursor: 'pointer',
  },
}));
