import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './Counter.styles';
import { RECORD_TIME_LIMIT } from '../RecordVideoComponent';

interface Props {
  initialValue: number;
  setIsCountStarted: (isStarted: boolean) => void;
  startRecording: () => void;
  isCountDownStarted: boolean;
  isRecording: boolean;
}

export const Counter: FC<Props> = ({
  initialValue,
  setIsCountStarted,
  startRecording,
  isCountDownStarted,
  isRecording,
}) => {
  const classes = useStyles();
  const [count, setCount] = useState(initialValue);
  const [recordingTimeCount, setRecordingTimeCount] = useState(RECORD_TIME_LIMIT);

  useEffect(() => {
    if (isCountDownStarted) {
      let initialCount = count;
      const interval = setInterval(() => {
        if (initialCount === 1) {
          setIsCountStarted(false);
          startRecording();
          clearInterval(interval);
          return;
        }
        initialCount--;
        setCount(initialCount);
      }, 1000);
    }
  }, [isCountDownStarted]);

  useEffect(() => {
    if (isRecording) {
      let initialCount = RECORD_TIME_LIMIT;
      const interval = setInterval(() => {
        if (initialCount === 0) {
          clearInterval(interval);
          return;
        }
        initialCount--;
        setRecordingTimeCount(initialCount);
      }, 1000);
    }
  }, [isRecording]);

  const minutes = Math.floor(recordingTimeCount / 60);
  const seconds = recordingTimeCount % 60;

  return (
    <Box className={classes.container}>
      {isCountDownStarted && <Typography className={classes.countDownText}>{count}</Typography>}

      {isRecording && recordingTimeCount > 0 && (
        <Box className={classes.recordTimeCounterWrapper}>
          <Box className={classes.recordDot} />
          <Typography className={classes.recordTimeText}>{`${minutes ? minutes + 'm:' : ''}${seconds}s`}</Typography>
        </Box>
      )}
    </Box>
  );
};
