import { useMemo } from 'react';
import { AuthInitialFormValues } from '../../common/commonTypes';

export const useSignUpFieldsConfig = () => {
  const signUpConfig = useMemo(() => {
    return {
      emailRequired: true,
      phoneRequired: true,
      nameRequired: true,
    };
  }, []);

  const requiredFormFields = useMemo<Array<keyof AuthInitialFormValues>>(() => {
    return Object.entries(signUpConfig).reduce<string[]>((acc, [key, value]) => {
      if (!value) return acc;

      if (key === 'emailRequired') {
        acc.push('email');
      }
      if (key === 'phoneRequired') {
        acc.push('phoneNumber');
      }
      if (key === 'nameRequired') {
        acc.push('name');
      }
      return acc;
    }, []);
  }, [signUpConfig]);

  return { signUpConfig, requiredFormFields };
};
