import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isMobile } from 'react-device-detect';

export const useWelcomeVideo = makeStyles<Theme, { clientHeight: number; isIos: boolean }>((theme: Theme) => ({
  welcomeVideoWrapper: {
    margin: '0 auto',
    position: 'relative',
    minHeight: '450px',
    width: '100%',
  },
  welcomeVideo: {
    cursor: 'pointer',
    aspectRatio: '9/16',
    width: '100%',
  },
  banner: {
    position: 'absolute',
    top: '6px',
    left: isMobile ? '8px' : '50%',
    transform: isMobile ? '0' : 'translateX(-50%)',
    zIndex: 10000,
    width: '95.5vw',
    maxWidth: '515px',
    background: theme.palette.common.white,
    color: '#2D11AD',
    border: `1.5px solid #2D11AD`,
    borderRadius: '9px',
    height: '16vw',
    maxHeight: '86px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bannerTitle: {
    fontFamily: 'Open Sans',
    fontSize: 'clamp(18px,  5vw, 27px)',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center',
    marginBottom: isMobile ? '0' : '6px',
  },
  btnWrapper: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px 0 12px',
    height: '58px',
    bottom: '38px',
    width: '100%',
  },
  bannerText: {
    color: 'var(--lightBlack)',
    fontFamily: 'Open Sans',
    fontSize: 'clamp(14px,  3.9vw, 21px)',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center',
  },
  playIcon: {
    position: 'absolute',
    width: '54px',
    height: '54px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100000,
  },
  muteIcon: {
    '&:hover': {
      opacity: '0.9',
    },
    width: '54px',
    height: '54px',
    zIndex: 100001,
  },
  skipIcon: {
    zIndex: 100001,
    '& svg': {
      fill: 'white',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  skipText: {
    color: 'white',
    fontFamily: 'Open Sans',
    fontSize: 'clamp(16px, 4.48vw, 24px)',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'center',
  },
}));
