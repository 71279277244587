import { useTheme } from '@mui/styles';
import React from 'react';

export const LinkedInBlueIcon = () => {
  const theme = useTheme();

  return (
    <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="56" height="56" rx="28" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 17.3372C13.5 15.218 15.218 13.5 17.3372 13.5C19.4564 13.5 21.1744 15.218 21.1744 17.3372C21.1744 19.4564 19.4564 21.1744 17.3372 21.1744C15.218 21.1744 13.5 19.4564 13.5 17.3372ZM17.3372 15.593C16.3739 15.593 15.593 16.3739 15.593 17.3372C15.593 18.3005 16.3739 19.0814 17.3372 19.0814C18.3005 19.0814 19.0814 18.3005 19.0814 17.3372C19.0814 16.3739 18.3005 15.593 17.3372 15.593Z"
        fill={theme.palette.primary.contrastText}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 24.314C13.5 23.736 13.9685 23.2674 14.5465 23.2674H20.1279C20.7059 23.2674 21.1744 23.736 21.1744 24.314V42.4535C21.1744 43.0315 20.7059 43.5 20.1279 43.5H14.5465C13.9685 43.5 13.5 43.0315 13.5 42.4535V24.314ZM15.593 25.3605V41.407H19.0814V25.3605H15.593Z"
        fill={theme.palette.primary.contrastText}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.0814 23.2674C28.8797 23.2674 24.6628 27.4843 24.6628 32.686V42.4535C24.6628 43.0315 25.1313 43.5 25.7093 43.5H31.2907C31.8687 43.5 32.3372 43.0315 32.3372 42.4535V32.686C32.3372 31.7227 33.1181 30.9419 34.0814 30.9419C35.0447 30.9419 35.8256 31.7227 35.8256 32.686V42.4535C35.8256 43.0315 36.2941 43.5 36.8721 43.5H42.4535C43.0315 43.5 43.5 43.0315 43.5 42.4535V32.686C43.5 27.4843 39.2831 23.2674 34.0814 23.2674ZM26.7558 32.686C26.7558 28.6403 30.0356 25.3605 34.0814 25.3605C38.1272 25.3605 41.407 28.6403 41.407 32.686V41.407H37.9186V32.686C37.9186 30.5668 36.2007 28.8488 34.0814 28.8488C31.9621 28.8488 30.2442 30.5668 30.2442 32.686V41.407H26.7558V32.686Z"
        fill={theme.palette.primary.contrastText}
      />
    </svg>
  );
};
