import { ReactNode } from 'react';
import * as Yup from 'yup';
import makeStyles from '@mui/styles/makeStyles';

import { ProfilePageRoutes } from '../../common/constants/routes';
import { ProfilePageStrings, ErrorsStrings } from '../../common/localization/en';
import { UserProfile } from './UserProfile/UserProfile';
import { Videos } from './Videos/Videos';
import { PointsTab } from './PointsTab/PointsTab';

// import { HistoryRewards } from '../HistoryRewards/HistoryRewards';

export enum TabsNames {
  Rewards = 'rewards',
  Videos = 'videos',
  Account = 'account',
  ActivityHistory = 'activity historyTab',
}

export interface TabValues {
  index: number;
  name: string;
  component: ReactNode;
}

interface TabsValues {
  [key: string]: TabValues;
}

export const tabList: TabsValues = {
  [ProfilePageRoutes.Account]: {
    index: 0,
    name: ProfilePageStrings.AccountTab,
    component: <UserProfile />,
  },
  [ProfilePageRoutes.Videos]: {
    index: 1,
    name: ProfilePageStrings.VideosTab,
    component: <Videos />,
  },
  [ProfilePageRoutes.Rewards]: {
    index: 2,
    name: ProfilePageStrings.RewardsTab,
    component: <PointsTab />,
  },
  // [ProfilePageRoutes.ActivityHistory]: {
  //   index: 3,
  //   name: ProfilePageStrings.ActivityHistoryTab,
  //   component: <HistoryRewards hasTopBar={false} />,
  // },
};

export const getProfileValidationSchema = (
  config: { emailRequired?: boolean; firstName?: boolean; lastName?: boolean } = {
    emailRequired: false,
    firstName: false,
    lastName: false,
  },
) => {
  const baseValidationObject = {
    firstName: Yup.string().trim().max(100, ErrorsStrings.MaxLength100),
    lastName: Yup.string().trim().max(100, ErrorsStrings.MaxLength100),
    email: Yup.string().email(ErrorsStrings.EmailIsNotValid).nullable(true),
    password: Yup.string().min(6, ErrorsStrings.PasswordAtLeast6),
    confirmPassword: Yup.string().when('password', (password: string) => {
      return Yup.string()
        .min(6, ErrorsStrings.PasswordAtLeast6)
        .test('on equal', ErrorsStrings.ConfirmPassword, (confirmPassword) => confirmPassword === password);
    }),
    phoneNumber: Yup.string(),
    photoUrl: Yup.string().nullable(),
  };

  if (config.emailRequired) {
    baseValidationObject.email = baseValidationObject.email.required('Email is required').nullable(false);
  }

  if (config.firstName) {
    baseValidationObject.firstName = baseValidationObject.firstName.required('Firstname is required');
  }
  if (config.lastName) {
    baseValidationObject.lastName = baseValidationObject.lastName.required('Lastname is required');
  }

  return Yup.object().shape(baseValidationObject);
};

export const useStyles = makeStyles((theme) => ({
  profilePageWrapper: {
    // padding: '10px',
    // paddingTop: '20px',
  },
  container: {
    position: 'relative',
    padding: '0 10px 0 10px',
  },
  form: {
    paddingTop: '30px',
  },
  contentWrapper: {
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      margin: '0 auto',
    },
  },
  imageWrapper: {
    zIndex: 0,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      '& img': {
        objectPosition: 'center',
      },
    },
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      flexGrow: 1,
      '& img': {
        objectPosition: 'top',
        objectFit: 'cover',
      },
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      // backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  imageBackground: {
    width: '100% !important',
    minWidth: 'auto !important',
  },
}));
