import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    position: 'relative',
    width: 327,
    backgroundColor: '#fff',
    borderRadius: '16px',
    paddingBottom: '3px',
  },
  logo: {
    height: 40,
    width: 40,
    alignSelf: 'center',
    marginTop: 16,
    objectFit: 'contain',
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
    color: theme.palette.common.black,
    textAlign: 'center',
    lineHeight: '30px',
    marginTop: 16,
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 12,
    textAlign: 'center',
    lineHeight: '18px',
    color: '#8B89A0',
    width: 'fit-content',
    alignSelf: 'center',
    padding: '20px',
  },
  phoneWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5,
  },
  phoneNumber: {
    alignSelf: 'center',
    '& .react-tel-input :disabled': {
      cursor: 'text',
    },
    '& .react-tel-input .selected-flag': {
      display: 'none',
    },
    '& .react-tel-input .form-control': {
      border: 'none',
      padding: '0px',
      height: 'auto',
      width: '118px',
      fontSize: '12px',
      lineHeight: '1.5',
      letterSpacing: '0.02em',
      paddingLeft: 5,
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
    '& .react-tel-input .flag-dropdown': {
      display: 'none',
    },
  },

  verificationCode: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& .ReactInputVerificationCode__item': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '48px',
      height: '48px',
      backgroundColor: '#E5E5E5',
      fontWeight: 400,
      fontSize: 36,
      border: 'none',
      boxShadow: 'none',
      color: theme.palette.primary.dark,
    },
    '& .is-active': {
      border: '3px solid #2D11AD',
      boxShadow: 'none !important',
    },
    '& .ReactInputVerificationCode__container': {
      width: '212px',
    },
  },
  verificationCodeError: {
    '& .ReactInputVerificationCode__item': {
      border: `2px solid ${theme.palette.error.light}`,
    },
  },
  bottomMessage: {
    fontWeight: 400,
    fontSize: 12,
    textAlign: 'center',
    lineHeight: '18px',
    marginTop: 5,
    color: '#8B89A0',
  },
  btnResend: {
    padding: 0,
    marginLeft: 5,
    textDecoration: 'underline',
    color: theme.palette.common.black,
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:active': {
      transform: 'scale(0.95)',
    },
  },
  btnVerify: {
    wordBreak: 'break-word',
    fontWeight: 600,
    margin: '8px auto 10px',
    fontSize: 14,
    borderRadius: 8,
    width: 295,
    height: 48,
    backgroundColor: '#17B790',
    color: theme.palette.common.white,
    cursor: 'pointer',
    textTransform: 'none',
    padding: 0,
    '&:hover': {
      backgroundColor: '#17b790de',
    },

    '&:active': {
      transition: '0.3s',
      transform: 'scale(0.95)',
    },
    '&:disabled': {
      color: 'rgba(23, 183, 144, 0.3)',
      background: 'rgba(23, 183, 144, 0.2)',
    },
  },

  outlinedbtnVerify: {
    wordBreak: 'break-word',
    fontWeight: 600,
    margin: '8px auto 10px',
    fontSize: 14,
    borderRadius: 8,
    width: 295,
    height: 48,
    backgroundColor: theme.palette.common.white,
    color: '#17B790',
    cursor: 'pointer',
    textTransform: 'none',
    padding: 0,
    '&:hover': {
      backgroundColor: 'grey',
    },

    '&:active': {
      transition: '0.3s',
      transform: 'scale(0.95)',
    },
    '&:disabled': {
      color: 'rgba(23, 183, 144, 0.3)',
      background: 'rgba(23, 183, 144, 0.2)',
    },
  },

  buttonBack: {
    fontWeight: 600,
    fontSize: 13,
    color: theme.palette.common.black,

    '&:hover': {
      textDecoration: 'underline',
    },

    '&:active': {
      transition: '0.3s',
      transform: 'scale(0.95)',
    },
  },
  verificationError: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 12,
    color: theme.palette.error.light,
    marginTop: 3,
    marginBottom: 5,
  },

  unregisteredPhone: {
    marginBottom: 10,
    '& .react-tel-input .form-control': {
      color: `${theme.palette.error.light} !important`,
    },
  },
  btnVerifyUnregistered: {
    marginTop: '5px !important',
  },
}));
