import { useTypedSelector } from '../../store/store';

export const useAttributesFilterUrl = () => {
  const { accountAttributes } = useTypedSelector((state) => state.account);
  return accountAttributes.properties?.['webapp.config']?.['filter-url'];
};

export const useLogoFilterEnabled = () => {
  const { accountAttributes } = useTypedSelector((state) => state.account);
  return accountAttributes.properties?.['webapp.config']?.['filter-logo-enabled'];
};
