import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';

export const useStyles = makeStyles(() => ({
  contentWrapper: {
    height: '100vh',
    maxHeight: 'calc(100vh - 40px)',
    width: '100%',
    padding: '12px 15px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  estimationTimeWrapper: {
    width: '80%',
    height: '238px',

    borderRadius: '14px',
    marginTop: '64px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  timeSectionWrapper: {
    width: '90%',
    height: '70px',
    borderRadius: '14px',
    backdropFilter: 'blur(2pxx)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  estimatedText: {
    fontSize: '14px',
    fontWeight: 600,
  },
  timeText: {
    fontSize: '14px',
    fontWeight: 700,
  },
  uploadNotificationWrapper: {
    marginTop: isMobile ? '' : '145px',
    position: 'absolute',
    bottom: '176px',
  },
}));
