import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../../../../../utils/analytics/analytics';
import { ClientsTypes } from '../../../../api/models/common';
import { StoriesApiModel } from '../../../../api/models/stories';
import { StoryVideosApiModel } from '../../../../api/models/videos';
// import { TagsSection } from './TagsSection/TagsSection';
import CustomSwitch from '../../../../common/components/CustomSwitch/CustomSwitch';
// import { VideoDuplicate } from '../../../../common/components/VideoDuplicate/VideoDuplicate';
import { format } from 'date-fns';
import { DownloadIcon } from '../../../../common/assets/shareIcons';
import { SharIcon } from '../../../../common/assets/shareIcons/ShareIcon';
import { AllVideosComponent } from '../../../../common/components/VideoComponent/AllVideosComponent';
import { EventNames } from '../../../../common/constants/constants';
import { AppRoutes } from '../../../../common/constants/routes';
import { useThemeLayout } from '../../../../common/hooks/useThemeLayout';
import { MediaListStrings } from '../../../../common/localization/en';
import { useModal } from '../../../../services/hooks/useModal';
import { StoryStatuses } from '../../../../store/slices/stories';
import { useTypedSelector } from '../../../../store/store';
import { DownloadVideo } from '../../../SharePage/components/DownloadVideo/DownloadVideo';
import { ModerationModal } from '../../../SharePage/components/ModerationModal';
import { VideoPreviewModal } from '../../../SharePage/components/VideoPreviewModal';
import { useShareStory } from '../../../SharePage/hooks/useShareStory';
import { DownloadType } from '../../../SharePage/types';
import { useStyles } from './MediaList.helper';
import {
  Container,
  // CustomGrid,
  MediaItem,
  MediaItemWrapper,
  MediaWrapper,
  SkeletonWrapper,
} from './MediaList.style';
import ShareVideoModal from '../../../SharePage/components/ShareVideoModal/ShareVideoModal';

interface SelectedStoryState {
  id: string;
  isPublic: boolean;
}

interface MediaListProps {
  isLoading: boolean;
  items: StoriesApiModel[];
  updateStory: ({ id, isPublic }: SelectedStoryState) => void;
  sorting: string;
  setSorting: (sorting: string) => void;
  backgroundImage: string;
}

export const MediaList: FC<MediaListProps> = ({ isLoading, items, updateStory }) => {
  const { isThemeLayoutEnabled, isDarkTheme } = useThemeLayout();
  const classes = useStyles({ isThemeLayoutEnabled, isDarkTheme });
  const { account } = useTypedSelector((state) => state.account);
  const { id, email, name } = useTypedSelector((state) => state.me);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [selectedStory, setSelectedStory] = useState<SelectedStoryState>();
  const theme = useTheme();
  const color = theme?.palette?.primary?.main;
  const [currentVideoId, setCurrentVideoId] = useState('');
  const [currentStory, setCurrentStory] = useState(items[0] || null);

  const isAthSol = account.clientType === ClientsTypes.ATHSOL;

  const { show: showModerationModal, open: openModerationModal, close: closeModerationModal } = useModal();
  const { open: openDownloadSnackbar } = useModal();
  const { show: showVideoModal, open: openVideoModal, close: closeVideoModal } = useModal();

  const { show: showDownloadModal, toggle: toggleDownloadModal } = useModal(false);
  const { show: showShareModal, open: openShareModal, close: closeShareModal } = useModal();

  const { downloadStory, downloadingType } = useShareStory({
    storyId: currentStory?.id,
    refetchUntilModerated: true,
  });
  const downloadVideo = async (downloadType: DownloadType) => {
    const isStoryOnModeration = [StoryStatuses.PENDING_MODERATION, StoryStatuses.MODERATED].includes(
      (currentStory as StoriesApiModel).status as StoryStatuses,
    );
    if (isStoryOnModeration) {
      openModerationModal();
      return;
    }

    await downloadStory(downloadType);
    openDownloadSnackbar();
  };

  const shareClick = (story: StoriesApiModel) => {
    setCurrentStory(story);
    openShareModal();
  };
  const downloadClick = (story: StoriesApiModel) => {
    setCurrentStory(story);
    toggleDownloadModal();
  };

  const handleShareClick = (item: StoryVideosApiModel) => {
    trackEvent({
      action: EventNames.My_Videos_Page_Share_Button_Click,
      location: window.location.href,
      accountId: account.id,
      userId: id,
      email: email,
      name,
      storyId: item.id,
    });

    history.push(`${AppRoutes.Share}/${item.id}`);
  };

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        updateStory({
          id: event.target.name,
          isPublic: event.target.checked,
        });
        return;
      }

      setSelectedStory({
        id: event.target.name,
        isPublic: event.target.checked,
      });
      setOpen(true);
    },
    [updateStory],
  );

  const handleClose = (apply = false) => {
    if (apply && selectedStory) {
      updateStory(selectedStory);
    }
    setOpen(false);
  };

  const togglePublic = (id: string, isPublic: boolean) => {
    updateStory({
      id,
      isPublic,
    });
  };

  const renderItems = useMemo(() => {
    return items.map((item) => {
      /*   const { ctaClicks, views, createdAt } = item;

      let conversion = 0;
      if (views > 0 && ctaClicks > 0) {
        conversion = (ctaClicks * 100) / views;
      } */

      return (
        <MediaWrapper key={item.id}>
          <MediaItem isThemeLayoutEnabled={isThemeLayoutEnabled}>
            <MediaItemWrapper>
              {/* <CustomGrid>
                <TagsSection story={item as StoryVideosApiModel} />
                <VideoDuplicate story={item} />
              </CustomGrid> */}
              <AllVideosComponent
                storyDetails={item.details}
                thumbnailUrl={item.thumbnailUrl}
                url={item.url}
                isPlayButton
                setCurrentVideoId={setCurrentVideoId}
                videoId={item.id}
                currentVideoId={currentVideoId}
                duration={item.duration}
                onClick={() => {
                  setCurrentStory(item);
                  openVideoModal();
                }}
              />
              {isAthSol && (
                <Box className={classes.switch}>
                  <CustomSwitch onChange={(isPublic) => togglePublic(item.id, isPublic)} isPublic={item.isPublic} />
                </Box>
              )}
              <Box className={classes.info}>
                <Typography> {format(new Date(item.createdAt), 'MMM dd, yyyy')}</Typography>
                <Box className={classes.icons}>
                  <Box onClick={() => downloadClick(item)}>
                    <DownloadIcon />
                  </Box>

                  <Box onClick={() => shareClick(item)}>
                    <SharIcon />
                  </Box>
                </Box>
              </Box>
            </MediaItemWrapper>
          </MediaItem>
        </MediaWrapper>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    classes.checked,
    classes.label,
    classes.root,
    classes.switchBase,
    classes.track,
    color,
    handleChange,
    handleShareClick,
    items,
    account.clientType,
    currentVideoId,
  ]);

  const RenderItems = () => {
    if (isLoading) {
      return (
        <SkeletonWrapper>
          <Skeleton count={3} height={431} />
        </SkeletonWrapper>
      );
    }

    return (
      <>
        {renderItems}
        <ModerationModal story={currentStory} show={showModerationModal} onClose={closeModerationModal} />
        <Dialog
          classes={{ paper: classes.paper }}
          open={open}
          aria-labelledby="confirm-modal"
          aria-describedby="simple-modal-description"
        >
          <DialogTitle>
            <IconButton className={classes.closeButton} onClick={() => handleClose()} aria-label="close" size="large">
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.content}>
            <Box component="p" marginBottom={3} lineHeight={1.625} fontSize={16}>
              {MediaListStrings.ConfirmDialogText}
            </Box>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              style={{ lineHeight: '36px' }}
              onClick={() => handleClose(true)}
              type="button"
            >
              {MediaListStrings.ConfirmButton}
            </Button>
          </DialogContent>
        </Dialog>

        {/* //share modal */}
        <Dialog
          classes={{ paper: classes.shareDialog }}
          open={showShareModal}
          aria-labelledby="confirm-modal"
          aria-describedby="simple-modal-description"
          onClose={(_, reason) => {
            if (reason && reason === 'backdropClick') {
              closeShareModal();
            }
          }}
        >
          <DialogTitle className={classes.dialogTitle}>
            <IconButton
              className={classes.closeButton}
              onClick={() => closeShareModal()}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.content}>
            {
              currentStory && (
                <ShareVideoModal
                  storyLink={currentStory.videoPageUrl}
                  isOpen={showShareModal}
                  onClose={closeShareModal}
                  storyId={currentStory.id}
                />
              ) /* (
              <ShareViaSocials
                story={currentStory}
                mb="10px"
                downloadingType={downloadingType}
                onVideoDownloadClick={downloadVideo}
              />
            ) */
            }
          </DialogContent>
        </Dialog>

        {currentStory && (
          <VideoPreviewModal
            src={currentStory.url}
            show={showVideoModal}
            onClose={closeVideoModal}
            captionsUrl={currentStory.captionsUrl}
          />
        )}

        {/* download modal */}
        {currentStory && (
          <Dialog
            classes={{ paper: classes.shareDialog }}
            open={showDownloadModal}
            aria-labelledby="confirm-modal"
            aria-describedby="simple-modal-description"
            onClose={(_, reason) => {
              if (reason && reason === 'backdropClick') {
                toggleDownloadModal();
              }
            }}
          >
            <DialogTitle className={classes.dialogTitle}>
              <IconButton
                className={classes.closeButton}
                onClick={() => toggleDownloadModal()}
                aria-label="close"
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent className={classes.content}>
              {currentStory && <DownloadVideo downloadingType={downloadingType} onDownloadClick={downloadVideo} />}
            </DialogContent>
          </Dialog>
        )}
      </>
    );
  };

  return <Container>{RenderItems()}</Container>;
};
