import React, { FC } from 'react';
import { Box } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { Modal } from '../../components/Modal/Modal';
import { PrivacyPolicyPage } from '../../../pages/PrivacyPolicyPage/PrivacyPolicyPage';

const styles = () => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '8px',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 5px 0 0 var(--darkScrollColor)',
    },
    '*::-webkit-resizer': {
      display: 'none',
    },
    '*::-webkit-scrollbar-corner': {
      display: 'none',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--lightScrollColor)',
      borderRadius: '10px',
      height: '40px',
    },
  },
});

interface TermsOfServiceProps {
  handleCloseTermsOfServiceModal: () => void;
}

const TermsOfServicePopup: FC<TermsOfServiceProps> = ({ handleCloseTermsOfServiceModal }) => {
  return (
    <Modal onClose={handleCloseTermsOfServiceModal}>
      <Box
        position="relative"
        height="75vh"
        padding="56px 10px 16px 10px"
        margin="10px"
        borderRadius="8px 8px 8px 8px"
        bgcolor="#1C1D22"
        overflow="scroll"
      >
        <PrivacyPolicyPage />
      </Box>
    </Modal>
  );
};
export default withStyles(styles)(TermsOfServicePopup);
