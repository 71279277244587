import React, { FC, useState, useMemo, memo } from 'react';
import { useTheme } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { FormTextField } from '../FormTextField/FormTextField';
import { PasswordWrapper, EyeWrapper } from './PasswordField.style';
import { useTypedSelector } from '../../../../store/store';
import { EventNames } from '../../../constants/constants';
import { ArrowUpPasswordIcon } from '../../../assets/ArrowUpPasswordIcon';
import { trackEvent } from '../../../../../utils/analytics/analytics';

interface PasswordFieldProps {
  label: string;
  name: string;
  value?: string | number;
  errorText?: string | null | undefined;
  onChange: (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  setFieldTouched: (name: string, isTouched: boolean) => void;
  disabled?: boolean;
}

export const PasswordField: FC<PasswordFieldProps> = memo((props) => {
  const { account } = useTypedSelector((state) => state.account);
  const theme = useTheme();
  const { label, name, value = '', setFieldTouched, errorText, onChange, onBlur, disabled } = props;

  const [hiddenPassword, setHiddenPassword] = useState<boolean>(true);
  const [isCapsLock, setisCapsLock] = useState<boolean | null>(null);

  const onClickPasswordEye = () => {
    trackEvent({
      action: EventNames.LoginFormShowPassword,
      location: window.location.href,
      accountId: account.id,
    });

    setHiddenPassword(!hiddenPassword);
  };

  const PasswordEye = useMemo(() => {
    if (hiddenPassword) {
      return (
        <EyeWrapper onClick={onClickPasswordEye}>
          <VisibilityIcon />
          {isCapsLock && <ArrowUpPasswordIcon color={theme.palette.primary.main} />}
        </EyeWrapper>
      );
    }
    return (
      <EyeWrapper onClick={onClickPasswordEye}>
        <VisibilityOffIcon />
        {isCapsLock && <ArrowUpPasswordIcon color={theme.palette.primary.main} />}
      </EyeWrapper>
    );
  }, [hiddenPassword, onClickPasswordEye]);

  return (
    <PasswordWrapper theme={theme}>
      <FormTextField
        onChange={onChange}
        onKeyDown={(e) => {
          setisCapsLock(e.getModifierState('CapsLock'));
        }}
        onKeyUp={(e: React.KeyboardEvent) => {
          setisCapsLock(e.getModifierState('CapsLock'));
        }}
        onBlur={onBlur}
        setFieldTouched={setFieldTouched}
        value={value}
        label={label}
        name={name}
        type={hiddenPassword ? 'password' : 'text'}
        errorText={errorText}
        disabled={disabled}
      />
      {PasswordEye}
    </PasswordWrapper>
  );
});
