import { FC } from 'react';

export const WeakWifiIcon: FC<{ color?: string }> = ({ color }) => {
  return (
    <svg width="34" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="9" width="6" height="9" rx="2" fill={color || '#F1F1F1'} />
      <rect x="2" y="20" width="6" height="6" rx="2" fill={color ? `${color}80` : 'black'} fillOpacity="0.22" />
      <rect x="10" y="7" width="6" height="11" rx="2" fill={color ? `${color}80` : 'black'} fillOpacity="0.22" />
      <rect x="10" y="20" width="6" height="6" rx="2" fill={color ? `${color}80` : 'black'} fillOpacity="0.22" />
      <rect x="18" y="5" width="6" height="13" rx="2" fill={color ? `${color}80` : 'black'} fillOpacity="0.22" />
      <rect x="18" y="20" width="6" height="6" rx="2" fill={color ? `${color}80` : 'black'} fillOpacity="0.22" />
      <rect x="26" y="2" width="6" height="16" rx="2" fill={color ? `${color}80` : 'black'} fillOpacity="0.22" />
      <rect x="26" y="20" width="6" height="6" rx="2" fill={color ? `${color}80` : 'black'} fillOpacity="0.22" />
    </svg>
  );
};
