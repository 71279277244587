import { FC, useCallback, useState } from 'react';
import { useFormik } from 'formik';

import { AppRoutes } from '../../../common/constants/routes';
import { formData, authSignInValidationSchema } from '../SignInPage.helper';
import { AuthForm } from '../../../common/components/AuthForm/AuthForm';
import { authFormInitialValues } from '../../../common/constants/constants';
import { Container } from './SignInForm.style';
import { useHistory } from 'react-router-dom';

export const SignInForm: FC = () => {
  const history = useHistory();

  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);
  const [isEnterpriseCaptcha, setIsEnterpriseCaptcha] = useState(true);

  const formik = useFormik({
    initialValues: authFormInitialValues,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: authSignInValidationSchema,
    onSubmit: (values) => {
      onSubmitHandler(values);
    },
  });

  const onSubmitHandler = useCallback((values) => {
    //@ts-expect-error
    const { email, password } = values;
    setDisabledSubmitButton(true);
  }, []);

  const transitionBetweenFormsHandler = useCallback(() => {
    history.push(AppRoutes.SignUp);
  }, [history]);

  return (
    <Container>
      <AuthForm
        formik={formik}
        formFieldsData={formData}
        disabledSubmitButton={disabledSubmitButton}
        transitionBetweenForms={transitionBetweenFormsHandler}
        setCaptcha={setCaptchaResponse}
        captchaResponse={captchaResponse}
        isEnterpriseCaptcha={isEnterpriseCaptcha}
        setIsEnterpriseCaptcha={setIsEnterpriseCaptcha}
      />
    </Container>
  );
};
