import { Box, BoxProps } from '@mui/material';
import React from 'react';

interface GlassContainerProps extends BoxProps {
  hideGlass?: boolean;
}

export const GlassContainer: React.FC<GlassContainerProps> = ({ children, hideGlass, ...boxProps }) => {
  return (
    <Box>
      <Box
        my="20px"
        borderRadius="16px"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        py="10px"
        px="5px"
        position="relative"
        sx={{
          background: hideGlass
            ? undefined
            : 'linear-gradient(180deg, rgba(0, 0, 0, 0.46) 35.94%, rgba(0, 0, 0, 0.46) 100%)',
          backdropFilter: 'blur(3.5px)',
        }}
        {...boxProps}
      >
        {children}
      </Box>
    </Box>
  );
};
