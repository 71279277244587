import { Box, Button, Modal, Typography } from '@mui/material';
import { FC } from 'react';

import { useStyles } from './DeactivatedUserModal.styles';
import { useThemeLayout } from '../../hooks/useThemeLayout';
import { DeactivatedModalStrings } from '../../localization/en';
import { useTypedSelector } from '../../../store/store';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const DeactivatedUserModal: FC<Props> = ({ isOpen, onClose }) => {
  const styles = useStyles();
  const { name } = useTypedSelector((state) => state.account.account);

  const { isDarkTheme, themeBackgroundColor, isThemeLayoutEnabled, themeTextColor } = useThemeLayout();

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        className={styles.container}
        sx={{
          background: isThemeLayoutEnabled ? themeBackgroundColor : '#fff',
        }}
      >
        <Typography className={styles.title} color={isThemeLayoutEnabled ? themeTextColor : '#000'}>
          {DeactivatedModalStrings.Title}
        </Typography>
        <Typography className={styles.subtitle} mt="3px" color={isDarkTheme ? themeTextColor : '#8B89A0'}>
          {DeactivatedModalStrings.AccountDeactivated}
        </Typography>
        <Typography className={styles.subtitle} color={isDarkTheme ? themeTextColor : '#8B89A0'}>
          {name}.
        </Typography>

        <Button size="small" variant="contained" onClick={onClose} className={styles.btn}>
          {DeactivatedModalStrings.Cancel}
        </Button>
      </Box>
    </Modal>
  );
};
