import styled from 'styled-components';
import { Theme } from '@mui/material';

export const PasswordWrapper = styled.div<{ theme: Theme }>`
  position: relative;
  .MuiSvgIcon-root {
    color: ${(props) => props.theme?.palette?.primary?.main};
  }
  :focus-within {
    .MuiSvgIcon-root {
      color: ${(props) => props.theme?.palette?.primary?.main};
    }
  }
`;

export const EyeWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  right: 20px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
`;
