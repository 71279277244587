import { ChangeEvent, FC } from 'react';
import { Checkbox } from '@mui/material';

interface Props {
  isChecked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, isChecked: boolean) => void;
  outlineColor?: string;
}

export const CustomCheckBox: FC<Props> = ({ isChecked, onChange, outlineColor }) => {
  return (
    <Checkbox
      checked={isChecked}
      onChange={onChange}
      icon={<CheckBoxIcon outlineColor={outlineColor} />}
      checkedIcon={<CheckBoxIconChecked />}
      sx={{ padding: '5px' }}
    />
  );
};

interface CheckBoxIconProps {
  outlineColor?: string;
}

const CheckBoxIcon: FC<CheckBoxIconProps> = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="19" height="19" rx="4.5" fill="white" />
      <rect x="0.5" y="0.5" width="19" height="19" rx="4.5" stroke="#C1C1C1" />
    </svg>
  );
};
const CheckBoxIconChecked: FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="19" height="19" rx="4.5" fill="white" />
      <rect x="0.5" y="0.5" width="19" height="19" rx="4.5" stroke="#C1C1C1" />
      <path
        d="M17.1663 3.25L6.37467 15.0417L2.83301 8.5"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
