import * as React from 'react';
import { SVGProps } from 'react';
const CCIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={21} height={18} viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.6667 0H2.33333C1.04417 0 0 0.978906 0 2.1875V15.3125C0 16.5211 1.04417 17.5 2.33333 17.5H18.6667C19.9558 17.5 21 16.5211 21 15.3125V2.1875C21 0.978906 19.9558 0 18.6667 0ZM9.33333 7.65625H7.58333V7.10937H5.25V10.3906H7.58333V9.84375H9.33333V10.9375C9.33333 11.2276 9.21042 11.5058 8.99162 11.7109C8.77283 11.916 8.47609 12.0312 8.16667 12.0312H4.66667C4.35725 12.0312 4.0605 11.916 3.84171 11.7109C3.62292 11.5058 3.5 11.2276 3.5 10.9375V6.5625C3.5 6.27242 3.62292 5.99422 3.84171 5.7891C4.0605 5.58398 4.35725 5.46875 4.66667 5.46875H8.16667C8.47609 5.46875 8.77283 5.58398 8.99162 5.7891C9.21042 5.99422 9.33333 6.27242 9.33333 6.5625V7.65625ZM17.5 7.65625H15.75V7.10937H13.4167V10.3906H15.75V9.84375H17.5V10.9375C17.5 11.2276 17.3771 11.5058 17.1583 11.7109C16.9395 11.916 16.6428 12.0312 16.3333 12.0312H12.8333C12.5239 12.0312 12.2272 11.916 12.0084 11.7109C11.7896 11.5058 11.6667 11.2276 11.6667 10.9375V6.5625C11.6667 6.27242 11.7896 5.99422 12.0084 5.7891C12.2272 5.58398 12.5239 5.46875 12.8333 5.46875H16.3333C16.6428 5.46875 16.9395 5.58398 17.1583 5.7891C17.3771 5.99422 17.5 6.27242 17.5 6.5625V7.65625Z"
      fill="white"
    />
  </svg>
);
export default CCIcon;
