import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 20px;
  color: #fff;
  z-index: 1;
  padding: 0;
  overflow: auto;
  background-color: #000;
`;
