import { UserIncentiveCampaignApiModel } from '../../../api/models/rewards';

export const orderCampaigns = (campaigns: UserIncentiveCampaignApiModel[]) => {
  const fulfilledCampaigns: UserIncentiveCampaignApiModel[] = [];
  const activeCampaigns: UserIncentiveCampaignApiModel[] = [];
  const inProgressCampaigns: UserIncentiveCampaignApiModel[] = [];
  const endedCampaigns: UserIncentiveCampaignApiModel[] = [];

  campaigns.forEach((campaign) => {
    if (campaign.fulfilled && !campaign.campaign?.isNullReward) {
      fulfilledCampaigns.push(campaign);
      return;
    }

    if (campaign.fulfilled && campaign.campaign?.isNullReward) {
      return;
    }

    if (campaign.active && !campaign.campaign?.endedAt) {
      activeCampaigns.push(campaign);
      return;
    }

    if (!campaign.campaign?.endedAt) {
      inProgressCampaigns.push(campaign);
      return;
    }

    endedCampaigns.push(campaign);
  });

  fulfilledCampaigns.sort((a, b) => {
    if (a.fulfilledAt && b.fulfilledAt) {
      return new Date(b.campaign.endedAt).getTime() - new Date(a.campaign.endedAt).getTime();
    }

    if (a.fulfilledAt) {
      return 1;
    }

    if (b.fulfilledAt) {
      return -1;
    }

    return 0;
  });

  endedCampaigns.sort((a, b) => {
    if (a.campaign.endedAt && b.campaign.endedAt) {
      return new Date(b.campaign.endedAt).getTime() - new Date(a.campaign.endedAt).getTime();
    }

    if (a.campaign.endedAt) {
      return 1;
    }

    if (b.campaign.endedAt) {
      return -1;
    }

    return 0;
  });

  const notFulfilledCampaigns = [...activeCampaigns, ...inProgressCampaigns, ...endedCampaigns];

  return {
    fulfilledCampaigns,
    notFulfilledCampaigns,
  };
};
