import { FC, useRef } from 'react';
import { UserIncentiveCampaignApiModel } from '../../../api/models/rewards';
import { useStyles } from './RewardBlock.styles';
import { Box } from '@mui/system';
import { LinearProgress, Typography } from '@mui/material';
import { PointsStrings } from '../../localization/en';
import { getTextWidth } from '../../../services/utilities';
import { RewardCard } from '../RewardCard/RewardCard';
import { CampaignObjectives } from '../../constants/constants';
import { bodyMapAwareness, bodyMapIncentive, getRewardProgress, titlesMap } from './RewardBlock.helper';

interface Props {
  userIncentiveCampaign: UserIncentiveCampaignApiModel;
  key?: string;
}

export const RewardBlock: FC<Props> = ({ userIncentiveCampaign, key }) => {
  const classes = useStyles();
  const rewardTitleRef = useRef<HTMLDivElement>(null);

  const progress = getRewardProgress(userIncentiveCampaign);
  const isGoalAchieved = progress >= 100;
  const rewardName = userIncentiveCampaign.campaign?.rewardTitle || '';
  const isIncentiveCampaign = userIncentiveCampaign.campaign.isNullReward === false;

  const campaignObjective = userIncentiveCampaign.campaign?.campaignObjective || CampaignObjectives.VideoUpload;
  const bodyTitle = titlesMap[campaignObjective];
  const bodyText = userIncentiveCampaign.campaign?.isNullReward
    ? bodyMapAwareness[campaignObjective]
    : bodyMapIncentive[campaignObjective];

  const getTitleFontSize = (title: string) => {
    const MAX_FONT_SIZE = 21;
    const CONTAINER_WIDTH = 291;

    let fontSize = MAX_FONT_SIZE;
    const getFont = (fontSize: number) => `600 ${fontSize}px "Open Sans", sans-serif`;

    let textWidth = getTextWidth(title, getFont(fontSize));

    while (textWidth > CONTAINER_WIDTH) {
      fontSize--;
      textWidth = getTextWidth(title, getFont(fontSize));
    }

    return fontSize;
  };

  if (userIncentiveCampaign.fulfilled && userIncentiveCampaign.campaign.isNullReward) {
    return null;
  }

  return (
    <Box className={classes.container} key={key}>
      <Box className={classes.contentWrapper}>
        {userIncentiveCampaign.fulfilled ? (
          <Box className={classes.cardWrapper}>
            <RewardCard userIncentiveCampaign={userIncentiveCampaign} />
          </Box>
        ) : (
          <Box
            style={{
              opacity: userIncentiveCampaign.campaign.endedAt ? 0.55 : 1,
            }}
          >
            {isIncentiveCampaign && (
              <Box className={classes.rewardNameWrapper} ref={rewardTitleRef}>
                <Typography className={classes.rewardName} fontSize={getTitleFontSize(rewardName)}>
                  {rewardName}
                </Typography>
              </Box>
            )}
            <Box
              className={classes.progressWrapper}
              style={{
                marginTop: isIncentiveCampaign ? '16px' : '44px',
              }}
            >
              <LinearProgress value={progress} variant="determinate" className={classes.progressBar} />
              <Typography className={classes.percentsText}>{`${progress}%`}</Typography>
            </Box>
            <Box className={classes.descriptionWrapper}>
              {isGoalAchieved ? (
                <Typography className={classes.achievedText}>{PointsStrings.ThanksForSubmitting}</Typography>
              ) : (
                <>
                  <Typography className={classes.moreVideosText}>{bodyTitle}</Typography>
                  <Typography className={classes.eligibilityText}>{bodyText}</Typography>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
