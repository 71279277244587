import React, { FC } from 'react';
import { Box, Button } from '@mui/material';
import { VideoToolStrings } from '../../../localization/en';
import { useStyles } from './CreateVideoControls.styles';
import clsx from 'clsx';

interface Props {
  handleCaptureClick: () => void;
  handleInputClick: (event: React.MouseEvent<HTMLElement>) => void;
  isButtonsDisabled: boolean;
}

export const CreateVideoControls: FC<Props> = ({ handleCaptureClick, handleInputClick, isButtonsDisabled }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Button
        className={clsx(classes.btn, classes.uploadButton)}
        onClick={handleInputClick}
        disabled={isButtonsDisabled}
      >
        {VideoToolStrings.UploadAVideo}
      </Button>
      <Button
        className={clsx(classes.btn, classes.recordButton)}
        onClick={handleCaptureClick}
        disabled={isButtonsDisabled}
      >
        {VideoToolStrings.RecordVideo}
      </Button>
    </Box>
  );
};
