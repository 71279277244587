import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { VideoAppTheme } from '../../../api/models/venue';

interface Props {
  isThemeLayoutEnabled?: boolean;
  layoutTheme?: VideoAppTheme;
}

export const useStyles = makeStyles<Theme, Props>(() => ({
  container: ({ isThemeLayoutEnabled, layoutTheme }) => ({
    background:
      isThemeLayoutEnabled && layoutTheme === VideoAppTheme.Light
        ? '#4754671A'
        : 'linear-gradient(180deg, rgba(0, 0, 0, 0.46) 35.94%, rgba(0, 0, 0, 0.46) 100%)',
    backdropFilter: 'blur(3.5px)',
    color: isThemeLayoutEnabled && layoutTheme === VideoAppTheme.Light ? '#475467' : '#fff',
    width: '338px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '12px',
    flexDirection: 'column',
    paddingBottom: '24px',
  }),
  swiperSlide: {
    borderRadius: '12px',
  },
  navWrapper: {
    display: 'flex',
    width: '303px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '24px auto 0',
  },
  navItemsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '8px',
    flexWrap: 'wrap',
  },
  navItem: {
    width: '14px',
    height: '14px',
    display: 'flex',
    background: '#fff',
    borderRadius: '50%',
    marginRight: '8px',
    marginTop: '8px',
    cursor: 'pointer',
  },
  btn: {
    minWidth: '40px',
    width: '40px',
    height: '20px',
    marginTop: '8px',
  },
}));
