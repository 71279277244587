import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  termsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  termsText: {
    color: '#475467',
    letterSpacing: '0.128px',
    fontSize: 'clamp(9px,3vw, 14px)',
    lineHeight: '160%',
    marginLeft: '2px',
    textAlign: 'center',
  },
  svTermsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5ox',
  },
  termsLink: {
    textDecoration: 'underline',
    color: '#2D11AD',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:visited': {
      textDecoration: 'underline',
    },
  },
  termsError: {
    fontSize: 'clamp(9px,3vw, 14px)',
    color: '#ED2E38',
    marginLeft: '30px',
  },
}));
