import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
  },
  imageWrapper: {
    '& div': {
      minHeight: '770px',
    },
    zIndex: 0,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      '& img': {
        objectPosition: 'center',
      },
    },
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      // flexGrow: 1,
      // '& img': {
      //   objectPosition: 'top',
      //   objectFit: 'cover',
      //  },
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      // backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  imageBackground: {
    width: '100% !important',
    // maxWidth: '480px !important',
    minWidth: 'auto !important',
  },
}));
