import { makeStyles } from '@mui/styles';

export const useSharePageStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bgcolor: 'white',
    width: '100%',
    height: '100vh',
    maxHeight: 'calc(100vh - 40px)',
    padding: '12px 15px 0',
    minHeight: '550px',
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5vh',
  },

  title: {
    fontSize: 'clamp(20px, 5.6vw, 30px)',
    fontWeight: 600,
    color: '#1A1A1A',
  },

  contentContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  mainMessageTitle: {
    fontSize: 'clamp(16px, 4.48vw, 24px)',
    color: '#2D11AD',
    textAlign: 'center',
    fontWeight: '700',
    lineHeight: '4.5vh',
    opacity: '0.8',
  },

  mainMessageText: {
    fontSize: 'clamp(16px, 4.48vw, 24px)',
    color: '#2D11AD',
    fontWeight: '500',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    opacity: '0.8',
  },

  divider: {
    width: '100%',
    margin: '2vh 0',
    borderColor: '#E4E4E4',
  },

  bottomText: {
    fontSize: 'clamp(14px, 3.92vw, 21px)',
    width: '100%',
    textAlign: 'center',
    color: '#475467',
    marginBottom: '2vh',
    whiteSpace: 'pre-wrap',
  },

  shareButton: {
    fontSize: 'clamp(16px, 4.48vw, 24px)',
    fontWeight: '600',
    color: '#2D11AD',
    borderColor: '#2D11AD',
    '&:hover': {
      color: '#2D11AD',
      borderColor: '#2D11AD',
    },
  },
}));
