import styled from 'styled-components';

interface PrivacyPolicyTextStyleProps {
  maxWidth: string;
}
export const PrivacyPolicyTextStyle = styled.div<PrivacyPolicyTextStyleProps>`
  max-width: ${(props) => props.maxWidth};
  font-size: 16px;
  word-break: break-word;
  font-weight: 400;
  color: var(--greyTextColor);
`;

export const DesktopPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1128px;
  align-items: center;
  margin: 0 auto;
  overflow-x: hidden;
`;

export const DesktopPageContent = styled.div`
  display: flex;
  width: 672px;
`;
