import { CampaignObjectives } from '../../constants/constants';
import { HowToRewardCreatorsStrings } from '../../localization/en';
import { UserIncentiveCampaignApiModel } from '../../../api/models/rewards';

export const titlesMap: Record<CampaignObjectives, string> = {
  [CampaignObjectives.VideoUpload]: HowToRewardCreatorsStrings.CreateMoreVideos,
  [CampaignObjectives.VideoViews]: HowToRewardCreatorsStrings.CollectMoreViews,
  [CampaignObjectives.VideoClicks]: HowToRewardCreatorsStrings.PostAndShare,
};

export const bodyMapIncentive: Record<CampaignObjectives, string> = {
  [CampaignObjectives.VideoUpload]: HowToRewardCreatorsStrings.VideosEligibility,
  [CampaignObjectives.VideoViews]: HowToRewardCreatorsStrings.ViewsEligibility,
  [CampaignObjectives.VideoClicks]: HowToRewardCreatorsStrings.SharesEligibility,
};

export const bodyMapAwareness: Record<CampaignObjectives, string> = {
  [CampaignObjectives.VideoUpload]: HowToRewardCreatorsStrings.VideosGoal,
  [CampaignObjectives.VideoViews]: HowToRewardCreatorsStrings.ViewsGoal,
  [CampaignObjectives.VideoClicks]: HowToRewardCreatorsStrings.SharesGoal,
};

export const getRewardProgress = (userIncentiveCampaign: UserIncentiveCampaignApiModel) => {
  let progress = 0;
  const campaignObjective = userIncentiveCampaign.campaign.campaignObjective;
  const { videos, videoViews, videoCtaClicks } = userIncentiveCampaign;

  switch (campaignObjective) {
    case CampaignObjectives.VideoUpload:
      if (videos > 0) progress = 100;
      break;
    case CampaignObjectives.VideoViews:
      if (videos > 0) progress += 50;
      if (videoViews > 0) progress += 50;
      break;
    case CampaignObjectives.VideoClicks:
      if (videos > 0) progress += 33;
      if (videoViews > 0) progress += 33;
      if (videoCtaClicks > 0) progress += 33;
      break;
  }

  return progress >= 99 ? 100 : progress;
};
