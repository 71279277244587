import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
  },
  revealWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: `clip-path 0.6s ease-in-out`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  companyName: {
    fontSize: '44px',
    fontWeight: 700,
    fontFamily: 'Poppins, sans-serif',
    textAlign: 'center',
  },
  logo: {
    objectFit: 'contain',
    width: '90%',
    maxWidth: '520px',
    maxHeight: '256px',
  },
}));
