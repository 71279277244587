import * as Yup from 'yup';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: 48,
    width: '100%',
    borderRadius: 10,
    ['&:disabled']: {
      backgroundColor: theme.palette.grey[500],
    },
  },
}));

export interface InitialFormValues {
  metaTitle: string;
  videoText: string;
  ctaBtnText: string;
  ctaBtnLinkUrl: string;
}

export const initialValues: InitialFormValues = {
  metaTitle: '',
  videoText: '',
  ctaBtnText: '',
  ctaBtnLinkUrl: '',
};

export const validationSchema = () => {
  return Yup.object().shape({
    metaTitle: Yup.string().min(5, 'Meta title should be min 5 characters').max(75, 'Recommended length: 75 characters'),
    videoText: Yup.string() /* .min(5, 'Custom text should be min 5 characters') */
      .max(100, 'max length 100 characters'),
    ctaBtnText: Yup.string().min(5, 'CTA text should be min 5 characters').max(25, 'max length 25 characters'),
    ctaBtnLinkUrl: Yup.string().url('Please include https:// with a valid url to proceed'),
  });
};
